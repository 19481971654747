import React, { FormEvent, FunctionComponent, PropsWithChildren, useState } from 'react';
import useForm, { FormProps } from '@/Hooks/useForm';
import { Report } from '@/Types/Models/Projects/report';
import moment from 'moment/moment';
import Button from '@/Components/Button';
import PencilIcon from '@/Components/PencilIcon';
import BinIcon from '@/Components/BinIcon';
import Field from '@/Components/Inputs/Field';
import Select, { IOption } from '@/Components/Inputs/Select';
import AsyncSelect from '@/Components/Inputs/AsyncSelect';
import Label from '@/Components/Inputs/Label';
import WYSIWYG from '@/Components/Inputs/WYSIWYG';
import { Observation } from '@/Types/Models/Projects/observation';
import FileUpload from '@/Components/FileUpload';
import { Image } from '@/Types/Models/Projects/image';
import route from 'ziggy-js';
import { useAuth } from '@/Contexts/AuthContext';
import { isBase64 } from '@/Utilities/helpers';
import Modal from '@/Components/Modals/Modal';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import Datepicker from '@/Components/Inputs/Datepicker';

interface IProps extends FormProps<Report>, PropsWithChildren {
    canEdit?: boolean;
}

interface IForm extends FormProps<Observation> {
    index?: number;
}

const Form: FunctionComponent<IForm> = ({ index, data, setData, loadData }) => {
	const { mobileMode, photoCount, photoLimit, incrementPhotoCount, deincrementPhotoCount } = useAuth();

	const handleImageChange = (imageIndex: number, attribute?: string, value?: any) => {
		if (data && attribute) {
			const image = data?.images?.[imageIndex];
			if (image) {
				image[attribute as keyof Image] = value;
				setData('images', [
					...(data?.images ?? []).slice(0, imageIndex),
					image,
					...(data?.images ?? []).slice(imageIndex + 1),
				]);
			}
		}
	};

	return (
		<div className="Form bg-gray-100 p-5 mt-6 mb-6 modal-form-observation">
			<div className="grid grid-cols-3 gap-6">
				<div className="col-span-1">
					<Field name="type" label="Type *">
						<Select name="type"
							value={data?.type}
							onChange={setData}
							options={[
								{
									label: 'Electrical',
									value: 'Electrical'
								},
								{
									label: 'Mechanical',
									value: 'Mechanical'
								},
								{
									label: 'Building',
									value: 'Building'
								},
							]}
						/>
					</Field>
				</div>
				<div className="col-span-1">
					<Field label="Status *" name="status_id">
						<AsyncSelect
							routeName="api.statuses.index"
							routeParams={{ type: 'observation' }}
							selectRouteName="api.statuses.show"
							selectParams={{ status: data?.status_id }}
							allowNullSearch={true}
							labelKey="name"
							value={data?.status_id}
							valueKey="id"
							onChange={(_, value, option) => loadData && loadData({
								...data ?? {},
								status_id: value ? parseInt(value) : undefined,
								status: {
									label: (option as IOption)?.label as string,
								}
							})}
							placeholder="Select Status..."
						/>
					</Field>
				</div>
				<div className="col-span-1">
					<Field
						label="Status Changed At *"
						name="status_changed_at"
					>
						<Datepicker
							name="status_changed_at"
							type="date"
							value={data?.status_changed_at}
							onChange={(_, value) => setData('status_changed_at', moment(value ?? moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').format('YYYY-MM-DD'))}
						/>
					</Field>
				</div>
			</div>
			<div className="grid grid-cols-2 gap-6 mt-6">
				<div className="col-span-2">
					<Label>Defect</Label>
					<WYSIWYG
						name="content"
						placeholder="Add your defect..."
						value={data?.defect ?? ''}
						onChange={(_, value) => setData('defect', value)}
					/>
				</div>

				<div key={data?.images?.length} className="col-span-2">
					{
						data?.images?.map((image, index) => (
							<div key={index} className="grid grid-cols-3 gap-6 mb-6">
								<div className="col-span-1">
									<Field
										label={index === 0 ? 'Image *' : undefined}
										name="attachment"
									>
										<FileUpload
											name="attachment"
											visibility="private"
											label="Upload an image"
											maxFileSize="10MB"
											initialValue={image?.attachment}
											onChange={(name, data) => handleImageChange(index, name, data)}
											validateTypes={['image/jpeg']}
											compressImages={true}
										/>
									</Field>
								</div>
								<div className="col-span-1">
									<Field
										label={index === 0 ? 'Caption *' : undefined}
										name="caption"
										value={image?.caption}
										onChange={(name, data) => handleImageChange(index, name, data)}
										spellCheck={true}
									/>
								</div>

								<div className="col-span-1 flex items-end">
									<Button
										type="button"
										className=""
										onClick={() => {
											mobileMode && deincrementPhotoCount && deincrementPhotoCount();
											setData('images', [
												...data?.images?.filter((_, i) => i !== index) ?? []
											]);
										}}
									>
                                        Remove Image
									</Button>
								</div>
							</div>
						))
					}

					{
						((mobileMode && (photoCount ?? 0) < photoLimit) || !mobileMode) && (
							<Button
								type="button"
								className="btn-secondary"
								onClick={() => {
									mobileMode && incrementPhotoCount && incrementPhotoCount();
									setData('images', [
										...(data?.images ?? []),
										{
											id: undefined,
											attachment: undefined,
											caption: '',
										}
									]);
								}}
							>
                        	Add Image
							</Button>
						)
					}
				</div>
			</div>
		</div>
	);
};

const ObservationForm: FunctionComponent<IProps> = ({ canEdit = true, data, errors, setData, children }) => {
	const [openEditModal, setOpenEditModal] = useState<boolean>(false);
	const [openStatsModal, setOpenStatsModal] = useState<boolean>(false);
	const { offline, mobileMode } = useAuth();
	const { data: newObservation, setData: setNewObservation, loadData: loadNewObservation } = useForm<Observation>({
		id: undefined
	});
	const { data: observation, setData: setObservation, loadData: loadObservation } = useForm<Observation>({
		id: undefined
	});

	const getStatusTextColour = (status: string) => {
		switch (status) {
		case 'Open':
			return 'text-red-500';
		case 'Monitor':
			return 'text-orange-500';
		case 'Closed':
			return 'text-green-500';
		default:
			return 'text-black';
		}
	};

	const handleCreate = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setData('observations', [
			...(data?.observations ?? []),
			{
				...newObservation,
				count: (data?.observations?.reduce((max: number, observation: Observation) => (observation.count && observation.count > max ? observation.count : max), (data?.observations[0]?.count ?? 0)) ?? 0) + 1,
			}
		]);

		loadNewObservation({
			id: undefined
		});

		localStorage.setItem('flash-success', 'Defect/Observation has been created. This change will be reflected on submission.');
	};

	const handleDelete = (observation: Observation) => {
		setData('observations', [
			...(data?.observations ?? []).filter(o => o !== observation)
		]);

		localStorage.setItem('flash-success', 'Defect/Observation has been deleted. This change will be reflected on submission.');
	};

	const handleEdit = (observation: Observation) => {
		loadObservation(observation);
		setOpenEditModal(true);
	};

	const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (observation.id === undefined) {
			setData('observations', [
				...(data?.observations ?? []).map(o => o.count === observation?.count ? observation : o)
			]);
		} else {
			setData('observations', [
				...(data?.observations ?? []).map(o => o.id === observation?.id ? observation : o)
			]);
		}

		setOpenEditModal(false);

		localStorage.setItem('flash-success', 'Defect/Observation has been updated. This change will be reflected on submission.');
	};

	const byValueChartOptions: ApexOptions = {
		chart: {
			type: 'pie',
			height: 350,
			zoom: {
				enabled: true
			}
		},
		series: [
			(data?.observations?.filter(observation => observation?.status?.label !== 'Closed')?.length ?? 0),
			(data?.observations?.filter(observation => observation?.status?.label === 'Closed')?.length ?? 0),
		],
		labels: [
			'Not Completed',
			'Completed'
		],
		colors: ['#c51e1e', '#000000'],
		dataLabels: {
			enabled: true,
			formatter: (val: number, opts) => `${opts?.w?.globals?.series?.[opts.seriesIndex]} (${val?.toFixed(2)}%)`
		}
	};

	return (
		<div className="ObservationForm relative">
			{/*<div className="w-full flex justify-end">*/}
			{/*	<Button type="button" className="btn-secondary" onClick={() => setOpenStatsModal(!openStatsModal)}>View*/}
			{/*        Stats</Button>*/}
			{/*</div>*/}

			<div className="w-full mb-10 float-left">
				<Field
					label="Date of Inspection *"
					name="date_of_inspection"
				>
					<Datepicker
						name="date_of_inspection"
						type="date"
						value={data?.date_of_inspection}
						disabled={!canEdit}
						onChange={setData}
					/>
				</Field>
			</div>

			{
				canEdit && (
					<form className="clear-both" key={data?.observations?.length ?? 0} onSubmit={handleCreate}>
						<Form data={newObservation} setData={setNewObservation} loadData={loadNewObservation}/>
						<div className="flex justify-end w-full mb-6">
							<Button type="submit"
								disabled={!newObservation?.type || !newObservation?.status_id}>Create</Button>
						</div>
					</form>
				)
			}

			{openStatsModal && (
				<Modal openModal={openStatsModal} onClose={() => setOpenStatsModal(!openStatsModal)}>
					<Chart options={byValueChartOptions} series={byValueChartOptions.series} type="pie" height={350}/>
					<div className="w-full flex justify-end mt-6">
						<strong className="text-black">Total
                            Defects/Observations: {data?.observations?.length ?? 0}</strong>
					</div>
				</Modal>
			)}

			{
				data?.observations && data?.observations?.length > 0 && (
					<div className="tables clear-both mt-6  overflow-hidden  responsive-tables">
						<table className="w-full">
							<thead>
								<tr>
									<th className="text-left">Number</th>
									<th className="text-left">Type</th>
									<th className="text-left">Defect</th>
									<th className="text-left">Photographs</th> 
									<th className="text-left">Status</th>
									<th className="text-left">Actions</th>
								</tr>
							</thead>
							<tbody>
								{data?.observations?.map((observation, index) => (
									<tr key={index}>
										<td>{observation?.count}</td>
										<td>{observation?.type}</td>
										<td>
											{observation?.defect && (
												<div dangerouslySetInnerHTML={{ __html: observation?.defect }}/>)}
										</td>
										<td>
											{observation?.images?.map((image, index) => (
												<div key={index} className="ObservationImage">
													{(image?.attachment?.url ?? image?.attachment?.uuid) ?
														(
															<img key={index}
																src={image?.attachment?.url ?? (isBase64(image?.attachment?.uuid as string) ? image?.attachment?.uuid : route('api.file', {
																	path: 'tmp/' + image?.attachment?.uuid,
																}))}
																alt=""/>
														) : null}
												</div>
											))}
										</td>
										<td className={getStatusTextColour(observation?.status?.label ?? 'Open')}>
											<b>{observation?.status?.label ?? 'Open'} <br/>
												{moment(observation?.status_changed_at ?? moment()).format('DD/MM/YYYY')}</b>
										</td>
										<td>
											{canEdit && (
												<div className="flex gap-2">
													<Button type="button" styleName="secondary" className="icon-only"
														onClick={() => handleEdit(observation)}><PencilIcon
															className="text-white"/></Button>
													<Button styleName="danger" type="button"
														onClick={() => handleDelete(observation)}><BinIcon/></Button>
												</div>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)
			}


			{openEditModal && (
				<Modal openModal={openEditModal} onClose={() => setOpenEditModal(!openEditModal)}>
					{
						canEdit && (
							<form key={observation?.id} onSubmit={handleUpdate}>
								<Form data={observation} setData={setObservation} loadData={loadObservation}/>

								<div className="flex w-full justify-end">
									<Button type="submit" className="btn-success"
										disabled={!observation?.type || !observation?.status_id}>Update</Button>
								</div>
							</form>
						)
					}
				</Modal>
			)}
			{children}
		</div>
	);
};

export default ObservationForm;
