import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import Button from '@/Components/Button';
import { Reports } from '@/Types/Models/Projects/report';
import Modal from '@/Components/Modals/Modal';
import Checkbox from '@/Components/Inputs/Checkbox';
import API from '@/Services/API';
import route from 'ziggy-js';
import { Project } from '@/Types/Models/Projects/project';
import useForm from '@/Hooks/useForm';
import { useAuth } from '@/Contexts/AuthContext';

interface IProps {
    week: number;
    project: Project;
    reports: Reports;
}

interface IData {
	week: number;
	types: string[];
}

const DeleteReports: FunctionComponent<IProps> = ({ week, project, reports }) => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { data, setData } = useForm<IData>({
		types: [],
		week: week
	});

	const handleDelete = () => {
		API.delete(route('api.projects.reports.delete-by-week', project.id), {
			data: {
				...data,
			}
		}).then(() => {
			setOpenModal(false);
			window.location.reload();
		});
	};

	const handleUpdateTypes = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setData('types', [
				...(data.types ?? []),
				e.target.value
			]);
		} else {
			setData('types', (data.types ?? []).filter((type) => type !== e.target.value));
		}
	};

	return (
		<>
			<Button styleName="danger" type="button" onClick={() => setOpenModal(!openModal)}>Delete Reports</Button>

			{openModal && (
				<Modal openModal={openModal} onClose={() => setOpenModal(!openModal)}>
					<div>
						<h3 className="mb-3">Delete Reports For Week {data.week}</h3>

						<div className="grid grid-cols-2 gap-3 mb-6">
							{
								reports.filter(report => report.weekly_report_sections?.length || report.observations?.length || report.snags?.length).map((report, index) => (
									<div key={index} className="grid-span-1 my-auto">
										<Checkbox
											checked={report.type ? data.types.includes(report.type) : false}
											onChange={handleUpdateTypes}
											label={report.type}
											value={report.type}
										/>
									</div>
								))
							}
						</div>

						<div className="w-full flex justify-end mt-6">
							<Button styleName="danger" type="button" onClick={handleDelete} disabled={!(data.types !== undefined && data.types.length > 0)}>Delete</Button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default DeleteReports;