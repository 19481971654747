import { useEffect, useState } from 'react';

interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;

    prompt(): Promise<void>;
}

const useAddToHomescreenPrompt = (): [
        IBeforeInstallPromptEvent | null,
    () => void
] => {
	const [prompt, setState] = useState<IBeforeInstallPromptEvent | null>(null);

	const promptToInstall = () => {
		if (prompt) {
			return prompt.prompt();
		}
		return Promise.reject(
			new Error(
				'Tried installing before browser sent "beforeinstallprompt" event'
			)
		);
	};

	useEffect(() => {
		const ready = (e: IBeforeInstallPromptEvent) => {
			e.preventDefault();
			setState(e);
		};

		window.addEventListener('beforeinstallprompt', ready as any);

		return () => {
			window.removeEventListener('beforeinstallprompt', ready as any);
		};
	}, []);

	return [prompt, promptToInstall];
};

export default useAddToHomescreenPrompt;