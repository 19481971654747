import { FunctionComponent } from 'react';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { classNames } from '@/Utilities/helpers';

const PencilIcon: FunctionComponent<{ className?: string }> = ({ className = '' }) => (
	<FontAwesomeIcon icon={faPencil}
		className={classNames('h-4 w-4 shrink-0 text-black', className)}
		aria-hidden="true"
	/>
);

export default PencilIcon;
