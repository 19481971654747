import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Section } from '@/Components/Sections';
import { Table } from '@/Components/Tables';
import DeleteConfirmation from '@/Components/Modals/DeleteConfirmation';
import Button from '@/Components/Button';
import { User } from '@/Types/Models/Auth/user';
import route from 'ziggy-js';
import { PaginatedData } from '@/Components/Tables/TableElement';
import PencilIcon from '@/Components/PencilIcon';
import BinIcon from '@/Components/BinIcon';
import { Link, useSearchParams } from 'react-router-dom';
import { useLayout } from '@/Contexts/LayoutContext';
import API from '@/Services/API';
import { debounce } from 'lodash';
import reactRoute from '@/Utilities/routes';
import Loader from '@/Components/Loader';
import { useAuth } from '@/Contexts/AuthContext';

const Index: FunctionComponent = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [users, setUsers] = useState<PaginatedData<User> | undefined>(undefined);
	const { setHeader, setAction } = useLayout();
	const { can } = useAuth();

	const getData = useCallback(
		debounce((searchParams: URLSearchParams) => {
			API.get(route('api.users.index'), {
				params: {
					page: searchParams.get('page'),
					per_page: searchParams.get('per_page') ?? 20,
					search: searchParams.get('search')
				}
			}).then(({ data }) => {
				setUsers(data?.data);
			});
		}, 500),
		[]
	);

	const setParam = (name: string, value: string | number) => {
		searchParams.delete(name);
		searchParams.append(name, value.toString());
		setSearchParams(searchParams);
	};

	useEffect(() => {
		setHeader && setHeader('Users');
		setAction && setAction(can && can('users.create') && (
			<Link to={reactRoute('users.create')}>
				<Button className="btn-success">Create User</Button>
			</Link>
		));

		getData(searchParams);
	}, []);

	useEffect(() => {
		getData(searchParams);
	}, [searchParams]);

	if (users === undefined) {
		return <Loader/>;
	}

	const tableConfig = {
		columns: [
			{
				title: 'Name',
				render: (user: User) =>
					user.id &&
                    <>
                    	{can && can('users.update') && (
                    		<>
                    			<Link
                    				to={user?.id.toString()}
                    				className="text-sm btn-link">
                    				{user.name}
                    			</Link>
                    			<div className="text-sm text-gray-500 mt-1">{user.email}</div>
                    		</>
                    	)}
                    	{!(can && can('users.update')) && (
                    		<>
                    			<div>{user.name}</div>
                    			<div className="text-sm text-gray-500">{user.email}</div>
                    		</>
                    	)}
                    </>

			},
			{
				title: 'Role',
				render: (user: User) => user?.role?.label
			},
			{
				title: 'Department',
				render: (user: User) => user?.department
			},
			{
				render: (user: User) =>
					user.id && can && can('users.update') &&
                    <Link
                    	to={user?.id.toString()}
                    	className="btn btn-secondary icon-only">
                    	<PencilIcon/>
                    </Link>

			},
			{
				render: (user: User) => user.id && can && can('users.delete') && <DeleteConfirmation
					isOpen={false}
					modalMessageText="Are you sure you want to delete?"
					modalHeaderText="Delete User"
					buttonText={<BinIcon/>}
					callback={() => {
						API.delete(route('api.users.destroy', [user.id])).then(() => {
							window.location.reload();
						});
					}}
				/>

			}
		]
	};

	return (
		<div className="UserIndexPage">
			<Section header={{
				title: 'All Users',
				onPerPageChange: (value) => setParam('per_page', value),
				perPageValue: typeof searchParams.get('per_page') === 'string' && (searchParams.get('per_page') as string).length > 0 ? parseInt(searchParams.get('per_page') as string) : 20,
				onSearchChange: (value) => setParam('search', value),
				searchValue: searchParams.get('search'),
				searchPlaceholder: 'Search Users'
			}}>
				<Table<User> data={users?.data ?? []} meta={users} config={tableConfig}/>
			</Section>
		</div>
	);
};

export default Index;
