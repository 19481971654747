import { TinyMCE } from 'tinymce';
import { Editor } from '@tinymce/tinymce-react';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import Field from './Field';
import Vapor from 'laravel-vapor';
import route from 'ziggy-js';
import API from '@/Services/API';
import { db } from '@/Services/IndexedDB';

interface IProps {
    moveRouteName?: string,
    moveRouteParams?: Record<string, string>,
    routeName?: string,
    routeParams?: Record<string, string>,
    placeholder?: string,
    css?: string,
    label?: string,
    name: string,
    value?: string,
    size?: number,
    disabled?: boolean,
    error?: string,
    onChange?: (name?: string, value?: string) => void,
    options?: Parameters<TinyMCE['init']>[0] & {
        selector?: undefined;
        target?: undefined;
    }
}

const WYSIWYG: FunctionComponent<IProps> = ({
	options,
	placeholder,
	css,
	label,
	name,
	value,
	size = 400,
	disabled,
	error,
	onChange,
	routeName = 'api.file',
	routeParams = {},
	moveRouteName = 'api.files.move-file',
	moveRouteParams = {}
}) => {
	const [valueState, setValueState] = useState(value ?? '');
	const [isEditorInitialized, setIsEditorInitialized] = useState(false);
	const editorRef = useRef<any>(null);

	// useEffect(() => {
	// 	if (!isEditorInitialized || !editorRef.current) {
	// 		return;
	// 	}
	//
	// 	const handlePaste = (event: ClipboardEvent) => {
	// 		const clipboard = event.clipboardData;
	// 		const originalHtml = clipboard?.getData('text/html');
	//
	// 		const parser = new DOMParser();
	// 		const doc = parser.parseFromString(originalHtml ?? '', 'text/html');
	//
	// 		console.log(originalHtml);
	//
	// 		const elems = doc.body.querySelectorAll('*');
	// 		elems.forEach((elem: any) => {
	// 			const preservedStyles = ['color', 'text-decoration'];
	//
	// 			for (const styleName of Object.keys(elem.style)) {
	// 				if (!preservedStyles.includes(styleName)) {
	// 					elem.style.removeProperty(styleName);
	// 				}
	// 			}
	//
	// 			elem.removeAttribute('id');
	// 			elem.removeAttribute('class');
	// 		});
	//
	// 		const preTags = doc.body.querySelectorAll('pre');
	//
	// 		preTags.forEach((preTag) => {
	// 			const pTag = document.createElement('p');
	// 			const preservedStyles = ['color', 'text-decoration'];
	//
	// 			for (const styleName of Object.keys(preTag.style)) {
	// 				if (preservedStyles.includes(styleName) && preTag?.style?.[styleName as any] !== undefined) {
	// 					pTag.style.setProperty(styleName, preTag.style[styleName as any]);
	// 				}
	// 			}
	// 			pTag.innerHTML = preTag.innerHTML;
	// 			preTag.replaceWith(pTag);
	// 		});
	//
	// 		editorRef.current.setContent(editorRef.current.getContent() + doc.body.innerHTML);
	//
	// 		event.preventDefault();
	// 	};
	//
	// 	if (editorRef.current) {
	// 		const editor = editorRef.current;
	//
	// 		editor.on('paste', handlePaste);
	// 	}
	//
	// 	return () => {
	// 		if (editorRef.current) {
	// 			const editor = editorRef.current;
	// 			editor.off('paste', handlePaste);
	// 		}
	// 	};
	// }, [isEditorInitialized]);

	useEffect(() => {
		if (valueState !== value) {
			setValueState(value ?? '');
		}
	}, [value]);

	/**
     * Handle changed on the editor
     *
     * @param content
     */
	const handleEditorChange = (content: string) => {
		if (onChange) onChange(name, content);

		setValueState(content);
	};

	/**
     * Set up the ref to the WYSIWYG
     * @param evt
     * @param editor
     */
	const onInit = (evt: any, editor: any) => {
		editorRef.current = editor;
		setIsEditorInitialized(true);
	};

	return (
		<Field label={label} name={name} disabled={disabled} error={error}>
			<Editor
				tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
				onInit={onInit}
				init={{
					paste_as_text: true,
					promotion: false,
					skin: true,
					content_css: 'html{overflow-y:scroll!important;}' + (css ?? ''),
					min_height: size,
					max_height: 800,
					scrollbars: true,
					menubar: true,
					resize: true,
					toolbar_mode: 'wrap',
					quickbars_insert_toolbar: false,
					quickbars_selection_toolbar: false,
					quickbars_image_toolbar: false,
					browser_spellcheck: true,
					contextmenu: false,
					mobile: {
						menubar: false
					},
					placeholder: placeholder,
					plugins: 'advlist anchor autolink autoresize autosave charmap code codesample directionality emoticons fullscreen help image importcss insertdatetime link lists media nonbreaking pagebreak preview quickbars save searchreplace table template visualblocks visualchars wordcount',
					toolbar: 'undo redo | bold italic underline forecolor backcolor | bullist numlist outdent indent | blocks | alignleft aligncenter | link image table | formatselect | ' +
                        'alignright alignjustify | ' +
                        'removeformat | wordcount | help',
					file_picker_types: 'file image media',
					images_fetch_before: (blobInfo: any, success: any, failure: any) => {
						// Add your custom header here
						const xhr = new XMLHttpRequest();
						xhr.open('GET', blobInfo.blob(), true);
						xhr.setRequestHeader('Your-Custom-Header', 'Your-Custom-Value');

						xhr.onreadystatechange = function () {
							if (xhr.readyState == 4 && xhr.status == 200) {
								// Return the modified blob
								success(blobInfo.blob());
							} else if (xhr.readyState == 4) {
								// Something went wrong
								failure('Failed to fetch image');
							}
						};

						xhr.send();
					},
					image_title: false,
					images_upload_credentials: true,
					images_upload_handler: (blobInfo) => new Promise((resolve, reject) => {
						const file = new File([blobInfo.blob()], blobInfo.filename());

						db.tokens.orderBy(':id').last().then(token => {
							Vapor.store(file, {
								baseURL: process.env.REACT_APP_API_URL ?? 'http://cow-api.test/',
								visibility: 'private',
								headers: {
									Authorization: token ? `Bearer ${token?.plainTextToken}` : '',
									Accept: 'application/json',
									'X-Requested-With': 'XMLHttpRequest'
								}
							})
								.then((response) => {
									const newPath = response.key.replace('tmp/', 'wysiwyg/');

									API.post(route(moveRouteName, {
										...moveRouteParams
									}), {
										path: response.key,
										new_path: newPath
									}).then(() => {
										resolve(route(routeName, {
											...routeParams,
											path: newPath
										}, true));
									}).catch((e) => {
										console.error(e);
										reject(e);
									});
								})
								.catch((e) => {
									console.error(e);
									reject(e);
								});
						});
					}),
					...options
				}}
				value={valueState}
				onEditorChange={handleEditorChange}
				textareaName={name}
				disabled={disabled}
			/>
		</Field>
	);
};

export default WYSIWYG;
