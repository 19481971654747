import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import Field from '@/Components/Inputs/Field';
import { User } from '@/Types/Models/Auth/user';
import { FormProps } from '@/Hooks/useForm';
import Select from '@/Components/Inputs/Select';
import AsyncSelect from '@/Components/Inputs/AsyncSelect';

interface IProps extends FormProps<User>, PropsWithChildren {
    user?: User
}

const Form: FunctionComponent<IProps> = ({ user, data, errors, setData, children }) => {
	const [sendResetPasswordEmail, setSendResetPasswordEmail] = useState<string>('1');

	useEffect(() => {
		setData('password', undefined);
	}, [sendResetPasswordEmail]);

	return (
		<div className="UserForm grid grid-cols-2 gap-6 relative">
			<div className="col-span-1">
				<Field
					label="Full Name *"
					name="name"
					value={data.name}
					onChange={setData}
					error={errors?.name}
				/>
			</div>
			<div className="col-span-1">
				<Field
					label="Email *"
					name="email"
					value={data.email}
					onChange={setData}
					error={errors?.email}
				/>
			</div>
			<div className="col-span-1">
				<Field
					label="Contact Number *"
					name="phone"
					value={data.phone}
					onChange={setData}
					error={errors?.phone}
				/>
			</div>
			<div className="col-span-1">
				<Field
					label="Department *"
					name="department"
				>
					<Select
						options={[
							'M&E',
							'Building'
						].map(department => ({ value: department, label: department }))}
						value={data.department}
						name="department"
						onChange={setData}
					/>
				</Field>
			</div>
			<div className="col-span-1">
				<Field
					label="Role *"
					name="role_id"
				>
					<AsyncSelect
						routeName="api.roles.index"
						selectRouteName="api.roles.show"
						selectParams={{ role: data?.role_id }}
						allowNullSearch={true}
						labelKey="label"
						value={data?.role_id}
						valueKey="id"
						onChange={(_, value) => setData && setData('role_id', parseInt(value))}
						placeholder="Select Role..."
					/>
				</Field>
			</div>
			{
				user === undefined && (
					<div className="col-span-1">
						<Field
							label="Send Password Reset Email?"
							name="send_password_reset_email"
							type="yes_no"
							value={sendResetPasswordEmail}
							onChange={(_, value) => setSendResetPasswordEmail(value)}
						/>
					</div>
				)
			}
			{
				(user || sendResetPasswordEmail === '0') && (
					<div className="col-span-1">
						<Field
							label={user ? 'Password' : 'Password *'}
							name="password"
							type="password"
							placeholder="********"
							value={data.password}
							onChange={setData}
							error={errors?.password}
						/>
						{
							user && (
								<span className="float-left w-full block mt-2">Leave blank if you dont wish to update the password</span>)
						}
					</div>
				)
			}

			{children}
		</div>
	);
};

export default Form;
