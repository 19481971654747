import { Fragment, FunctionComponent, ReactNode, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { ButtonStyleName } from '../Button';

interface IProps {
    message: string;
    title: string;
    buttonText: string | ReactNode;
    buttonStyle?: ButtonStyleName;
    onConfirm?: (setDeleting: (deleting: boolean) => void) => void;
    confirmButtonText?: string;
    cancelButtonText?: string;
    buttonClasses?: string;
    disabled?: boolean;
}

const Confirmation: FunctionComponent<IProps> = ({
	message,
	title,
	buttonText,
	buttonStyle,
	onConfirm,
	confirmButtonText = 'Confirm',
	cancelButtonText = 'Cancel',
	buttonClasses,
	disabled = false
}) => {

	const [open, setOpen] = useState(false);
	const [deleting, setDeleting] = useState(false);

	const closeModal = () => {
		setOpen(false);

		onConfirm && onConfirm(setDeleting);
	};

	return (
		<>
			<Button type="button" disabled={disabled} styleName={buttonStyle} className={buttonClasses}
				onClick={() => setOpen((open) => !open)} processing={deleting}>
				{buttonText}
			</Button>
			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div"
					className="fixed z-50 overflow-hidden px-6 py-6 top-0 left-0 w-full h-full min-h-screen"
					onClose={setOpen}>
					<div
						className="flex justify-center h-full float-left text-center items-center content-center w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay
								className="fixed inset-0 modal-background transition-opacity bg-slate-200 bg-opacity-75"/>
						</Transition.Child>


						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								className="inline-block bg-white modal-content  p-6 text-left overflow-hidden shadow-xl transform transition-all confirmation-modal">

								<div className="flex float-left w-full h-auto">
									<div
										className="icon-block pr-4">
										<FontAwesomeIcon icon={faExclamationCircle}
											className="float-left w-full h-auto block"
											aria-hidden="true"
										/>
									</div>
									<div className="text-left content">
										<Dialog.Title as="h3" className="float-left w-full mb-2 d-block">
											{title}
										</Dialog.Title>
										<p className="float-left w-full d-block">
											{message}
										</p>

									</div>
								</div>
								<div className="mt-6 float-left w-full flex justify-between">

									<Button
										type="button"
										styleName="danger"
										onClick={() => setOpen(false)}
									>
										{cancelButtonText}
									</Button>

									<Button
										type="button"
										styleName="positive"
										onClick={() => closeModal()}
									>
										{confirmButtonText}
									</Button>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default Confirmation;
