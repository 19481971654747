import React, { ChangeEvent, FunctionComponent, PropsWithChildren, ReactElement, ReactNode } from 'react';
import Search from '@/Components/Inputs/Search';
import Select from '@/Components/Inputs/Select';

interface IProps extends PropsWithChildren {
    title: string;
    onSearchChange?: (value: string) => void;
    searchValue?: string | null;
    searchPlaceholder?: string | null;
    onPerPageChange?: (value: number) => void;
    perPageOptions?: { value: number; label: string }[];
    perPageValue?: number | null;
    actions?: ReactElement | React.JSX.Element | ReactNode | string;
    filters?: ReactElement | React.JSX.Element | ReactNode | string;
}

const SectionHeaderElement: FunctionComponent<IProps> = ({
	title,
	onSearchChange,
	searchValue = '',
	searchPlaceholder = '',
	children,
	onPerPageChange,
	perPageOptions,
	perPageValue,
	actions,
	filters
}) => {
	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
		onSearchChange &&
        onSearchChange(e.target.value as string);

	const handlePerPageChange = (_: string | undefined, value: any) =>
		onPerPageChange &&
        onPerPageChange(value as number);

	return (
		<div className="mb-6 flex justify-between items-center content-center section-header">
			{title && <h3 className="mr-auto section-header__title">{title}</h3>}
			{children && (
				<div className="float-right ml-auto section-header__main mr-4">
					{children}
				</div>
			)}

			{actions && (
				<div className="flex section-header__actions mr-4">
					{actions}
				</div>
			)}

			{onPerPageChange && (
				<div className="flex mr-4 section-header__per-page">
					<div className="relative flex-grow focus-within:z-10 per-page form-group">
						<Select
							options={perPageOptions ?? [
								{ value: 10, label: 'Show 10 Per Page' },
								{ value: 20, label: 'Show 20 Per Page' },
								{ value: 30, label: 'Show 30 Per Page' },
								{ value: 40, label: 'Show 40 Per Page' },
								{ value: 50, label: 'Show 50 Per Page' }
							]}
							value={perPageValue}
							name="per_page"
							onChange={handlePerPageChange}
						/>
					</div>
				</div>
			)}

			{onSearchChange && (
				<div className="flex mr-4 section-header__search">
					<div className="relative flex-grow focus-within:z-10 search form-group">
						<Search
							type="text"
							defaultValue={searchValue ?? ''}
							placeholder={searchPlaceholder ?? ''}
							name="section_search"
							onChange={handleSearchChange}
						/>
					</div>
				</div>
			)}

			{filters}
		</div>
	);
};

export { SectionHeaderElement as default, type IProps as IHeader };
