import { Context, createContext, FunctionComponent, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { User } from '@/Types/Models/Auth/user';
import { db, Device, JSONResource } from '@/Services/IndexedDB';
import { Token } from '@/Types/Models/Auth/token';
import posthog from 'posthog-js';
import { Project } from '@/Types/Models/Projects/project';
import { WeeklyReportSections } from '@/Types/Models/Projects/weeklyreportsections';
import { Observations } from '@/Types/Models/Projects/observation';
import { Snags } from '@/Types/Models/Projects/snag';
import { base64toFile } from '@/Utilities/helpers';
import Vapor from 'laravel-vapor';
import { AxiosError } from 'axios';
import reactRoute from '@/Utilities/routes';

interface IAuthContext {
	device?: Device;
	setDevice?: (device?: Device) => void;
    user?: User;
    setUser?: (user?: User) => void;
    isAuthenticated?: null | boolean;
    token?: Token;
	setToken?: (token?: Token) => void;
    removeSession?: () => void;
    can?: (permission: string) => boolean;
    offline?: boolean;
    mobileMode?: boolean;
    setMobileMode?: (mobileMode: boolean) => void;
	photoCount?: number;
	photoLimit: number;
	setPhotoCount?: (photoCount: number) => void;
	incrementPhotoCount?: () => void;
	deincrementPhotoCount?: () => void;
	calculatePhotoCount?: () => void;
}

const AuthContext: Context<IAuthContext> = createContext({
	photoLimit: 50,
});

const AuthProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
	const [user, setU] = useState<User | undefined>();
	const [token, setT] = useState<Token | undefined>();
	const [device, setD] = useState<Device | undefined>();
	const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null);
	const [offline, setOffline] = useState<boolean | undefined>(undefined);
	const [mobileMode, setMobileMode] = useState<boolean>(
		(!!navigator.maxTouchPoints && navigator.maxTouchPoints > 2) || localStorage.getItem('mobileMode') === 'true'
	);
	const [photoCount, setPhotoCount] = useState<number>(0);

	const calculatePhotoCount = () => {
		let tempPhotoCount = 0;

		db.projects.orderBy(':id').each(project => {
			if (project) {
				(project as Project)?.reports?.forEach(report => {
					for (const sectionName of ['weekly_report_sections', 'observations', 'snags']) {
						const reportSections = report[sectionName] as WeeklyReportSections | Observations | Snags;
						if (reportSections) {
							for (const section of reportSections) {
								if (section?.images !== undefined) {
									section.images.forEach(image => {
										if (image?.attachment?.id === undefined && image?.attachment?.url.includes('data:image')) {
											tempPhotoCount++;
										}
									});
								}
							}
						}
					}
				});
			}
		}).then(() => {
			setPhotoCount(tempPhotoCount);
		});
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (!navigator.onLine && !offline) {
				setOffline(true);
			} else if (navigator.onLine && (offline !== false)) {
				setOffline(false);
			}
		}, 500);

		return () => clearInterval(intervalId);
	}, [offline]);

	useEffect(() => {
		db.device.orderBy(':id')
			.last()
			.then(device => {
				setD(device as Device);
			}).catch(() => setD(undefined));

		db.user.orderBy(':id')
			.first()
			.then(user => {
				if (user) {
					setU(user as User);
					setIsAuthenticated(true);
				} else {
					setIsAuthenticated(false);
				}
			}).catch(() => setIsAuthenticated(false));

		db.tokens.orderBy(':id')
			.last()
			.then(token => {
				setT(token as Token);
			}).catch(() => setT(undefined));

		calculatePhotoCount();
	}, []);

	useEffect(() => {
		localStorage.setItem('mobileMode', mobileMode ? 'true' : 'false');
	}, [mobileMode]);

	const setUser = (user?: User) => {
		if (user) {
			db.user.clear();
			db.user.add(user);
			setIsAuthenticated(true);

			if (user?.id) {
				posthog.identify(user.id.toString(), {
					name: user.name,
					email: user.email,
				});
			}
		} else {
			db.user.clear();
			setIsAuthenticated(false);
		}

		setU(user);
	};

	const setDevice = (device?: Device) => {
		if (device) {
			db.device.clear();
			db.device.add(device);
		}

		setD(device);
	};

	const setToken = (token?: Token) => {
		if (token) {
			db.tokens.clear();
			db.tokens.add(token as JSONResource);
		}

		setT(token);
	};

	const removeSession = () => {
		db.user.clear();
		db.tokens.clear();
		setIsAuthenticated(false);
	};

	const can = (permission: string) => {
		return Boolean(user?.permissions?.includes(permission));
	};

	const data: IAuthContext = {
		device,
		setDevice,
		user,
		setUser,
		isAuthenticated,
		token,
		setToken,
		removeSession,
		can,
		offline,
		mobileMode,
		setMobileMode,
		photoCount,
		photoLimit: 50,
		setPhotoCount,
		incrementPhotoCount: () => setPhotoCount(photoCount + 1),
		deincrementPhotoCount: () => setPhotoCount(photoCount - 1),
		calculatePhotoCount,
	};

	return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
const useAuth = () => {
	return useContext(AuthContext);
};

export {
	AuthProvider as default,
	AuthContext,
	useAuth
};