import React, { Fragment, FunctionComponent, ReactNode, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
    children?: ReactNode;
    onClose?: () => void;
    openModal?: boolean;
    showCloseButton?: boolean;
}

const Modal: FunctionComponent<IProps> = ({
	children,
	onClose,
	openModal = false,
	showCloseButton = true
}) => {
	const [open, setOpen] = useState(openModal);

	const closeModal = () => {
		setOpen(false);

		onClose && onClose();
	};

	return (
		<>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="fixed z-50 px-6 py-6 top-0 left-0 w-full h-full modal-outer"
					onClose={closeModal}
				>
					<div
						className="flex justify-center h-full float-left text-center items-center content-center w-full">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay
								className="fixed inset-0 modal-background transition-opacity bg-slate-200 bg-opacity-75"/>
						</Transition.Child>


						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div
								className="inline-block bg-white modal-content  p-6 text-left overflow-hidden shadow-xl transform transition-all ">
								{showCloseButton && (
									<div className="close-modal float-left w-full flex justify-end">
										<FontAwesomeIcon icon={faTimes}
											className={'h-4 w-4 shrink-0 text-black cursor-pointer'}
											aria-hidden="true"
											onClick={closeModal}
										/>
									</div>
								)}
								<div className="float-left w-full block">{children}</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default Modal;
