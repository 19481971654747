import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Report, Reports } from '@/Types/Models/Projects/report';
import route from 'ziggy-js';
import PencilIcon from '@/Components/PencilIcon';
import { Link, useNavigate } from 'react-router-dom';
import API from '@/Services/API';
import reactRoute from '@/Utilities/routes';
import Loader from '@/Components/Loader';
import { useAuth } from '@/Contexts/AuthContext';
import { db } from '@/Services/IndexedDB';
import { Project } from '@/Types/Models/Projects/project';
import CreateIcon from '@/Components/CreateIcon';
import Button from '@/Components/Button';
import EyeIcon from '@/Components/EyeIcon';
import DownloadReport from '@/Components/Projects/Reports/DownloadReport';
import EmailReports from '@/Components/Projects/Reports/EmailReports';
import { Image } from '@/Types/Models/Projects/image';
import DeleteReports from '@/Components/Projects/Reports/DeleteReports';

const Index: FunctionComponent<{ project: Project, isLocalCopy: boolean }> = ({ project, isLocalCopy }) => {
	const navigate = useNavigate();

	const { user, offline, mobileMode } = useAuth();

	const [reports, setReports] = useState<Reports | undefined>(undefined);
	// const [isLocalCopy, setIsLocalCopy] = useState<boolean>(false);
	const [weeks, setWeeks] = useState<number>(0);
	const [newWeekInProgress, setNewWeekInProgress] = useState<boolean>(false);

	const getData = useCallback((id: string | number, offline: boolean | undefined) => {
		db.projects.get(typeof id === 'string' ? parseInt(id) : id).then(project => {
			if (project) {
				setReports(project.reports);
				setWeeks(project.reports.reduce((max: number, report: Report) => (report.week && report.week > max ? report.week : max), (project?.reports?.[0]?.week ?? 0)));
				// setIsLocalCopy(true);
			} else {
				if (!offline) {
					API.get(route('api.projects.reports.index', id), {
						headers: {
							'PWA-No-Cache': 'true'
						}
					}).then(({ data }) => {
						setReports(data.data);
						setWeeks(data.weeks ?? 0);
					});
				}
			}

		}).catch(() => {
			if (!offline) {
				API.get(route('api.projects.reports.index', id), {
					headers: {
						'PWA-No-Cache': 'true'
					}
				}).then(({ data }) => {
					setReports(data.data);
					setWeeks(data.weeks ?? 0);
				});
			} else {
				navigate(reactRoute('not-found'));
			}
		});
	}, []);

	useEffect(() => {
		project.id && getData(project.id, offline);
	}, []);

	useEffect(() => {
		project.id && getData(project.id, offline);
	}, [project]);

	const handleAddWeek = () => {
		setNewWeekInProgress(true);
		if (project.id && reports) {
			if (isLocalCopy) {
				db.projects.get(project.id).then(project => {
					const data = project as Project;

					const latestReports = data.reports?.filter(report => report.week === weeks).map(report => {
						return {
							...report,
							id: undefined,
							week: weeks + 1,
							date_of_inspection: undefined,
							weekly_report_sections: report?.weekly_report_sections?.map(section => {
								return {
									...section,
									id: undefined,
									report_id: undefined,
									images: section?.images?.map(image => {
										return {
											...image,
											id: undefined,
											relation_id: undefined,
											attachment: image?.attachment ? {
												...image?.attachment,
												to_replicate: true,
												relation_id: undefined
											} : undefined,
										};
									})
								};
							}),
							observations: report?.observations?.map(observation => {
								return {
									...observation,
									id: undefined,
									report_id: undefined,
									images: observation?.images?.map(image => {
										return {
											...image,
											id: undefined,
											relation_id: undefined,
											attachment: image?.attachment ? {
												...image?.attachment,
												to_replicate: true,
												relation_id: undefined
											} : undefined,
										};
									})
								};
							}),
							snags: report?.snags?.map(snag => {
								return {
									...snag,
									id: undefined,
									report_id: undefined,
									images: snag?.images?.map((image): Image => {
										return {
											...image,
											id: undefined,
											relation_id: undefined,
											attachment: image?.attachment ? {
												...image?.attachment,
												to_replicate: true,
												relation_id: undefined
											} : undefined,
										};
									})
								};
							}),
						};
					});

					if (latestReports && project?.id) {
						setReports([...reports, ...latestReports]);
						db.projects.update(project.id, {
							...project,
							reports: [
								...(project?.reports ?? []),
								...latestReports
							]
						});
					}

					setWeeks(weeks + 1);
					setNewWeekInProgress(false);
				});
			} else {
				API.post(route('api.projects.reports.replicate', project.id), { week: weeks + 1 }).then(() => {
					project.id && getData(project.id, offline);
					setNewWeekInProgress(false);
				});
			}
		}
	};

	if (reports === undefined) {
		return <Loader/>;
	}

	return (
		<div className="ReportIndexPage">
			<div className="flex justify-between mb-6">
				{
					(mobileMode && isLocalCopy) && weeks > 6 && (
						<p>Please note that only the last 6 weeks are available on the local copy.</p>
					)
				}
				{weeks >= 1 && ((mobileMode && isLocalCopy) || (!mobileMode && !isLocalCopy)) && reports.filter(r => r.id === undefined).length === 0 ? (
					<Button type="button" className="btn-secondary" onClick={handleAddWeek} processing={newWeekInProgress}>Add Week</Button>
				) : (isLocalCopy && (<p>You must sync before you add another week.</p>))}
			</div>
			<div className="overflow-hidden float-left w-full block h-auto tables responsive-tables">
				<table className="min-w-full ">
					<thead>
						<tr>
							<th>Week</th>
							<th>Weekly Report</th>
							<th>Defect and Observation Report</th>
							<th>Snag Report</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{
							project.id && [...Array((weeks || 1))].map((_, index) => (reports.filter(report => report.week === index + 1).length > 0 || reports.length === 0) && (
								<tr key={index}>
									<td>{index + 1}</td>
									{
										['Weekly Report', 'Defect and Observation Report', 'Snag Report'].map((type, i) => (
											<td align="center" className="text-center" key={i}>
												{
													reports?.find(report => report.week === index + 1 && report.type === type) ? (
														<Link
															to={
																reports?.find(report => report.week === index + 1 && report.type === type)?.id ?
																	reactRoute('projects.reports.show', [project?.id, reports?.find(report => report.week === index + 1 && report.type === type)?.id])
																	:
																	reactRoute('projects.reports.show', [project?.id, undefined])
															}
															state={{
																week: index + 1,
																type,
																canEdit: weeks === reports?.find(report => report.week === index + 1 && report.type === type)?.week && (project.locked_by_id === user?.id || project.locked_by_id === undefined || project.locked_by_id === null) && ((mobileMode && isLocalCopy) || (!mobileMode && !isLocalCopy)),
															}}
															className="btn btn-secondary icon-only">
															{
																weeks === index + 1 && ((mobileMode && isLocalCopy) || (!mobileMode && !isLocalCopy)) ? (
																	<PencilIcon/>
																) : (
																	<EyeIcon/>
																)
															}
														</Link>
													) : (
														<>
															{
																(weeks === (index + 1) || weeks === 0) && ((mobileMode && isLocalCopy) || (!mobileMode && !isLocalCopy)) ? (
																	<Link
																		to={reactRoute('projects.reports.create', [project.id])}
																		state={{ week: index + 1, type }}
																		className="btn btn-secondary icon-only">
																		<CreateIcon/>
																	</Link>
																) : (
																	<>N/A</>
																)
															}
														</>
													)
												}
											</td>
										))
									}

									<td align="center">
										{
											reports?.find(report => report.week === index + 1) && !offline && !isLocalCopy && (
												<div className="flex gap-4">
													<DownloadReport project={project}
														reports={reports?.filter(report => report.week === index + 1)}/>
													<EmailReports project={project}
														reports={reports?.filter(report => report.week === index + 1)}/>
													{
														user?.role?.name === 'administrator' && reports[0].week && !mobileMode && project.locked_by_id === undefined && (
															<DeleteReports week={index + 1} project={project} reports={reports?.filter(report => report.week === index + 1)}/>
														)
													}
												</div>
											)
										}
									</td>
								</tr>
							))
						}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default Index;
