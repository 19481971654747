import React, { FunctionComponent, useState } from 'react';
import { Project } from '@/Types/Models/Projects/project';
import { useAuth } from '@/Contexts/AuthContext';
import API from '@/Services/API';
import route from 'ziggy-js';
import Confirmation from '@/Components/Modals/Confirmation';

const CompleteButton: FunctionComponent<{ project: Project }> = ({ project }) => {
	const { offline } = useAuth();
	const [completed, setCompleted] = useState<boolean>(project?.status?.name === 'completed');

	const handleChange = (project: Project) => () => {
		API.post(route('api.projects.toggle-status', [project.id])).then(() => {
			setCompleted(!completed);
			if (!completed) {
				localStorage.setItem('flash-success', 'Project has been marked as complete.');
			} else {
				localStorage.setItem('flash-success', 'Project has been marked as active.');
			}
		});
	};

	if (offline) {
		return <></>;
	}

	return (
		<>
			{
				completed ? (
					<Confirmation
						message="Are you sure you want to mark this project as active?"
						onConfirm={handleChange(project)}
						buttonStyle="primary"
						buttonText="Mark as Active"
						title="Confirmation"
					/>
				) : (
					<Confirmation
						message="Are you sure you want to mark this project as complete?"
						onConfirm={handleChange(project)}
						buttonStyle="positive"
						buttonText="Mark as Complete"
						title="Confirmation"
					/>
				)
			}
		</>
	);
};

export default CompleteButton;