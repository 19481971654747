import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Section } from '@/Components/Sections';
import Tabs, { ITab } from '@/Components/Tabs';
import route from 'ziggy-js';
import Edit from './Edit';
import { useLayout } from '@/Contexts/LayoutContext';
import { useParams, useSearchParams } from 'react-router-dom';
import API from '@/Services/API';
import reactRoute from '@/Utilities/routes';
import Activity from '@/Components/Activity';
import Models from '@/Utilities/models';
import Loader from '@/Components/Loader';
import Comments from '@/Components/Comments';
import { Client } from '@/Types/Models/Clients/client';

interface IProps {
    tab?: string;
}

const Show: FunctionComponent<IProps> = () => {
	const { setHeader } = useLayout();
	const { id } = useParams();
	const [client, setClient] = useState<Client | null>(null);
	const [searchParams] = useSearchParams();

	const getData = useCallback((id: string | number) => {
		API.get(route('api.clients.show', id)).then(({ data }) => {
			setClient(data.data);
		});
	}, []);

	useEffect(() => {
		if (id) {
			getData(id);
		}
	}, [id]);

	useEffect(() => {
		client && setHeader && setHeader(`Edit Client: ${client?.name}`);
	}, [client]);

	if (client === null) {
		return <Loader/>;
	}

	const tabs: ITab[] = [
		{
			name: 'client',
			label: 'Client',
			href: client.id ? reactRoute('clients.show', [client.id], { tab: 'client' }) : undefined,
			Render: client ? <Edit client={client}/> : null
		},
		{
			name: 'comments',
			label: 'Comments',
			href: client.id ? reactRoute('clients.show', [client.id], { tab: 'comments' }) : undefined,
			Render: client.id ? <Comments model={Models.Client} id={client.id}/> : undefined
		},
		{
			name: 'activity',
			label: 'Activity',
			href: client.id ? reactRoute('clients.show', [client.id], { tab: 'activity' }) : undefined,
			Render: client.id ? <Activity model={Models.Client} id={client.id}/> : undefined
		}
	];

	return (
		<div className="ClientShowPage">
			<Section>
				<Tabs tabs={tabs} activeTabName={searchParams.get('tab') ?? 'client'}/>
			</Section>
		</div>
	);
};

export default Show;