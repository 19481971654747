import React, { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FormProps } from '@/Hooks/useForm';
import { Report } from '@/Types/Models/Projects/report';
import Select from '@/Components/Inputs/Select';
import Field from '@/Components/Inputs/Field';
import Button from '@/Components/Button';
import { WeeklyReportSection } from '@/Types/Models/Projects/weeklyreportsections';
import WYSIWYG from '@/Components/Inputs/WYSIWYG';
import Datepicker from '@/Components/Inputs/Datepicker';
import Label from '@/Components/Inputs/Label';
import FileUpload from '@/Components/FileUpload';
import { Image } from '@/Types/Models/Projects/image';
import { useAuth } from '@/Contexts/AuthContext';

interface IProps extends FormProps<Report>, PropsWithChildren {
    canEdit?: boolean;
}

interface IWeeklyReportSectionForm extends FormProps<Report> {
    index: number;
    section?: WeeklyReportSection;
    canEdit?: boolean;
}

const WeeklyReportSectionForm: FunctionComponent<IWeeklyReportSectionForm> = ({
	section,
	index,
	data,
	canEdit = true,
	errors,
	setData
}) => {
	const { mobileMode, photoCount, photoLimit, incrementPhotoCount, deincrementPhotoCount } = useAuth();
	const previousImages = useRef(section?.images);

	const handleUpdateSection = (attribute?: string, value?: any) => {
		const section = data?.weekly_report_sections?.[index];
		if (section && attribute) {
			section[attribute as keyof WeeklyReportSection] = value;
			setData('weekly_report_sections', [
				...(data?.weekly_report_sections ?? []).slice(0, index),
				section,
				...(data?.weekly_report_sections ?? []).slice(index + 1),
			]);
		}
	};

	const handleImageChange = (imageIndex: number, attribute?: string, value?: any) => {
		const section = data?.weekly_report_sections?.[index];
		if (section && attribute) {
			const image = section?.images?.[imageIndex];
			if (image) {
				image[attribute as keyof Image] = value;
				setData('weekly_report_sections', [
					...(data?.weekly_report_sections ?? []).slice(0, index),
					section,
					...(data?.weekly_report_sections ?? []).slice(index + 1),
				]);
			}
		}
	};

	useEffect(() => {
		if (section && Array.isArray(previousImages.current) && Array.isArray(section?.images) && previousImages.current.length < section.images.length) {
			window.scroll(0, 100000);
		}

		previousImages.current = section?.images;
	}, [section?.images]);

	return (
		<div className="WeeklyReportSection bg-gray-100 p-5 mt-6">
			<div className="grid grid-cols-1 gap-6">
				<div className="col-span-1">
					<Field
						label="Section Title *"
						name="section"
						value={section?.name}
						disabled={true}
					/>
				</div>

				<div className="col-span-1">
					<Label>Section Information</Label>
					<WYSIWYG
						name="content"
						placeholder="Add your information..."
						value={section?.content}
						disabled={!canEdit}
						onChange={handleUpdateSection}
					/>
				</div>

				<div key={section?.images?.length} className="col-span-1">
					{
						section?.images?.map((image, index) => (
							<div key={index} className="grid grid-cols-3 gap-6 mb-6">
								<div className="col-span-1">
									<Field
										label={index === 0 ? 'Image *' : undefined}
										name="attachment"
									>
										<FileUpload
											name="attachment"
											visibility="private"
											label="Upload an image"
											maxFileSize="10MB"
											initialValue={image?.attachment}
											onChange={(name, data) => handleImageChange(index, name, data)}
											validateTypes={['image/jpeg']}
											compressImages={true}
										/>
									</Field>
								</div>
								<div className="col-span-1">
									<Field
										label={index === 0 ? 'Caption *' : undefined}
										name="caption"
										value={image?.caption}
										onChange={(name, data) => handleImageChange(index, name, data)}
										spellCheck={true}
									/>
								</div>

								<div className="col-span-1 flex items-end ">
									<Button
										type="button"
										className=""
										onClick={() => {
											mobileMode && deincrementPhotoCount && deincrementPhotoCount();
											handleUpdateSection('images', [
												...section?.images?.filter((_, i) => i !== index) ?? []
											]);
										}}
									>
                                        Remove Image
									</Button>
								</div>
							</div>
						))
					}

					{
						((mobileMode && (photoCount ?? 0) < photoLimit) || !mobileMode) && (
							<Button
								type="button"
								className="btn-secondary"
								onClick={() => {
									mobileMode && incrementPhotoCount && incrementPhotoCount();
									handleUpdateSection('images', [
										...(section?.images ?? []),
										{
											id: undefined,
											attachment: undefined,
											caption: '',
										}
									]);
								}}
							>
								Add Image
							</Button>
						)
					}
				</div>
			</div>
		</div>
	);
};

const WeeklyReportForm: FunctionComponent<IProps> = ({ canEdit = true, data, errors, setData, children }) => {
	const [section, setSection] = useState<string | number | undefined>();

	const handleAddSection = (value: string) => {
		if (value) {
			if (canEdit && data?.weekly_report_sections?.find(s => value === s.name) === undefined) {
				setData('weekly_report_sections', [
					...(data?.weekly_report_sections ?? []),
					{
						id: undefined,
						weekly_report_id: undefined,
						name: value,
						content: '',
					}
				]);
			}
			setSection(value);
		}
	};

	const handleDeleteSection = (value: string) => {
		if (value) {
			if (canEdit && data?.weekly_report_sections?.find(s => value === s.name) !== undefined) {
				setData('weekly_report_sections', [
					...(data?.weekly_report_sections ?? []).filter(s => value !== s.name),
				]);
			}
			setSection(undefined);
		}
	};

	const sectionNames = [
		'Program',
		'Progress Against Programme',
		'Site Activities',
		'Site Labour',
		'Weather',
		'Information Received/Required',
		'Quality Control',
		'Discussion with Site Operatives',
		'Design Queries',
		'Health & Safety',
		'Photographs',
	];

	return (
		<div className="WeeklyReportForm relative">
			<div className="grid grid-cols-2 gap-6">
				<div className="col-span-1">
					<Field
						label="Date of Inspection *"
						name="date_of_inspection"
					>
						<Datepicker
							name="date_of_inspection"
							type="date"
							value={data?.date_of_inspection}
							disabled={!canEdit}
							onChange={setData}
						/>
					</Field>
				</div>
				<div className="col-span-1">
					<Field
						label="Select Section *"
						name="section"
					>
						<Select
							value={section}
							name="section"
							onChange={(_, value) => handleAddSection(value as string)}
							options={[
								...(sectionNames.map(s => ({ value: s, label: s }))),
								...((data?.weekly_report_sections ?? []).filter(s => s?.name && !sectionNames.includes(s.name)).map(s => ({ value: s.name, label: s.name })))
							]}
							createable={true}
						/>
					</Field>
				</div>
			</div>

			{
				data?.weekly_report_sections?.find(s => section === s.name) !== undefined && (
					<WeeklyReportSectionForm key={section}
						index={data.weekly_report_sections?.indexOf(data.weekly_report_sections.find(s => section === s.name) as WeeklyReportSection)}
						section={data.weekly_report_sections.find(s => section === s.name)} data={data}
						setData={setData} canEdit={canEdit} errors={errors}/>
				)
			}

			{
				section && !sectionNames.includes(section as string) && (
					<div className="w-full flex justify-end">
						<Button
							type="button"
							onClick={() => handleDeleteSection(section as string)}
						>
							Remove Section
						</Button>
					</div>
				)
			}

			{children}
		</div>
	);
};

export default WeeklyReportForm;
