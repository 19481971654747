import React, { FunctionComponent } from 'react';
import route from 'ziggy-js';
import { db } from '@/Services/IndexedDB';
import axios from 'axios';
import Button from './Button';
import { useLayout } from '@/Contexts/LayoutContext';

interface IProps {
    className?: string;
}

const BackupLocalData: FunctionComponent<IProps> = () => {
	const { setLoading, setLoadingMessage } = useLayout();
	const handleBackup = () => {
		setLoading && setLoading(true);
		setLoadingMessage && setLoadingMessage('Backing up local data to server');

		db.projects.toArray().then((projects) => {
			axios.post(route('api.backup-local-data'), { projects }).then(() => {
				alert('Local data backed up to server');
				setLoading && setLoading(false);
			}).catch(() => {
				alert('There was an error backing up local data to server');
				setLoading && setLoading(false);
			});
		}).catch(() => {
			alert('There was an error backing up local data to server');
			setLoading && setLoading(false);
		});
	};


	return (
		<Button styleName="secondary" type="button" onClick={handleBackup}>Backup Local Storage to Server</Button>
	);
};

export default BackupLocalData;
