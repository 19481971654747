interface IModels {
    User: string;
    Client: string;
    Project: string;
}

const Models: IModels = {
	'User': 'COW\\Auth\\Models\\User',
	'Client': 'COW\\Clients\\Models\\Client',
	'Project': 'COW\\Projects\\Models\\Project'
};

export { Models as default, type IModels };