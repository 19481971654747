import { FormEvent, FunctionComponent, useEffect } from 'react';
import { Section } from '@/Components/Sections';
import Button from '@/Components/Button';
import Form from './Form';
import { Client } from '@/Types/Models/Clients/client';
import useForm from '@/Hooks/useForm';
import { useLayout } from '@/Contexts/LayoutContext';
import route from 'ziggy-js';
import API from '@/Services/API';
import { useNavigate } from 'react-router-dom';
import reactRoute from '@/Utilities/routes';


const Create: FunctionComponent = () => {
	const { setHeader } = useLayout();
	const navigate = useNavigate();
	const { data, setData, errors, loadErrors } = useForm<Client>({
		id: undefined,
		name: '',
		project_managers: []
	});

	const submit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		API.post(route('api.clients.store'), data).then(() => {
			navigate(reactRoute('clients.index'));
		}).catch(({ response }) => loadErrors(response?.data?.errors));
	};

	useEffect(() => {
		setHeader && setHeader('Create Client');
	}, []);

	return (
		<div className="ClientCreatePage">
			<Section>
				<form onSubmit={submit}>
					<Form data={data} setData={setData} errors={errors}/>
					<div className="flex clear-both  w-full float-leftjustify-end mt-6">
						<Button className="ml-auto btn-success" type="submit">Create Client</Button>
					</div>
				</form>
			</Section>
		</div>
	);
};

export default Create;
