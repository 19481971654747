import React, { FunctionComponent, useEffect, useState } from 'react';
import { Project } from '@/Types/Models/Projects/project';
import { useNavigate } from 'react-router-dom';
import { db } from '@/Services/IndexedDB';
import API from '@/Services/API';
import route from 'ziggy-js';
import reactRoute from '@/Utilities/routes';
import Tooltip from '@/Components/Tooltip';
import Confirmation from '@/Components/Modals/Confirmation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSync } from '@fortawesome/pro-regular-svg-icons';
import { WeeklyReportSections } from '@/Types/Models/Projects/weeklyreportsections';
import { Observations } from '@/Types/Models/Projects/observation';
import { Snags } from '@/Types/Models/Projects/snag';
import { useAuth } from '@/Contexts/AuthContext';
import Vapor from 'laravel-vapor';
import { base64toFile } from '@/Utilities/helpers';
import { AxiosError } from 'axios';
import { useLayout } from '@/Contexts/LayoutContext';

interface IProps {
    project: Project;
    onChange?: (status: 'synced' | 'downloaded') => void;
}

const DownloadSyncButton: FunctionComponent<IProps> = ({ project, onChange }) => {
	const navigate = useNavigate();

	const { user, token, device, calculatePhotoCount } = useAuth();
	const { setLoading, setLoadingMessage } = useLayout();
	const [disabled, setDisabled] = useState<boolean>(false);
	const [isInOfflineStorage, setIsInOfflineStorage] = useState<boolean>(true);

	useEffect(() => {
		projectIsInOfflineStorage(project).then(setIsInOfflineStorage);
	}, []);

	const projectIsInOfflineStorage = async (project: Project) => {
		if (project.id) {
			const local = await db.projects.where(':id').equals(project.id).first();

			return local !== undefined;
		}

		return false;
	};

	const handleDownload = (project: Project) => () => {
		setDisabled(true);
		setLoading && setLoading(true);
		setLoadingMessage && setLoadingMessage('Downloading project to offline storage please do not close the window/tab');
		API.get(route('api.projects.show', {
			project: project.id,
			with_reports: true,
			lock: true,
			base_64_images: true,
			for_local_copy: true
		}), {
			headers: {
				'PWA-No-Cache': 'true'
			}
		}).then(({ data }) => {
			if (data.data.id) {
				db.projects.add(data.data, project.id).then(() => {
					localStorage.setItem('flash-success', `Added project #${project.id} to offline storage`);
					setIsInOfflineStorage(true);
					setDisabled(false);
					onChange && onChange('downloaded');
				}).catch(error => {
					setDisabled(false);
					localStorage.setItem('flash-error', `There was an error adding the project to offline storage. Please try again. (${error?.message})`);
					throw error;
				});
			} else {
				throw new Error(data?.message);
			}

			setLoading && setLoading(false);
		}).catch(error => {
			setDisabled(false);
			localStorage.setItem('flash-error', `There was an error adding the project to offline storage. Please try again. (${error?.message})`);
			setLoading && setLoading(false);
			throw error;
		});
	};

	const handleSync = (project: Project, retry = false) => () => {
		setDisabled(true);
		setLoading && setLoading(true);
		setLoadingMessage && setLoadingMessage('Syncing project to server please do not close the window/tab');
		project.id && db.projects.get(project.id).then(data => {
			// If data is undefined or empty
			if (data === undefined || Object.keys(data).length === 0) {
				setDisabled(false);
				localStorage.setItem('flash-error', 'There was an error getting the project data from offline storage. Please try again.');
				setLoading && setLoading(false);
				return;
			}

			const processData = async (data: Project) => {
				const reports = await Promise.all((data?.reports ?? []).map(async report => {
					// Strip out base64 any images that have already been synced, to save on request size
					for (const sectionName of ['weekly_report_sections', 'observations', 'snags']) {
						const reportSections = report[sectionName] as WeeklyReportSections | Observations | Snags;
						if (reportSections) {
							for (const section of reportSections) {
								if (section?.images !== undefined) {
									// Split the images into smaller chunks
									const chunkSize = 5; // Process 5 images at a time
									const imageChunks = [];
									for (let i = 0; i < section.images.length; i += chunkSize) {
										imageChunks.push(section.images.slice(i, i + chunkSize));
									}

									// Process each chunk of images asynchronously
									for (const chunk of imageChunks) {
										await Promise.all(chunk.map(async image => {
											if (image?.attachment?.id && image?.attachment?.url.includes('data:image')) {
												image.attachment.url = '';
											}

											if (image?.attachment?.id === undefined && image?.attachment?.url.includes('data:image')) {
												// Syncing a new image to the s3 bucket
												// Convert the base64 image to a file
												const file = base64toFile(image.attachment.url, image.attachment.name);

												try {
													const vaporFile = await Vapor.store(file as File, {
														visibility: 'private',
														baseURL: process.env.REACT_APP_API_URL ?? 'http://cow-api.test/',
														headers: {
															Authorization: token ? `Bearer ${token.plainTextToken}` : '',
															Accept: 'application/json',
															'X-Requested-With': 'XMLHttpRequest',
															'Device-Instance-Mode': (!!navigator.maxTouchPoints && navigator.maxTouchPoints > 2) || localStorage.getItem('mobileMode') === 'true' ? 'Mobile' : 'Desktop',
															'Device-Instance-ID': device?.uuid,
															'Device-Instance-React-App-Version': process.env.REACT_APP_VERSION,
															'Device-Instance-Created-At': device?.created_at,
														}
													});

													image.attachment.id = vaporFile.uuid;
													image.attachment.uuid = vaporFile.uuid;
													image.attachment.url = '';
												} catch (error: AxiosError|any) {
													if (error?.response?.status === 401) {
														db.tokens.clear().then(() => {
															db.user.clear().then(() => {
																window.location.href = reactRoute('login');
															});
														});
													}
												}

											}
											return image;
										}));

										console.log('Processed chunk of images');
									}
								}
							}
						}
					}
					return report;
				}));

				return {
					...data,
					reports
				};
			};

			processData(data as Project).then(data => {
				API.patch(route('api.projects.update', [project.id]), {
					...(data as Project),
					unlock: true
				}).then(() => {
					project.id && db.projects.delete(project.id).then(() => {
						caches.delete('aws-images');
						localStorage.setItem('flash-success', `Synced project #${project.id} to server`);
						calculatePhotoCount && calculatePhotoCount();
						setIsInOfflineStorage(false);
						onChange && onChange('synced');
						setDisabled(false);
						setLoading && setLoading(false);
					}).catch(error => {
						setDisabled(false);
						localStorage.setItem('flash-error', 'There was an error deleting the project from offline storage. Please try again.');
						setLoading && setLoading(false);
						throw error;
					});
				}).catch(error => {
					localStorage.setItem('flash-error', 'There was an error syncing the project to the server. Please check to rectify the errors. If issues persist, please contact support.');
					project.id && navigate(reactRoute('projects.show', [project.id]), {
						state: {
							errors: error?.response?.data?.errors
						}
					});
					setDisabled(false);
					setLoading && setLoading(false);
				});
			}).catch(error => {
				setDisabled(false);
				localStorage.setItem('flash-error', `There was an error processing the data, please contact support. ${error?.message}`);
				setLoading && setLoading(false);
				throw error;
			});
		}).catch(error => {
			setDisabled(false);
			localStorage.setItem('flash-error', 'There was an error getting the project data from offline storage. Please try again.');
			setLoading && setLoading(false);
			throw error;
		});
	};

	if (project.locked_by_id !== undefined && project.locked_by_id > 0 && project.locked_by_id !== user?.id) {
		return (
			<div className="sync-project-button">
				<small>Project is locked by {project.locked_by?.name}</small>
			</div>
		);
	}

	return (
		<div className="sync-project-button">
			{
				isInOfflineStorage ? (
					<Tooltip tooltipContent="Sync project to the server">
						<Confirmation
							message="Are you sure you want to sync this project to the server?"
							onConfirm={handleSync(project)}
							buttonStyle="secondary"
							buttonText={
								<FontAwesomeIcon icon={faSync}
									className="h-4 w-4 shrink-0 text-black"
									aria-hidden="true"
								/>
							}
							title="Confirmation"
							disabled={!isInOfflineStorage || disabled}
						/>
					</Tooltip>
				) : (
					<Tooltip tooltipContent="Download a copy of the project to offline storage">
						<Confirmation
							message="Are you sure you want to download this project to offline storage?"
							onConfirm={handleDownload(project)}
							buttonStyle="secondary"
							buttonText={
								<FontAwesomeIcon icon={faDownload}
									className="h-4 w-4 shrink-0 text-black"
									aria-hidden="true"
								/>
							}
							title="Confirmation"
							disabled={isInOfflineStorage || disabled}
						/>
					</Tooltip>
				)
			}
		</div>
	);
};

export default DownloadSyncButton;