import { FunctionComponent } from 'react';

interface IProps {
    message?: string|string[];
}

const Error: FunctionComponent<IProps> = ({ message = '' }) => {
	return (
		<>
			<p className="mt-2 text-sm text-red-600 input-error">
				{Array.isArray(message) ? (
					<ul>
						{message.map((error: string, key: number) => (
							<li key={key}>{error}</li>
						))}
					</ul>
				) : (
					message
				)}
			</p>
		</>
	);
};

export default Error;
