import { AxiosRequestConfig } from 'axios';
import { db } from '@/Services/IndexedDB';
import reactRoute from '@/Utilities/routes';
import route from 'ziggy-js';

const baseUrl = process.env.REACT_APP_API_URL ?? 'http://cow-api.test/';

export default class API {
	public static request = () => {
		window.apiAxios.interceptors.request.use(async (config) => {
			const token = await db.tokens.orderBy(':id').last();
			const device = await db.device.orderBy(':id').last();

			config.headers.Authorization = token ? `Bearer ${token.plainTextToken}` : '';
			config.headers.Accept = 'application/json';
			config.headers['X-Requested-With'] = 'XMLHttpRequest';

			if (device) {
				config.headers['Device-Instance-Mode'] = (!!navigator.maxTouchPoints && navigator.maxTouchPoints > 2) || localStorage.getItem('mobileMode') === 'true' ? 'Mobile' : 'Desktop';
				config.headers['Device-Instance-ID'] = device?.uuid;
				config.headers['Device-Instance-React-App-Version'] = process.env.REACT_APP_VERSION;
				config.headers['Device-Instance-Created-At'] = device?.created_at;
			}

			return config;
		});

		window.apiAxios.interceptors.response.use(response => {
			if (response?.data?.message && response?.data?.success) {
				localStorage.setItem('flash-success', response?.data?.message);
			}

			if (response?.data?.message && !response?.data?.success) {
				localStorage.setItem('flash-error', response?.data?.message);
			}

			return response;
		}, error => {
			if (error?.response?.status === 401) {
				db.tokens.clear().then(() => {
					db.user.clear().then(() => {
						window.location.href = reactRoute('login');
					});
				});
			}

			if (error?.response?.status === 404) {
				window.location.href = reactRoute('not-found');
			}

			if (error?.response?.status >= 500) {
				localStorage.setItem('flash-error', 'Sorry there has been an error. Please contact your system administrator.');
			}

			return Promise.reject(error);
		});

		window.apiAxios.defaults.withCredentials = true;
		window.apiAxios.defaults.baseURL = baseUrl;

		return window.apiAxios;
	};

	public static formatUrl = (url: string) => url.replace('//', '/');

	public static get = (url: string, config?: AxiosRequestConfig<any>) => this.request().get(url, config);

	public static delete = (url: string, config?: AxiosRequestConfig<any>) => this.request().delete(url, config);

	public static post = (url: string, data?: any, config?: AxiosRequestConfig<any>) => this.request().post(url, data, config);

	public static patch = (url: string, data?: any, config?: AxiosRequestConfig<any>) => this.request().patch(url, data, config);
}


