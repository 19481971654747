import ApplicationLogo from '@/Components/ApplicationLogo';
import Loader from '@/Components/Loader';
import { FunctionComponent } from 'react';

interface IProps {
    withoutLogo?: boolean;
	message?: string;
}

const LoadingPage: FunctionComponent<IProps> = ({ withoutLogo = false, message = '' }) => (
	<div className="LoadingPage flex h-screen w-screen">
		<div className="m-auto">
			{!withoutLogo && <ApplicationLogo className="w-80 mb-5"/>}
			<Loader/>
			{message && message.length && <p className="text-center text-gray-500">{message}</p>}
		</div>
	</div>
);

export default LoadingPage;