import React, { FunctionComponent, useEffect, useState } from 'react';
import Field from './Field';
import AsyncSelect from './AsyncSelect';

interface IAddressOptions {
    addresses?: IAddress[];
}

export interface IAddress {
    line_1?: string;
    line_2?: string;
    line_3?: string;
    city?: string;
    county?: string;
    postcode?: string;
    country_id?: string | number;
}

type IErrors = IAddress;

interface IProps {
    data?: IAddress;
    onChange?: (data: IAddress) => void;
    errors?: IErrors;
    apiRoute: string;
    ukOnly?: boolean;
    readOnly?: boolean;
}

const AddressAutocomplete: FunctionComponent<IProps> = ({
	data,
	onChange,
	errors,
	apiRoute = 'api.address.get-address',
	ukOnly = false,
	readOnly = false
}) => {
	const [queryError, setQueryError] = useState<boolean>();

	const [line1, setLine1] = useState<string|undefined>(data?.line_1);
	const [line2, setLine2] = useState<string|undefined>(data?.line_2);
	const [line3, setLine3] = useState<string|undefined>(data?.line_3);
	const [city, setCity] = useState<string|undefined>(data?.city);
	const [county, setCounty] = useState<string|undefined>(data?.county);
	const [postcode, setPostcodeOriginal] = useState<string|undefined>(data?.postcode);
	const [countryId, setCountryId] = useState<string|number|undefined>(
		data?.country_id ? data.country_id : 225
	);

	const setPostcode = (value: string|undefined) => {
		if (value === undefined) {
			setPostcodeOriginal(undefined);
			return;
		}

		setPostcodeOriginal(value.toUpperCase());
	};

	useEffect(() => {
		setLine1(data?.line_1);
		setLine2(data?.line_2);
		setLine3(data?.line_3);
		setCity(data?.city);
		setCounty(data?.county);
		setPostcode(data?.postcode);
		setCountryId(data?.country_id ? data.country_id : 225);
	}, [data]);

	useEffect(() => {
		if (onChange) {
			onChange({
				...data,
				...{ line_1: line1, line_2: line2, line_3: line3, city, county, postcode, country_id: countryId }
			});
		}
	}, [line1, line2, line3, city, county, postcode, countryId]);

	const handleQueryError = () => {
		setQueryError(true);
	};
	const handleQuerySuccess = () => {
		setQueryError(false);
	};

	const formatData = (data: IAddressOptions | any) => {
		return data.addresses;
	};

	const onAddressSelected = (
		item: string,
		name: string,
		object: IAddress | any
	) => {
		if (object?.line1 !== undefined) {
			setLine1(object.line1);
			setLine2(object.line2);
			setLine3(object.line3);
			setCity(object.city);
			setCounty(object.county);
			setPostcode(object.postcode);
			setCountryId(225);
		}
	};

	return (
		<>
			<div className="float-left w-full block address">
				{!readOnly && (
					<div className="float-left w-full block mb-5">
						<Field name="search" label="">
							<AsyncSelect
								routeName={apiRoute}
								name="address_search"
								allowNullSearch={false}
								queryName="input"
								onChange={onAddressSelected}
								onQueryFail={handleQueryError}
								onQuerySuccess={handleQuerySuccess}
								placeholder="Enter postcode to search for address"
								valueKey="fullAddress"
								labelKey="fullAddress"
								formatData={formatData}
								labelGenerate={(data: any) => data.fullAddress}
								error={queryError ? 'Postcode was not found' : undefined}
							/>
						</Field>
					</div>
				)}

				<div className="grid grid-cols-2 address-placeholder-grid clear-both float-left w-full">

					<div className="col-span-1 address-placeholder">
						<Field
							name="line1"
							label=""
							value={line1}
							onChange={(name, value) => setLine1(value)}
							error={errors?.line_1}
							readOnly={readOnly}
							placeholder="Address Line 1 *"
						/>
					</div>

					<div className="col-span-1 address-placeholder">
						<Field
							name="line2"
							label=""
							value={line2}
							onChange={(name, value) => setLine2(value)}
							error={errors?.line_2}
							readOnly={readOnly}
							placeholder="Address Line 2"
						/>
					</div>

					<div className="col-span-1 address-placeholder">
						<Field
							name="line3"
							label=""
							value={line3}
							onChange={(name, value) => setLine3(value)}
							error={errors?.line_3}
							readOnly={readOnly}
							placeholder="Address Line 3"
						/>
					</div>

					<div className="col-span-1 address-placeholder">
						<Field
							name="city"
							label=""
							value={city}
							onChange={(name, value) => setCity(value)}
							error={errors?.city}
							readOnly={readOnly}
							placeholder="City *"
						/>
					</div>

					<div className="col-span-1 address-placeholder">
						<Field
							name="county"
							label=""
							value={county}
							error={errors?.county}
							onChange={(name, value) => setCounty(value)}
							readOnly={readOnly}
							placeholder="County *"
						/>
					</div>

					<div className="col-span-1 address-placeholder">
						<Field
							name="postcode"
							label=""
							value={postcode}
							onChange={(name, value) => setPostcode(value)}
							error={errors?.postcode}
							readOnly={readOnly}
							placeholder="Postcode *"
						/>
					</div>

					{!ukOnly && (
						<div className="col-span-1 address-placeholder">
							<Field name="country_id" label="">
								<AsyncSelect
									routeName="api.address.get-countries"
									name="country_id"
									onChange={(name, value) => setCountryId(value)}
									value={countryId}
									error={errors?.country_id as string}
									placeholder="Country *"
								/>
							</Field>
						</div>
					)}

				</div>

			</div>
		</>
	);
};

export default AddressAutocomplete;
