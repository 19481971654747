import React, {
	FunctionComponent,
	HTMLInputTypeAttribute,
	InputHTMLAttributes,
	ReactNode,
	useEffect,
	useRef
} from 'react';
import { classNames } from '@/Utilities/helpers';

type InputType = 'yes_no' | 'textarea' | HTMLInputTypeAttribute | undefined;

interface IProps
    extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    isFocused?: boolean;
    error?: string;
    RenderIconLeft?: ReactNode | string | null;
    RenderIconRight?: ReactNode | string | null;
    type?: InputType;
    onBlur?: () => void;
	spellCheck?: boolean;
}

const Input: FunctionComponent<IProps> = ({
	type = 'text',
	name,
	value,
	className = '',
	autoComplete,
	required,
	isFocused,
	error = '',
	placeholder = '',
	onChange,
	onPaste,
	RenderIconLeft,
	RenderIconRight,
	disabled = false,
	readOnly = false,
	maxLength,
	minLength,
	onBlur,
	spellCheck = false
}) => {
	const ref = useRef<any>(null);

	useEffect(() => {
		if (isFocused) {
			const input = ref.current!;
			input.focus();
		}
	}, []);

	const classes =
        error.length > 0 ? 'pr-10 error' : 'px-4 py-2 transition';

	let inputRender;

	switch (type) {
	case 'yes_no':
		// eslint-disable-next-line no-case-declarations
		const options = [
			{ label: 'Yes', value: 1 },
			{ label: 'No', value: 0 }
		];
		inputRender = (
			<div className="radio">
				{options.map(({ label: input_label, value: input_value }, key) => (
					<div
						key={key}
						className="float-left flex justify-start align-center items-center radio-fix mr-6 relative"
					>
						<input
							type="radio"
							value={input_value}
							onChange={onChange}
							checked={
								(typeof value === 'string' ? parseInt(value) : value) ===
                                    input_value
							}
							className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
						/>
						<label className="" key={input_value}>
							<span className="">{input_label}</span>
						</label>
					</div>
				))}
			</div>
		);
		break;
	case 'textarea':
		inputRender = (
			<textarea
				name={name}
				value={value}
				className={classNames(
					classes,
					' block w-full focus:ring focus:ring-opacity-50 form-input ',
					className,
					// RenderIconLeft && ' pl-10 '
				)}
				ref={ref}
				autoComplete={autoComplete}
				required={required}
				placeholder={placeholder}
				onChange={onChange}
				onPaste={onPaste}
				disabled={disabled}
				readOnly={readOnly}
				maxLength={maxLength}
				minLength={minLength}
				onBlur={onBlur}
				spellCheck={spellCheck}
			/>
		);
		break;
	default:
		inputRender = (
			<input
				type={type}
				name={name}
				value={value}
				className={classNames(
					classes,
					' block w-full focus:ring focus:ring-opacity-50 form-input ',
					className,
					// RenderIconLeft && ' pl-10 '
				)}
				ref={ref}
				autoComplete={autoComplete}
				required={required}
				placeholder={placeholder}
				onChange={onChange}
				onPaste={onPaste}
				disabled={disabled}
				readOnly={readOnly}
				maxLength={maxLength}
				minLength={minLength}
				onBlur={onBlur}
				spellCheck={spellCheck}
			/>
		);
		break;
	}

	return (
		<React.Fragment>
			<div className="relative flex justify-start items-center content-center clear-both float-left w-full">
				{RenderIconLeft ?? (
					<div className="absolute render-icon right-4 search  pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path
								d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"/>
						</svg>
					</div>
				)}

				{inputRender}

				{error.length > 0 && (
					<div className="absolute render-icon right-4  pointer-events-none">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
							/>
						</svg>
					</div>
				)}

				{RenderIconRight ?? (
					<div className="absolute render-icon left-4 search  pointer-events-none">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path
								d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"/>
						</svg>
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

export { Input as default, type InputType };
