import { FunctionComponent } from 'react';

interface IProps {
    className?: string;
}

const ApplicationLogo: FunctionComponent<IProps> = (props) => (
	<img className={props.className} src={`${process.env.PUBLIC_URL}/images/logo.jpg`} alt="Logo"/>
);

export default ApplicationLogo;
