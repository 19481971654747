import React, { FunctionComponent, PropsWithChildren } from 'react';
import Field from '@/Components/Inputs/Field';
import { Client } from '@/Types/Models/Clients/client';
import { FormProps } from '@/Hooks/useForm';
import Select from '@/Components/Inputs/Select';

interface IProps extends FormProps<Client>, PropsWithChildren {
    client?: Client
}

const Form: FunctionComponent<IProps> = ({ data, errors, setData, children }) => (
	<div className="ClientForm grid grid-cols-2 gap-6 relative">
		<div className="col-span-1">
			<Field
				label="Name *"
				name="name"
				value={data.name}
				onChange={setData}
				error={errors?.name}
			/>
		</div>

		<div className="col-span-1">
			<Field
				label="Project Managers *"
				name="project_managers"
				error={errors?.name}
			>
				<Select
					value={data.project_managers}
					name="project_managers"
					onChange={setData}
					labelKey="name"
					valueKey="name"
					createable={true}
					multiple={true}
					options={[]}
				/>
			</Field>
		</div>

		{children}
	</div>
);

export default Form;
