import { FormEvent, FunctionComponent } from 'react';
import useForm from '@/Hooks/useForm';
import Button from '@/Components/Button';
import Field from '@/Components/Inputs/Field';
import { useAuth } from '@/Contexts/AuthContext';
import API from '@/Services/API';
import route from 'ziggy-js';
import { User } from '@/Types/Models/Auth/user';
import { useNavigate } from 'react-router-dom';

const Profile: FunctionComponent = () => {
	const navigate = useNavigate();
	const { user, setUser } = useAuth();

	const { data, setData, errors, loadErrors } = useForm({
		name: user?.name ?? '',
		email: user?.email ?? '',
		password: '',
		password_confirmation: '',
	});

	const submit = (e: FormEvent) => {
		e.preventDefault();

		API.post(route('api.profile.update'), data).then((response) => {
			setUser && setUser(response.data.user as User);
			navigate('/');
		}).catch(({ response }) => loadErrors(response?.data?.errors));
	};

	return (
		<div className="ProfilePage">
			<div className="float-left panel-outer w-full block bg-white shadow-sm p-5">
				<header className="float-left w-full mb-4">
					<div className="flex justify-between items-center">
						<div>
							<h3 className="float-left w-full block mb-2">
                                Profile Information
							</h3>

							<p>
                                Update your account&apos;s profile information and email
                                address.
							</p>
						</div>
						<div>
							<img className="h-20 w-20 rounded-full bg-gray-50 gravatar-profile "
								src={user?.gravitar_url}/>
						</div>
					</div>
				</header>

				<form onSubmit={submit} className="w-full float-left block">

					<div className="grid grid-cols-2 gap-6 relative">
						<div className="col-span-1">
							<Field label="Name *"
								name="name"
								value={data.name}
								onChange={setData}
								error={errors.name}
							/>
						</div>
						<div className="col-span-1">
							<Field label="Email *"
								name="email"
								type="email"
								value={data.email}
								onChange={setData}
								error={errors.email}
							/>
						</div>

						<div className="col-span-1">
							<Field label="Password *"
								name="password"
								type="password"
								placeholder="Leave blank if you don't want to reset your password"
								onChange={setData}
								error={errors.password}
								value={data.password}
							/>
						</div>

						{
							data.password.length > 0 ?
								(
									<div className="col-span-1">
										<Field label="Confirm Password *"
											name="password_confirmation"
											type="password"
											onChange={setData}
											error={errors.password_confirmation}
											value={data.password_confirmation}
										/>
									</div>
								)
								:
								null
						}

						<div className="col-span-2 flex justify-end content-center items-center">
							<Button>Save</Button>
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Profile;