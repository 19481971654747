import React, { FunctionComponent } from 'react';
import Button from '../Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PaginatedData } from './TableElement';

interface IProps {
    pagination: PaginatedData<any>;
}

const TablePaginationElement: FunctionComponent<IProps> = ({ pagination }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	const handleClickPrevious = () => {
		const params = (new URL(document.location.href)).searchParams;
		const newParams = (new URL(pagination.prev_page_url)).searchParams;

		params.forEach((value, key) => {
			if (!newParams.has(key)) {
				newParams.append(key, value);
			}
		});

		setSearchParams(newParams);
	};
	const handleClickNext = () => {
		const params = (new URL(document.location.href)).searchParams;
		const newParams = (new URL(pagination.next_page_url)).searchParams;

		params.forEach((value, key) => {
			if (!newParams.has(key)) {
				newParams.append(key, value);
			}
		});

		setSearchParams(newParams);
	};

	return (
		<nav
			className="mt-6 flex items-center justify-between content-center items-center float-left w-full table-pagination"
			aria-label="Pagination"
		>
			<div className="hidden sm:block">
				<p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{pagination.from}</span> to <span
						className="font-medium">{pagination.to}</span> of{' '}
					<span className="font-medium">{pagination.total}</span> results
				</p>
			</div>
			<div className="flex-1 flex justify-between sm:justify-end">
				<Button
					type="button"
					className="ml-3"

					disabled={!pagination.prev_page_url || pagination.current_page === 1}
					onClick={handleClickPrevious}
				>Previous</Button>

				<Button
					type="button"
					className="ml-3"

					disabled={!pagination.next_page_url || pagination.current_page === pagination.total}
					onClick={handleClickNext}
				>Next</Button>
			</div>
		</nav>
	);
};

export default TablePaginationElement;
