import { FunctionComponent, useEffect, useState } from 'react';
import { faCircleCheck, faCircleXmark, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FlashMessage: FunctionComponent = () => {
	const [error, setError] = useState<string | null>(localStorage.getItem('flash-error'));
	const [success, setSuccess] = useState<string | null>(localStorage.getItem('flash-success'));
	const [counter, setCounter] = useState<number | undefined>(undefined);

	useEffect(() => {
		const interval = setInterval(() => {
			if (localStorage.getItem('flash-success')) {
				setError(null);
				setSuccess(localStorage.getItem('flash-success'));
				localStorage.removeItem('flash-success');
				setCounter(0);
			}
			if (localStorage.getItem('flash-error')) {
				setSuccess(null);
				setError(localStorage.getItem('flash-error'));
				localStorage.removeItem('flash-error');
				setCounter(0);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (success || error) {
				setCounter(counter === undefined || counter <= 5 ? (counter ?? 0) + 1 : 0);
			}
			if (counter !== undefined && counter >= 5) {
				setSuccess(null);
				setError(null);
				setCounter(undefined);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [counter]);

	return (
		<div className="fixed toast-banners fixed">
			<>
				{
					success &&
                    <div className="bg-green-50 p-4">
                    	<div className="flex">
                    		<div className="flex-shrink-0">
                    			<FontAwesomeIcon icon={faCircleCheck}
                    				className="h-5 w-5 text-green-400"
                    				aria-hidden="true"
                    			/>
                    		</div>
                    		<div className="ml-3">
                    			<p className="text-sm font-medium text-green-800">{success}</p>
                    		</div>
                    		<div className="ml-auto pl-3">
                    			<div className="-mx-1.5 -my-1.5">
                    				<button
                    					type="button"
                    					onClick={() => setSuccess(null)}
                    					className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                    				>
                    					<span className="sr-only">Dismiss</span>
                    					<FontAwesomeIcon icon={faXmark}
                    						className="h-5 w-5"
                    						aria-hidden="true"
                    					/>
                    				</button>
                    			</div>
                    		</div>
                    	</div>
                    	<div className="h-1.5 rounded bg-green-700"
                    		style={{ width: ((counter ?? 0) / 5) * 100 + '%' }}></div>
                    </div>
				}
				{
					error &&
                    <div className="bg-red-50 p-4">
                    	<div className="flex">
                    		<div className="flex-shrink-0">
                    			<FontAwesomeIcon icon={faCircleXmark}
                    				className="h-5 w-5 text-red-400"
                    				aria-hidden="true"
                    			/>
                    		</div>
                    		<div className="ml-3">
                    			<p className="text-sm font-medium text-red-800">{error}</p>
                    		</div>
                    		<div className="ml-auto pl-3">
                    			<div className="-mx-1.5 -my-1.5">
                    				<button
                    					type="button"
                    					onClick={() => setError(null)}
                    					className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50"
                    				>
                    					<span className="sr-only">Dismiss</span>
                    					<FontAwesomeIcon icon={faXmark}
                    						className="h-5 w-5"
                    						aria-hidden="true"
                    					/>
                    				</button>
                    			</div>
                    		</div>
                    	</div>
                    	<div className="h-1.5 rounded bg-red-700"
                    		style={{ width: ((counter ?? 0) / 5) * 100 + '%' }}></div>
                    </div>
				}
			</>
		</div>
	);
};

export { FlashMessage as default };
