import { FormEvent, FunctionComponent, useEffect } from 'react';
import Button from '@/Components/Button';
import Form from './Form';
import { Project } from '@/Types/Models/Projects/project';
import useForm from '@/Hooks/useForm';
import route from 'ziggy-js';
import API from '@/Services/API';
import { useNavigate } from 'react-router-dom';
import reactRoute from '@/Utilities/routes';
import { useAuth } from '@/Contexts/AuthContext';
import { db } from '@/Services/IndexedDB';


const Edit: FunctionComponent<{ project: Project, setProject: (project:Project) => void; isLocalCopy: boolean }> = ({ project, setProject, isLocalCopy }) => {
	const { data, setData, setDate, errors, loadErrors, loadData } = useForm<Project>(project);
	const navigate = useNavigate();
	const { offline, mobileMode, user } = useAuth();

	const submit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (offline || mobileMode) {
			if (project?.id) {
				db.projects.update(project.id, {
					...project,
					...data
				}).then(() => {
					localStorage.setItem('flash-success', 'Project has been updated in the local database. When you are back online / have better signal, please sync to the server.');
					navigate(reactRoute('projects.show', [project.id]));
				});
			}
		} else {
			if (project?.id) {
				API.patch(route('api.projects.update', [project.id]), data).then(({ data }) => {
					project.id && db.projects.delete(project.id).then(() => {
						navigate(reactRoute('projects.show', [project.id]));
					});
					loadData(data?.data);
					setProject(data?.data);
				}).catch(({ response }) => loadErrors(response?.data?.errors));
			}
		}
	};

	useEffect(() => {
		if (project?.client_id !== data?.client_id) {
			setData('project_manager_id', undefined);
		}
	}, [data?.client_id]);

	return (
		<div className="ProjectEdit">
			<form onSubmit={submit}>
				<Form project={project} data={data} setData={setData} setDate={setDate} errors={errors}/>
				<div className="flex clear-both w-full float-left justify-end mt-6">
					{
						((mobileMode && isLocalCopy) || (!mobileMode)) &&
                        (!project.locked_by_id || project.locked_by_id === user?.id) &&
                        (<Button type="submit" className="ml-3 btn-success">Update Project</Button>)
					}
				</div>
			</form>
		</div>
	);
};

export default Edit;
