import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import Button from '@/Components/Button';
import { Report, Reports } from '@/Types/Models/Projects/report';
import Modal from '@/Components/Modals/Modal';
import Checkbox from '@/Components/Inputs/Checkbox';
import API from '@/Services/API';
import route from 'ziggy-js';
import { Project } from '@/Types/Models/Projects/project';
import useForm from '@/Hooks/useForm';
import WYSIWYG from '@/Components/Inputs/WYSIWYG';
import Label from '@/Components/Inputs/Label';
import Select from '@/Components/Inputs/Select';
import Field from '@/Components/Inputs/Field';
import { useAuth } from '@/Contexts/AuthContext';
import moment from 'moment/moment';
import Error from '@/Components/Inputs/Error';

interface IProps {
    project: Project;
    reports: Reports;
}

interface IData {
    contacts: string[];
    template: string;
    reports: number[];
    formats: string[];
}

const EmailReports: FunctionComponent<IProps> = ({ project, reports }) => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { data, setData, errors, loadErrors } = useForm<IData>({
		contacts: project?.contacts?.map((contact) => contact.value) ?? [],
		template: '<p>Hi All</p>' +
			'<p>Please find attached reports for inspections carried out on (insert project name) on (insert inspection date).</p>' +
			// `<ul>` + data?.reports?.map((report) => `<li>${report}</li>`)} + `</ul>` +
			'<p>If you would like the report circulating to additional team members, please advise.</p>',
		reports: [],
		formats: []
	});
	const { user } = useAuth();

	useEffect(() => {
		const dateOfInspection = moment(reports?.[0]?.date_of_inspection).format('DD/MM/YYYY');
		const reportList = data?.reports?.map((report) => {
			const reportModel = reports?.find((r) => r.id === report);
			if (!report) return '';
			return `<li>${project?.owner?.department} ${reportModel?.type} (${reportModel?.number})</li>`;
		}).join('');

		setData(
			'template',
			'<p>Hi All</p><br/>' +
			`<p>Please find attached reports for inspections carried out on ${project?.name} on ${dateOfInspection}.</p>` +
			`<ul>${reportList}</ul>` +
			'<p>If you would like the report circulating to additional team members, please advise.</p>'
		);
	}, [data.reports]);

	const handleSend = () => {
		API.post(route('api.projects.reports.send', project.id), data).then(() => {
			setOpenModal(false);
			setData('contacts', []);
			setData('template', '');
			setData('reports', []);
			setData('formats', []);
		}).catch(({ response }) => loadErrors(response?.data?.errors));
	};

	const handleUpdateReports = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setData('reports', [
				...(data.reports ?? []),
				parseInt(e.target.value)
			]);
		} else {
			setData('reports', (data.reports ?? []).filter((report) => report !== parseInt(e.target.value)));
		}
	};

	const handleUpdateFormats = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setData('formats', [
				...(data.formats ?? []),
				e.target.value
			]);
		} else {
			setData('formats', (data.formats ?? []).filter((format) => format !== e.target.value));
		}
	};

	return (
		<>
			<Button type="button" onClick={() => setOpenModal(!openModal)}>Email Reports</Button>

			{openModal && (
				<Modal openModal={openModal} onClose={() => setOpenModal(!openModal)}>
					<div>
						<h3 className="mb-3">Week {reports[0].week} Reports</h3>

						<div className="grid grid-cols-2  gap-3 mb-6">
							{
								reports.filter(report => report.weekly_report_sections?.length || report.observations?.length || report.snags?.length).map((report, index) => (
									<div key={index} className="grid-span-1 my-auto">
										<Checkbox
											checked={report.id ? data.reports.includes(report.id) : false}
											onChange={handleUpdateReports}
											label={report.type}
											value={report.id}
										/>
									</div>
								))
							}
							{errors?.reports && <Error message={errors?.reports} />}
						</div>

						<h3 className="mb-3">Format *</h3>
						<div className="grid grid-cols-2  gap-3 mb-6">
							{
								['pdf', user?.role?.name === 'staff' ? '' : 'docx'].filter(v => v.length > 0).map((format, index) => (
									<div key={index} className="grid-span-1 my-auto">
										<Checkbox
											checked={data.formats.includes(format)}
											onChange={handleUpdateFormats}
											label={`${format.toUpperCase()} Document`}
											value={format}
										/>
									</div>
								))
							}
							{errors?.formats && <Error message={errors?.formats} />}
						</div>

						<div className="grid grid-cols-1  gap-6">
							<div className="grid-span-1">
								<div className="form-group">
									<Label>Email Template *</Label>
									<WYSIWYG
										name="template"
										placeholder="Add your content..."
										value={data.template}
										onChange={(name, value) => name && setData(name, value)}
										error={errors?.template}
									/>
								</div>
							</div>

							<div className="grid-span-1">
								<Field
									label="Email List"
									name="contacts"
									error={errors?.contacts}
								>
									<Select
										value={data.contacts}
										name="contacts"
										onChange={(name, value) => setData('contacts', value)}
										labelKey="value"
										valueKey="value"
										createable={true}
										multiple={true}
										options={project?.contacts?.map((contact) => ({
											value: contact.value,
											label: contact.value
										})) ?? []}
									/>
								</Field>
							</div>
						</div>

						<div className="w-full flex justify-end mt-6">
							<Button type="button" onClick={handleSend}>Send Emails</Button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default EmailReports;