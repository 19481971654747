import React, { ChangeEvent, FunctionComponent, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import Label from './Label';
import Input, { InputType } from './Input';
import Error from './Error';

interface IProps extends PropsWithChildren {
    type?: InputType;
    label: string | ReactNode;
    name: string;
    value?: number | string;
    isFocused?: boolean;
    error?: string | string[];
    labelClassNames?: string;
    inputClassNames?: string;
    onChange?: (name: string, data: any) => void;
    onBlur?: () => void;
    disabled?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    maxLength?: number;
    minLength?: number;
    maxWords?: number;
    minWords?: number;
	spellCheck?: boolean;
}

const Field: FunctionComponent<IProps> = ({
	type = 'text',
	label,
	name,
	value,
	onChange,
	isFocused = false,
	error,
	labelClassNames = 'font-bold mb-2',
	inputClassNames,
	children,
	disabled = false,
	placeholder = '',
	readOnly = false,
	maxLength,
	minLength,
	maxWords,
	minWords,
	onBlur,
	spellCheck = false
}) => {
	const [wordCount, setWordCount] = useState(0);
	const [dataValue, setDataValue] = useState(value);
	const [errorValue, setErrorValue] = useState(error);

	useEffect(() => {
		if (value !== undefined) {
			setDataValue(value);
		}
	}, [value]);

	useEffect(() => {
		if (error !== undefined) {
			setErrorValue(error);
		}
	}, [error]);

	const handleChange = (e: ChangeEvent | ClipboardEvent) => {
		const target = e.target as HTMLInputElement;

		if (onChange) {
			if (target.type === 'radio' && !target.checked) {
				return;
			}

			const currentWordCount = target.value.split(' ').length;
			setWordCount(currentWordCount);

			if (target.value.length > 0 && target.type === 'radio') {
				onChange(name, target.value);
				setDataValue(target.value);
			} else {
				let errorText = null;

				if (minWords !== undefined && maxWords !== undefined && !(currentWordCount >= minWords && currentWordCount <= maxWords)) {
					errorText = `Please enter between ${minWords} and ${maxWords} words`;
				}
				if (minWords !== undefined && maxWords === undefined && !(currentWordCount >= minWords)) {
					errorText = `Please enter at least ${minWords} words`;
				}
				if (maxWords !== undefined && minWords === undefined && !(currentWordCount <= maxWords)) {
					errorText = `Please enter no more than ${maxWords} words`;
				}

				onChange(name, target.value);
				setDataValue(target.value);
				// setErrorValue(errorText);
			}
		}
	};

	return (
		<div className="float-left form-group d-block w-full relative">
			<Label className={labelClassNames} value={label}/>

			{children ?? (
				<Input
					type={type}
					name={name}
					value={dataValue}
					className={inputClassNames}
					autoComplete={name}
					isFocused={isFocused}
					onChange={handleChange}
					// onPaste={handleChange}
					onBlur={onBlur}
					disabled={disabled}
					error={typeof errorValue === 'string' ? errorValue : undefined}
					placeholder={placeholder}
					readOnly={readOnly}
					maxLength={maxLength}
					minLength={minLength}
					spellCheck={spellCheck}
				/>
			)}

			{errorValue ? <Error message={errorValue}/> : null}
		</div>
	);
};

export default Field;
