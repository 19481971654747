import { FormEvent, FunctionComponent } from 'react';
import Button from '@/Components/Button';
import Form from './Form';
import { User } from '@/Types/Models/Auth/user';
import useForm from '@/Hooks/useForm';
import route from 'ziggy-js';
import API from '@/Services/API';
import { useNavigate } from 'react-router-dom';
import reactRoute from '@/Utilities/routes';


const Edit: FunctionComponent<{ user: User }> = ({ user }) => {
	const { data, setData, errors, loadErrors } = useForm<User>(user);
	const navigate = useNavigate();

	const submit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (user.id) {
			API.patch(route('api.users.update', [user.id]), data).then(() => {
				navigate(reactRoute('users.index'));
			});
		}
	};

	const handleSendResetPasswordLink = () => {
		if (user.id) {
			API.post(route('api.users.send-password-reset-link', [user.id])).then(() => {
				navigate(reactRoute('users.index'));
			}).catch(({ response }) => loadErrors(response?.data?.errors));
		}
	};

	return (
		<div className="UserEdit">
			<form onSubmit={submit}>
				<Form user={user} data={data} setData={setData} errors={errors}/>
				<div className="flex clear-both w-full float-left justify-end mt-6">
					<Button onClick={handleSendResetPasswordLink} styleName="secondary" className="ml-auto"
						type="button">
                        Send Reset Password Link
					</Button>
					<Button type="submit" className="ml-3 btn-success">Update User</Button>
				</div>
			</form>
		</div>
	);
};

export default Edit;
