const routes: Record<string, string> = {
	'not-found': '/not-found',
	'home': '/',
	'login': '/login',
	'forgot-password': '/forgot-password',
	'reset-password': '/reset-password',
	'profile': '/profile',

	// Projects Module
	'projects.index': '/projects',
	'projects.create': '/projects/create',
	'projects.show': '/projects/:id',

	// Reports Module
	'projects.reports.index': '/projects/reports',
	'projects.reports.create': '/projects/:id/reports/create',
	'projects.reports.show': '/projects/:projectId/reports/:id',

	/**
     * SETTINGS
     */
	// Users Module
	'users.index': '/settings/users',
	'users.create': '/settings/users/create',
	'users.show': '/settings/users/:id',
	// Clients Module
	'clients.index': '/settings/clients',
	'clients.create': '/settings/clients/create',
	'clients.show': '/settings/clients/:id'
};

const reactRoute = (name: string, parameters: any[] = [], query?: any): string => {
	let route = routes[name];

	// Replace parameters in the route
	for (let i = 0; i < parameters.length; i++) {
		if (parameters[i] === undefined) {
			route = route.replace(':id', 'undefined');
		} else {
			if (route.includes('projectId')) {
				if (i === 0) {
					route = route.replace(':projectId', parameters[i].toString());
				} else {
					route = route.replace(':id', parameters[i].toString());
				}
			} else {
				route = route.replace(':id', parameters[i].toString());
			}
		}
	}

	if (query) {
		const searchParams = new URLSearchParams;

		Object.keys(query).map(key => {
			searchParams.append(key, query[key]);
		});

		return route + '?' + searchParams.toString();
	}

	return route;
};

const isCurrentReactRoute = (name: string) => {
	return window.location.href === reactRoute(name);
};

export default reactRoute;
export {
	isCurrentReactRoute
};