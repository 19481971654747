import Dexie, { Table } from 'dexie';

export interface JSONResource extends Record<string, any> {
    id?: number;
}

export interface Device extends JSONResource {
	uuid: string;
	created_at: string;
}

export class IndexedDB extends Dexie {
	testing!: Table<JSONResource>;
	routes!: Table<JSONResource>;
	user!: Table<JSONResource>;
	tokens!: Table<JSONResource>;
	projects!: Table<JSONResource>;
	device!: Table<Device>;

	constructor() {
		super('COW');
		this.version(6).stores({
			testing: '++id',
			routes: '++id',
			user: '++id',
			tokens: '++id',
			projects: '++id',
			device: '++id,uuid,created_at'
		});
	}
}

export const db = new IndexedDB();