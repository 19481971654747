import Tooltip from '@/Components/Tooltip';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Audits } from '@/Types/Models/Audits/audit';
import moment from 'moment/moment';
import { snakeCaseToSentenceCase } from '@/Utilities/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserRobot } from '@fortawesome/pro-solid-svg-icons';
import API from '@/Services/API';
import route from 'ziggy-js';
import Loader from '@/Components/Loader';
import { useAuth } from '@/Contexts/AuthContext';

interface IProps {
    model: string;
    id: number;
}

const Activity: FunctionComponent<IProps> = ({ model, id }) => {
	const { offline } = useAuth();
	const [audits, setAudits] = useState<Audits>();

	const getData = useCallback(() => {
		API.get(route('api.audits'), {
			params: {
				model,
				id
			}
		}).then(({ data }) => {
			setAudits(data.data);
		});
	}, []);

	useEffect(() => {
		getData();
	}, []);

	if (audits === undefined) {
		return <Loader/>;
	}

	return (
		<div className="Activity">
			{offline && (
				<p>Activity is not available while offline</p>
			)}
			{!offline && (
				<ul role="list" className="Activity-List mt-6 space-y-6">
					{audits.map((audit, key) => (
						<li className="Activity-ListItem" key={key}>
							<div className="relative flex justify-between top-meta items-center content-center">
								<>
									<p className="pr-6 flex justify-start">
										{
											audit?.user?.gravitar_url ? (
												<img
													src={audit?.user?.gravitar_url}
													alt=""
													className="Activity-UserImage h-6 w-6 rounded-full bg-gray-50"
												/>
											)
												:
												(
													<FontAwesomeIcon icon={faUserRobot}
														className="Activity-BotImage h-6 w-6 rounded-full bg-gray-50"/>
												)
										}
										<div>
											<span className="font-medium">{audit?.user?.name ?? ''}</span>{' '}
											{audit.message}
										</div>
									</p>

									<Tooltip tooltipContent={moment(audit.created_at).format('DD/MM/YYYY HH:mm')}>
										<time
											dateTime={audit?.created_at}
											className="flex-none py-0.5 text-xs leading-5 text-gray-500"
										>
											{moment(audit.created_at).fromNow()}
										</time>
									</Tooltip>
								</>
							</div>
							{
								audit?.changes && (
									<div className="relative w-full h-auto float-left block">

										<ul className="flex justify-between">
											{
												Object.keys(audit.changes).map((key, index) => (
													<li key={index}>
														<span
															className="chunk">{isNaN(parseInt(key)) ? snakeCaseToSentenceCase(key) + ':' : ''} </span><br/>{' '}
														{
															typeof audit.changes[key] === 'string' && (
																<div
																	dangerouslySetInnerHTML={{ __html: audit.changes[key] as string }}></div>
															)
														}

														{
															Array.isArray(audit.changes[key]) && (
																<ul>
																	{
																		(audit.changes[key] as string[]).map((change, index) => (
																			<li key={index}>
																				<div
																					dangerouslySetInnerHTML={{ __html: change }}></div>
																			</li>
																		))
																	}
																</ul>
															)
														}
													</li>
												))
											}
										</ul>
									</div>
								)
							}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Activity;
