import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Section } from '@/Components/Sections';
import { Table } from '@/Components/Tables';
import DeleteConfirmation from '@/Components/Modals/DeleteConfirmation';
import { Project } from '@/Types/Models/Projects/project';
import route from 'ziggy-js';
import { PaginatedData } from '@/Components/Tables/TableElement';
import PencilIcon from '@/Components/PencilIcon';
import BinIcon from '@/Components/BinIcon';
import { Link, useSearchParams } from 'react-router-dom';
import { useLayout } from '@/Contexts/LayoutContext';
import API from '@/Services/API';
import { debounce } from 'lodash';
import reactRoute from '@/Utilities/routes';
import Loader from '@/Components/Loader';
import moment from 'moment/moment';
import { useAuth } from '@/Contexts/AuthContext';
import { db } from '@/Services/IndexedDB';
import AsyncSelect from '@/Components/Inputs/AsyncSelect';
import { Status } from '@/Types/Models/Statuses/status';
import Field from '@/Components/Inputs/Field';
import Search from '@/Components/Inputs/Search';
import CompleteButton from '@/Components/Projects/CompleteButton';
import DownloadSyncButton from '@/Components/Projects/DownloadSyncButton';
import usePrevious from '@/Hooks/usePrevious';

const Filters: FunctionComponent = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [loaded, setLoaded] = useState<boolean>(false);
	const { user } = useAuth();

	const setParam = (name: string, value: string | number) => {
		searchParams.delete(name);
		searchParams.append(name, value.toString());
		setSearchParams(searchParams);
	};

	useEffect(() => {
		const timeout = setTimeout(() => {
			setLoaded(true);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, []);

	if (!loaded) {
		return <></>;
	}

	return (
		<div className="flex justify-between gap-6 mobile-filters">
			<Field label="Search By Project Name" name="name">
				<Search
					type="text"
					defaultValue={searchParams.get('name') ?? ''}
					name="name"
					onChange={e => setParam('name', e.target.value)}
				/>
			</Field>
			<Field label="Select Client" name="client_id">
				<AsyncSelect
					routeName="api.clients.index"
					selectRouteName="api.clients.show"
					selectParams={{ client: searchParams.get('client_id') }}
					allowNullSearch={true}
					labelKey="name"
					value={searchParams.get('client_id')}
					valueKey="id"
					onChange={(_, value) => setParam('client_id', value)}
					placeholder="Select Client..."
				/>
			</Field>
			<Field label="Select Clerk of Works" name="owner_id">
				<AsyncSelect
					routeName="api.users.index"
					selectRouteName="api.users.show"
					selectParams={{ user: searchParams.get('owner_id') ?? (user?.role?.name === 'staff' ? user?.id : undefined) }}
					allowNullSearch={true}
					labelKey="name"
					value={searchParams.get('owner_id') ?? (user?.role?.name === 'staff' ? user?.id : undefined)}
					valueKey="id"
					onChange={(_, value) => setParam('owner_id', value)}
					placeholder="Select Clerk of Works..."
				/>
			</Field>

			<Field label="Project Status" name="status_id">
				<AsyncSelect
					routeName="api.statuses.index"
					routeParams={{ type: 'project' }}
					selectRouteName="api.statuses.show"
					selectParams={{ status: searchParams.get('status_id') }}
					allowNullSearch={true}
					labelKey="name"
					value={searchParams.get('status_id')}
					valueKey="id"
					onChange={(_, value) => setParam('status_id', value)}
					placeholder="Select Status..."
				/>
			</Field>
		</div>
	);
};

const Index: FunctionComponent<{ homePage?: boolean }> = ({ homePage = false }) => {
	const [projects, setProjects] = useState<PaginatedData<Project> | undefined>(undefined);
	const { setHeader, setAction } = useLayout();
	const { offline, mobileMode, user } = useAuth();
	const [searchParams, setSearchParams] = useSearchParams(
		(user?.id && user?.role?.name === 'staff' ? {
			owner_id: user?.id.toString()
		} : {})
	);
	const previousSearchParams = usePrevious<URLSearchParams | undefined>(searchParams);

	const getData = useCallback(
		debounce((searchParams: URLSearchParams, offline) => {
			if (offline) {
				db.projects.orderBy(':id').toArray().then(projects => setProjects({
					first_page_url: '',
					last_page_url: '',
					links: [],
					next_page_url: '',
					path: '',
					prev_page_url: '',
					to: 0,
					total: 0,
					data: projects.map(p => ({
						...p,
						isLocalCopy: true
					})) ?? [],
					current_page: 1,
					from: 1,
					last_page: 1,
					per_page: projects.length.toString()
				}));
			} else {
				API.get(route('api.projects.index'), {
					params: {
						page: searchParams.get('page'),
						per_page: searchParams.get('per_page') ?? 20,
						search: searchParams.get('search'),
						status_id: searchParams.get('status_id'),
						client_id: searchParams.get('client_id'),
						owner_id: searchParams.get('owner_id'),
						name: searchParams.get('name'),
					},
					headers: {
						'PWA-No-Cache': 'true'
					}
				}).then(async ({ data }) => {
					setProjects({
						...data?.data,
						data: await Promise.all((data?.data?.data || []).map(async (project: Project) => {
							if (project.id) {
								const local = await db.projects.get(project.id);
								return {
									...project,
									...(local !== undefined ? local : {}),
									isLocalCopy: local !== undefined,
								};
							} else {
								return project;
							}
						}))
					});
				});
			}
		}, 500),
		[]
	);

	const setParam = (name: string, value: string | number) => {
		searchParams.delete(name);
		searchParams.append(name, value.toString());
		setSearchParams(searchParams);
	};

	useEffect(() => {
		setHeader && setHeader(homePage ? 'Latest Active Projects' : 'Projects');
		setAction && setAction((
			<Link to={reactRoute('projects.create')}>
				<button className="false btn btn-success">Create Project</button>
			</Link>
		));

		if (homePage) {
			API.get(route('api.statuses.index', {
				type: 'project'
			})).then(({ data }) => {
				data.data.forEach((status: Status) => {
					if (status.name === 'active' && status.id) {
						setParam('status_id', status.id.toString());
						getData(searchParams, offline);
					}
				});
			});
		} else {
			getData(searchParams, offline);
		}
	}, []);

	useEffect(() => {
		getData(searchParams, offline);
	}, [searchParams]);

	useEffect(() => {
		getData(searchParams, offline);

		if (offline) {
			setAction && setAction(offline);
		} else {
			setAction && setAction((
				<Link to={reactRoute('projects.create')}>
					<button className="false btn btn-success">Create Project</button>
				</Link>
			));
		}
	}, [offline]);

	if (projects === undefined || offline === undefined) {
		return <Loader/>;
	}

	const tableConfig = {
		columns: [
			{
				title: 'Name',
				render: (project: Project) =>
					project.id &&
                    <Link
                    	to={reactRoute('projects.show', [project.id])}
                    	className="text-sm btn-link">
                    	{project.name}
                    </Link>

			},
			{
				title: 'Client Name',
				render: (project: Project) => project?.client?.name ?? 'N/A'
			},
			{
				title: 'Main Contractor',
				render: (project: Project) => project?.main_contractor ?? 'N/A'
			},
			{
				title: 'Clerk of Works',
				render: (project: Project) => project?.owner?.name ?? 'N/A'
			},
			{
				title: 'Commencement Date',
				render: (project: Project) => project?.commencement_date ? moment(project?.commencement_date).format('DD/MM/YYYY') : 'N/A'
			},
			{
				title: 'Status',
				render: (project: Project) => project?.status?.label ?? 'N/A'
			},
			{
				render: (project: Project) =>
					project.id && !offline && mobileMode && <DownloadSyncButton key={project.id} project={project}/>
			},
			{
				render: (project: Project) =>
					project.id && !offline && (!project.locked_by_id || project.locked_by_id === user?.id) && project.isLocalCopy == undefined &&
                    <CompleteButton project={project}/>
			},
			{
				render: (project: Project) =>
					project.id && !offline &&
                    <Link
                    	to={reactRoute('projects.show', [project.id])}
                    	className="btn btn-secondary icon-only">
                    	<PencilIcon/>
                    </Link>
			},
			{
				render: (project: Project) => project.id && !offline && (!project.locked_by_id || project.locked_by_id === user?.id) &&
                    <DeleteConfirmation
                    	isOpen={false}
                    	modalMessageText="Are you sure you want to delete?"
                    	modalHeaderText="Delete Project"
                    	buttonText={<BinIcon/>}
                    	callback={() => {
                    		API.delete(route('api.projects.destroy', [project.id])).then(() => {
                    			window.location.reload();
                    		});
                    	}}
                    />

			}
		]
	};

	return (
		<div className="ProjectIndexPage">
			<Section header={{
				title: '',
				onPerPageChange: (value) => setParam('per_page', value),
				perPageValue: typeof searchParams.get('per_page') === 'string' && (searchParams.get('per_page') as string).length > 0 ? parseInt(searchParams.get('per_page') as string) : 20,
				searchPlaceholder: 'Search Projects',
				filters: offline ? undefined : <Filters/>
			}}>
				<Table<Project> key={JSON.stringify(searchParams.entries())} data={projects?.data ?? []} meta={projects} config={tableConfig}/>
			</Section>
		</div>
	);
};

export default Index;
