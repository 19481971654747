import React, { FunctionComponent, InputHTMLAttributes, ReactNode } from 'react';
import Label from './Label';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | ReactNode;
}

const Checkbox: FunctionComponent<IProps> = ({
	label,
	name,
	value,
	onChange,
	checked,
	disabled
}) => (
	<div className="input-checkbox">
		<input
			type="checkbox"
			id={name}
			name={name}
			value={value}
			disabled={disabled}
			defaultChecked={checked}
			className={'rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'}
			onChange={onChange}
		/>
		{label ? <Label htmlFor={name} value={label}/> : null}
	</div>
);

export default Checkbox;
