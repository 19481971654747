import React, { FunctionComponent, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import FlashMessage from '@/Components/FlashMessage';
import posthog from 'posthog-js';
import LayoutProvider from '@/Contexts/LayoutContext';
import LoadingPage from '@/Pages/LoadingPage';

const Guest: FunctionComponent = () => {
	const location = useLocation();
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingMessage, setLoadingMessage] = useState<string>('');
	
	useEffect(() => {
		posthog.capture('$pageview');
	}, [location]);

	return (
		<LayoutProvider {...{
			loading,
			setLoading,
			loadingMessage,
			setLoadingMessage
		}}>
			{
				loading && <LoadingPage message={loadingMessage}/>
			}
			{
				!loading && (
					<div className="flex gap-2 min-h-screen w-full gradient-1  items-center login-screen">
						<div className="w-1/2 text-white h-full flex justify-center align-center">
							<div className="max-w-[360px]">
								<img src="images/logo-main.png" className="w-auto h-[50px] mb-[30px]"/>
								<Outlet/>
							</div>

						</div>
						<div className="w-1/2 h-screen ">
							<img className="w-full h-full object-cover"
								src="images/steve.jpg"
								alt="Builder"/>
						</div>

						<FlashMessage/>
					</div>
				)
			}
		</LayoutProvider>
	);
};

export default Guest;
