import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import Sidebar from '@/Components/Sidebar';
import { Link, Outlet, useLocation } from 'react-router-dom';
import PWAInstallerPrompt from '@/Components/PWAInstallerPrompt';
import OfflineBar from '@/Components/OfflineBar';
import ApplicationLogo from '@/Components/ApplicationLogo';
import LayoutProvider from '@/Contexts/LayoutContext';
import FlashMessage from '@/Components/FlashMessage';
import usePrevious from '@/Hooks/usePrevious';
import reactRoute from '@/Utilities/routes';
import { useServiceWorker } from '@/Hooks/useServiceWorker';
import posthog from 'posthog-js';
import LoadingPage from '@/Pages/LoadingPage';

const Authenticated: FunctionComponent = () => {
	const [header, setHeader] = useState<string | ReactNode | null | undefined>();
	const [action, setAction] = useState<ReactNode | null | undefined>();
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingMessage, setLoadingMessage] = useState<string>('');
	const [showSideBar, setShowSideBar] = useState(false);

	const { pathname } = useLocation();
	const previousPathName = usePrevious(pathname);
	const { waitingWorker, showReload, reloadPage } = useServiceWorker();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	
	useEffect(() => {
		posthog.capture('$pageview');
	}, [location]);

	return (
		<LayoutProvider {...{
			header,
			setHeader,
			action,
			setAction,
			loading,
			setLoading,
			loadingMessage,
			setLoadingMessage
		}}>
			{
				loading && <LoadingPage message={loadingMessage}/>
			}
			{
				!loading && (
					<div className="flex flex-col min-h-screen relative">
						<OfflineBar/>
						{
							showReload && waitingWorker && (
								<div className="w-full bg-red-100 flex justify-center">
									A new version of this page is available
									<button onClick={() => reloadPage()}>Refresh Now</button>
								</div>
							)
						}
						<header
							className="header float-left flex justify-between items-center content-center flex-wrap w-full header-logged-in">
							<Link to={reactRoute('home')}>
								<ApplicationLogo className="w-60"/>
							</Link>
							<Sidebar showSideBar={showSideBar} setShowSideBar={setShowSideBar}/>
							<button type="button" className=" text-gray-700"
								onClick={() => setShowSideBar(true)}>
								<div className="mobile-menu">
									<span></span>
									<span></span>
									<span></span>
								</div>
							</button>
						</header>

						<main className="float-left w-full block">
							<div className="px-5 pb-[25px] pt-[40px] TitleActionBar w-full flex">
								<div className="pr-6 my-auto">
									{
										typeof header === 'string' ?
											<h2 className="leading-tight my-auto">{header}</h2> :
											<>{header}</>
									}
								</div>
								{action && <div className="ml-auto my-auto">{action}</div>}
							</div>
							<div className="px-5 float-left w-full block">
								<FlashMessage/>
								<Outlet/>
							</div>
						</main>

						<footer className="float-left w-full mt-auto px-5 py-5 flex justify-between">
							<p className="my-auto">
								Clerks of Works Inspector Services Ltd. 25 Portland Close, Huddersfield, HD3 4BS. Registered
								in
								England & Wales. No. 08873037
								(<small className="text-sm">({process.env.REACT_APP_VERSION})</small>)
							</p>
							<div className="my-auto pwa-prompt ">
								<PWAInstallerPrompt/>
							</div>
						</footer>
					</div>
				)
			}
		</LayoutProvider>
	);
};

export default Authenticated;