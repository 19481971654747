import { FormEvent, FunctionComponent, useState } from 'react';
import API from '@/Services/API';
import Button from '@/Components/Button';
import useForm from '@/Hooks/useForm';
import Field from '@/Components/Inputs/Field';
import route from 'ziggy-js';

const ForgotPassword: FunctionComponent = () => {
	const [status, setStatus] = useState<string | undefined>();

	const { data, setData, errors, loadErrors } = useForm({
		email: ''
	});

	const submit = (e: FormEvent) => {
		e.preventDefault();

		API.get('sanctum/csrf-cookie', {
			headers: {
				'PWA-No-Cache': 'true'
			}
		}).then(() => {
			API.post(route('api.password.email'), data).then(() => {
				setStatus('If we can find your email within the system a email will be sent with the next steps.');
			}).catch(({ response }) => loadErrors(response?.data?.errors));
		});
	};

	return (
		<div className="ForgotPasswordPage">
			<h1 className="float-left w-full mb-6 text-white">Forgot Password</h1>

			{
				status ?
					(
						<p>{status}</p>
					) : (
						<form className="w-full block grid grid-col-1 gap-6" onSubmit={submit}>
							<div className="col-span-1 text-white">
								<Field label="Email *"
									name="email"
									onChange={setData}
									error={errors.email}
									value={data.email}
								/>
							</div>

							<div className="col-span-1">
								<Button>
                                    Submit
								</Button>
							</div>
						</form>
					)
			}
		</div>
	);
};

export default ForgotPassword;