import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from '@/Components/Button';
import useAddToHomescreenPrompt from '@/Hooks/useAddToHomescreenPrompt';

const PWAInstallerPrompt: FunctionComponent = () => {

	const [prompt, promptToInstall] = useAddToHomescreenPrompt();
	const [isVisible, setVisibleState] = useState(false);

	useEffect(() => {
		if (prompt) {
			setVisibleState(true);
		}
	}, [prompt]);

	if (!isVisible) {
		return <></>;
	}

	return (
		<Button onClick={promptToInstall} className="pwa-prompt">Download The App</Button>
	);
};

export default PWAInstallerPrompt;