import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { classNames } from '@/Utilities/helpers';
import { Link, useNavigate } from 'react-router-dom';

interface ITab {
    name: string;
    default?: boolean;
    label: string;
    href?: string;
    onClick?: () => void;
    Render: ReactNode;
}

interface IProps {
    tabs: ITab[];
    activeTabName?: string;
}

const Tabs: FunctionComponent<IProps> = ({ tabs, activeTabName = null }) => {
	const select = useRef<HTMLSelectElement | null>(null);
	const [activeTab, setActiveTab] = useState<ITab | null | undefined>(null);

	const navigate = useNavigate();

	useEffect(() => {
		const temp = tabs.find((tab) => tab.name === activeTabName || tab.default === true);
		setActiveTab(temp);
	}, [tabs]);

	useEffect(() => {
		if (activeTab && activeTab.onClick) {
			activeTab.onClick();
		}
	}, [activeTab]);

	const handleOnSelectChange = () => select.current! && navigate(select.current.value);
	const tabClassNames = (tab: ITab) =>
		classNames(
			activeTab?.name === tab.name
				? ''
				: '',
			'whitespace-nowrap '
		);

	return (
		<div>
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">
                    Select a tab
				</label>
				<select
					ref={select}
					className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
					onChange={handleOnSelectChange}
					value={activeTab?.href}>
					{tabs.map((tab) => (
						<option key={tab.name} value={tab.href}>
							{tab.label}
						</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block">
				<nav className="flex nav-tabs" aria-label="Tabs">
					{tabs.map((tab) =>
						tab.href !== undefined ? (
							<Link
								key={tab.name}
								className={tabClassNames(tab)}
								to={tab.href}
								aria-current={activeTab?.name === tab.name ? 'page' : undefined}>
								{' '}
								{tab.label}{' '}
							</Link>
						) : (
							<a
								href="javascript:"
								key={tab.name}
								className={tabClassNames(tab)}
								onClick={() => setActiveTab(tab)}
								aria-current={activeTab?.name === tab.name ? 'page' : undefined}>
								{tab.label}
							</a>
						)
					)}
				</nav>
			</div>

			{activeTab && <div className=" mt-6 float-left w-full">{activeTab.Render}</div>}
		</div>
	);
};

export { Tabs as default, type ITab };
