import { FormEvent, FunctionComponent } from 'react';
import API from '@/Services/API';
import Button from '@/Components/Button';
import useForm from '@/Hooks/useForm';
import Field from '@/Components/Inputs/Field';
import route from 'ziggy-js';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPassword: FunctionComponent = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const { data, setData, errors, loadErrors } = useForm({
		email: searchParams.get('email') ?? '',
		password: '',
		password_confirmation: '',
		token: searchParams.get('token')
	});

	const submit = (e: FormEvent) => {
		e.preventDefault();

		API.get('sanctum/csrf-cookie', {
			headers: {
				'PWA-No-Cache': 'true'
			}
		}).then(() => {
			API.post(route('api.password.update'), data).then(() => {
				navigate('/login');
			}).catch(({ response }) => loadErrors(response?.data?.errors));
		});
	};

	return (
		<div className="ResetPasswordPage">
			<h1 className="float-left w-full mb-6 text-white">Reset Password</h1>

			<form className="w-full block grid grid-col-1 gap-6" onSubmit={submit}>
				<div className="col-span-1">
					<Field label="Email *"
						name="email"
						onChange={setData}
						error={errors.email}
						value={data.email}
						disabled={true}
					/>
				</div>

				<div className="col-span-1">
					<Field label="Password *"
						name="password"
						type="password"
						onChange={setData}
						error={errors.password}
						value={data.password}
					/>
				</div>

				<div className="col-span-1">
					<Field label="Confirm Password *"
						name="password_confirmation"
						type="password"
						onChange={setData}
						error={errors.password_confirmation}
						value={data.password_confirmation}
					/>
				</div>

				<div className="col-span-1">
					<Button>
                        Reset Password
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ResetPassword;