import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Section } from '@/Components/Sections';
import Tabs, { ITab } from '@/Components/Tabs';
import { User } from '@/Types/Models/Auth/user';
import route from 'ziggy-js';
import Edit from './Edit';
import { useLayout } from '@/Contexts/LayoutContext';
import { useParams, useSearchParams } from 'react-router-dom';
import API from '@/Services/API';
import reactRoute from '@/Utilities/routes';
import Activity from '@/Components/Activity';
import Models from '@/Utilities/models';
import Loader from '@/Components/Loader';
import Comments from '@/Components/Comments';

interface IProps {
    tab?: string;
}

const Show: FunctionComponent<IProps> = () => {
	const { setHeader } = useLayout();
	const { id } = useParams();
	const [user, setUser] = useState<User | null>(null);
	const [searchParams] = useSearchParams();

	const getData = useCallback((id: string | number) => {
		API.get(route('api.users.show', id)).then(({ data }) => {
			setUser(data.data);
		});
	}, []);

	useEffect(() => {
		if (id) {
			getData(id);
		}
	}, [id]);

	useEffect(() => {
		user && setHeader && setHeader(`Edit User: ${user?.name}`);
	}, [user]);

	if (user === null) {
		return <Loader/>;
	}

	const tabs: ITab[] = [
		{
			name: 'user',
			label: 'User',
			href: user.id ? reactRoute('users.show', [user.id], { tab: 'user' }) : undefined,
			Render: user ? <Edit user={user}/> : null
		},
		{
			name: 'comments',
			label: 'Comments',
			href: user.id ? reactRoute('users.show', [user.id], { tab: 'comments' }) : undefined,
			Render: user.id ? <Comments model={Models.User} id={user.id}/> : undefined
		},
		{
			name: 'activity',
			label: 'Activity',
			href: user.id ? reactRoute('users.show', [user.id], { tab: 'activity' }) : undefined,
			Render: user.id ? <Activity model={Models.User} id={user.id}/> : undefined
		}
	];

	return (
		<div className="UserShowPage">
			<Section>
				<Tabs tabs={tabs} activeTabName={searchParams.get('tab') ?? 'user'}/>
			</Section>
		</div>
	);
};

export default Show;