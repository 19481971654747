import React, { FunctionComponent, useEffect } from 'react';
import './App.css';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import AuthProvider from '@/Contexts/AuthContext';
import Router from '@/Components/Router';
import posthog from 'posthog-js';

posthog.init(
	'phc_U18Cbk4l4xQaqKKeaPvt5cSL9BDS5gTKTkFKXKqr6H2', { api_host: 'https://eu.posthog.com' }
);

const App: FunctionComponent = () => {
	return (
		<AuthProvider>
			<Router/>
		</AuthProvider>
	);
};
export default App;
