import { FormEvent, FunctionComponent } from 'react';
import Button from '@/Components/Button';
import Form from './Form';
import { Client } from '@/Types/Models/Clients/client';
import useForm from '@/Hooks/useForm';
import route from 'ziggy-js';
import API from '@/Services/API';
import { useNavigate } from 'react-router-dom';
import reactRoute from '@/Utilities/routes';


const Edit: FunctionComponent<{ client: Client }> = ({ client }) => {
	const { data, setData, errors, loadErrors } = useForm<Client>(client);
	const navigate = useNavigate();

	const submit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (client.id) {
			API.patch(route('api.clients.update', [client.id]), data).then(() => {
				navigate(reactRoute('clients.index'));
			}).catch(({ response }) => loadErrors(response?.data?.errors));
		}
	};

	return (
		<div className="ClientEdit">
			<form onSubmit={submit}>
				<Form data={data} setData={setData} errors={errors}/>
				<div className="flex clear-both w-full float-left justify-end mt-6">
					<Button type="submit" className="ml-3 btn-success">Update Client</Button>
				</div>
			</form>
		</div>
	);
};

export default Edit;
