import {
	Context,
	createContext,
	Dispatch,
	FunctionComponent,
	PropsWithChildren,
	ReactNode,
	SetStateAction,
	useContext
} from 'react';

interface ILayoutContext extends PropsWithChildren {
    header?: string | ReactNode | null;
    action?: ReactNode | null;
	loading?: boolean;
	loadingMessage?: string;
    setHeader?: Dispatch<SetStateAction<string | ReactNode | null | undefined>>;
    setAction?: Dispatch<SetStateAction<ReactNode | null | undefined>>;
	setLoading?: Dispatch<SetStateAction<boolean>>;
	setLoadingMessage?: Dispatch<SetStateAction<string>>;
}

const LayoutContext: Context<ILayoutContext> = createContext({});

const LayoutProvider: FunctionComponent<ILayoutContext> = ({
	header,
	setHeader,
	action,
	setAction,
	loading,
	setLoading,
	loadingMessage,
	setLoadingMessage,
	children
}) => {
	return <LayoutContext.Provider value={{
		header,
		setHeader,
		action,
		setAction,
		loading,
		setLoading,
		loadingMessage,
		setLoadingMessage
	}}>
		{children}
	</LayoutContext.Provider>;
};
const useLayout = () => {
	return useContext(LayoutContext);
};

export {
	LayoutProvider as default,
	LayoutContext,
	useLayout
};