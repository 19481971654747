import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Header } from '../Sections/index';
import { IHeader } from './SectionHeaderElement';
import { classNames } from '@/Utilities/helpers';

interface IProps extends PropsWithChildren {
    header?: IHeader | undefined;
    wrap?: boolean;
    fluid?: boolean;
    className?: string;
}


const SectionElement: FunctionComponent<IProps> = ({
	header,
	children,
	wrap = true,
	fluid = false,
	className = ''
}) => {
	return (
		<div className={classNames(className, 'section-element')}>
			<div
				className="float-left panel-outer w-full block bg-white shadow-sm p-5">
				<div className={fluid ? '' : 'container-outer'}>
					<div className=" float-left w-full h-auto">
						<div className=" float-left w-full h-auto panel-inner">
							{header && <Header {...header} />}

							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { SectionElement as default };
