import React, { FunctionComponent, useEffect } from 'react';
import {
	BrowserRouter,
	createBrowserRouter,
	createRoutesFromElements,
	Navigate,
	Outlet,
	Route,
	RouterProvider,
	Routes,
	useLocation
} from 'react-router-dom';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout';
import Home from '@/Pages/Home';
import GuestLayout from '@/Layouts/GuestLayout';
import Login from '@/Pages/Auth/Login';
import { useAuth } from '@/Contexts/AuthContext';
import LoadingPage from '@/Pages/LoadingPage';
import NotFound from '@/Pages/NotFound';
import ForgotPassword from '@/Pages/Auth/ForgotPassword';
import ResetPassword from '@/Pages/Auth/ResetPassword';
import Profile from '@/Pages/Profile';
import ClientsIndex from '@/Pages/Settings/Clients/Index';
import ClientsCreate from '@/Pages/Settings/Clients/Create';
import ClientsShow from '@/Pages/Settings/Clients/Show';
import UsersIndex from '@/Pages/Settings/Users/Index';
import UsersCreate from '@/Pages/Settings/Users/Create';
import UsersShow from '@/Pages/Settings/Users/Show';
import ProjectsIndex from '@/Pages/Projects/Index';
import ProjectsCreate from '@/Pages/Projects/Create';
import ProjectsShow from '@/Pages/Projects/Show';
import ReportsCreate from '@/Pages/Projects/Reports/Create';
import ReportsShow from '@/Pages/Projects/Reports/Show';

import reactRoute from '@/Utilities/routes';
import posthog from 'posthog-js';

const PrivateOutlet = () => {
	const { isAuthenticated } = useAuth();
	return isAuthenticated ? <Outlet/> : <Navigate to="/login" replace={true}/>;
};

const PublicOutlet = () => {
	const { isAuthenticated } = useAuth();
	return !isAuthenticated ? <Outlet/> : <Navigate to="/" replace={true}/>;
};

const Router: FunctionComponent = () => {
	const { isAuthenticated, can } = useAuth();

	if (isAuthenticated === null) {
		return <LoadingPage/>;
	}

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path='*' element={<NotFound/>}/>
				<Route path='/not-found' element={<NotFound/>}/>

				<Route path="/" element={<PrivateOutlet/>}>
					<Route element={<AuthenticatedLayout/>}>
						<Route index={true} element={<Home/>}/>
						<Route path={reactRoute('profile')} element={<Profile/>}/>
						<Route path="settings">
							{/* Users Module */}
							<Route path={reactRoute('users.index')} element={<UsersIndex/>}/>
							{
								can && can('users.create') && (
									<Route path={reactRoute('users.create')} element={<UsersCreate/>}/>)
							}
							{
								can && can('users.update') && (
									<Route path={reactRoute('users.show')} element={<UsersShow/>}/>)
							}

							{/* Clients Module */}
							<Route path={reactRoute('clients.index')} element={<ClientsIndex/>}/>
							<Route path={reactRoute('clients.create')} element={<ClientsCreate/>}/>
							<Route path={reactRoute('clients.show')} element={<ClientsShow/>}/>
						</Route>

						{/* Projects Module */}
						<Route path={reactRoute('projects.index')} element={<ProjectsIndex/>}/>
						<Route path={reactRoute('projects.create')} element={<ProjectsCreate/>}/>
						<Route path={reactRoute('projects.show')} element={<ProjectsShow/>}/>

						{/* Reports Module */}
						<Route path={reactRoute('projects.reports.create')} element={<ReportsCreate/>}/>
						<Route path={reactRoute('projects.reports.show')} element={<ReportsShow/>}/>
					</Route>
				</Route>

				<Route element={<PublicOutlet/>}>
					<Route element={<GuestLayout/>}>
						<Route path={reactRoute('login')} element={<Login/>}/>
						<Route path={reactRoute('forgot-password')} element={<ForgotPassword/>}/>
						<Route path={reactRoute('reset-password')} element={<ResetPassword/>}/>
					</Route>
				</Route>
			</>
		)
	);

	return (
		<RouterProvider router={router} />
	);
};

export default Router;