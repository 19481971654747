import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment/moment';

export interface FormProps<T extends Record<string, any>> {
    data: T;
    setData: (name: string, value: any) => void;
    setDate?: (name: string, value: any, format?: string) => void;
    loadData?: (data: T) => void;
    errors?: Record<string, string|string[]>
    setErrors?: (name: string, value: string|string[]) => void;
    loadErrors?: (errors: Record<string, string | string[]>) => void;
}

const useForm = <T extends Record<string, any>>(initialState: T) => {
	const { state } = useLocation();

	const [data, setD] = useState<T>(initialState);
	const [errors, setE] = useState<Record<string, any>>(state?.errors || {});

	const setData = (name: string, value: any) => {
		const obj: Record<string, any> = {};
		obj[name] = value;

		setD({
			...data,
			...obj
		});
	};

	const setDate = (name: string, value: any, format = 'DD/MM/YYYY') => {
		setData(name, moment(value, format).format('YYYY-MM-DD'));
	};

	const loadData = (data: T) => {
		setD(data);
	};

	const setErrors = (name: string, value: any) => {
		const obj: Record<string, any> = {};
		obj[name] = value;

		setE({
			...data,
			...obj
		});
	};

	const loadErrors = (errors: Record<string, string | string[]>) => {
		if (errors) {
			const e: any = {};

			Object.keys(errors).map(key => {
				if(errors[key].length > 0 && key.includes('.')) {
					const [name, number] = key.split('.');
					if (e[name] === undefined && !isNaN(parseInt(number))) {
						e[name] = [];

						e[name][number] = errors[key][0];
					} else {
						if (isNaN(parseInt(number))) {
							e[name] = {};

							e[name][number] = errors[key][0];
						} else {
							e[key] = errors[key][0];
						}
					}
				} else {
					e[key] = errors[key][0];
				}
			});

			setE(e);
		}
	};

	return {
		data,
		setData,
		setDate,
		loadData,
		errors,
		setErrors,
		loadErrors
	};
};

export default useForm;