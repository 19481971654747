import TablePaginationElement from './TablePaginationElement';

interface ITableData<TData> {
    title?: string;
    render: (item: TData, additionalData: any) => any;
}

interface IConfig<TData> {
    columns: ITableData<TData>[];
    // extraRow?: {
    //     when: string | ((currentItem: TData, previousItem?: TData|undefined, nextItem?: TData|undefined) => boolean);
    //     columns: (ITableData<TData> | null)[];
    //     className?: string;
    // }
}

interface PaginatedLinks {
    active: string;
    label: string;
    url: string;
}

interface PaginatedData<TData> {
    data: TData[];
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    prev_page_url: string;
    path: string;
    per_page: string;
    to: number;
    total: number;
    links: PaginatedLinks[];
}

interface IProps<TData> {
    data: TData[];
    meta?: PaginatedData<TData>;
    config: IConfig<TData>;
    additionalData?: any;
}

const TableElement = <TData extends object>({ data = [], meta, config, additionalData = {} }: IProps<TData>) => (
	<>
		<div className="overflow-hidden float-left w-full block h-auto tables responsive-tables">
			<table className="min-w-full ">
				<thead className="">
					<tr>
						{config.columns.map((column, index) => (
							<th
								key={index}
								scope="col"
								className="px-2 pb-4 text-left"
							>
								{column.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody className="divide-y">
					{(Array.isArray(data) ? data : Object.values(data))?.map((item, index) => (
						<>
							<tr key={index}>
								{config.columns.map((column, index) => (
									<td key={index} className="px-2 py-3">
										{column.render(item as TData, additionalData)}
									</td>
								))}
							</tr>
						</>
					))}
				</tbody>
			</table>
		</div>


		{meta && <TablePaginationElement pagination={meta}/>}
	</>
);

export { TableElement as default, type PaginatedData };
