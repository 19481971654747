import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Section } from '@/Components/Sections';
import Tabs, { ITab } from '@/Components/Tabs';
import route from 'ziggy-js';
import Edit from './Edit';
import { useLayout } from '@/Contexts/LayoutContext';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import API from '@/Services/API';
import reactRoute from '@/Utilities/routes';
import Activity from '@/Components/Activity';
import Models from '@/Utilities/models';
import Loader from '@/Components/Loader';
import Comments from '@/Components/Comments';
import { Project } from '@/Types/Models/Projects/project';
import { useAuth } from '@/Contexts/AuthContext';
import { db } from '@/Services/IndexedDB';
import CompleteButton from '@/Components/Projects/CompleteButton';
import Index from '@/Pages/Projects/Reports/Index';
import DownloadSyncButton from '@/Components/Projects/DownloadSyncButton';

interface IProps {
    tab?: string;
}

const Show: FunctionComponent<IProps> = () => {
	const { setHeader, setAction } = useLayout();
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const { offline, user, mobileMode } = useAuth();
	const navigate = useNavigate();

	const [project, setProject] = useState<Project | null>(null);
	const [isLocalCopy, setIsLocalCopy] = useState<boolean>(false);

	const getData = useCallback((id: string | number, offline: boolean | undefined, force = false) => {
		const notFound = () => {
			if (mobileMode && !force) {
				db.projects.get(parseInt(id as string)).then(project => {
					if (project === undefined) {
						API.get(route('api.projects.show', id), {
							headers: {
								'PWA-No-Cache': 'true'
							}
						}).then(({ data }) => {
							if (data.data) {
								setProject(data.data);
								setIsLocalCopy(false);
							} else {
								localStorage.setItem('flash-error', 'There was an error accessing the project from the server. Please try again.');
								navigate(reactRoute('projects.index'));
							}
						}).catch(error => {
							localStorage.setItem('flash-error', 'There was an error accessing the project from the server. Please try again. ' + error);
							navigate(reactRoute('projects.index'));
						});
					}
				});
			} else {
				API.get(route('api.projects.show', id), {
					headers: {
						'PWA-No-Cache': 'true'
					}
				}).then(({ data }) => {
					setProject(data.data);
				}).catch(() => {
					navigate(reactRoute('projects.index'));
				});
			}
		};

		db.projects.get(typeof id === 'string' ? parseInt(id) : id).then(project => {
			if (project) {
				setProject(project as Project);
				setIsLocalCopy(true);
			} else {
				notFound();
			}
		}).catch(() => {
			notFound();
		});
	}, []);

	useEffect(() => {
		if (id) {
			getData(id, offline);
		}
	}, [id]);

	useEffect(() => {
		project && setHeader && setHeader(`Edit Project: ${project?.name}`);
		setAction && setAction(undefined);
	}, [project]);

	if (project === null) {
		return <Loader/>;
	}

	const tabs: ITab[] = [
		{
			name: 'project',
			label: 'Project Information',
			href: project?.id ? reactRoute('projects.show', [project?.id], { tab: 'project' }) : undefined,
			Render: project ? <Edit project={project} setProject={setProject} isLocalCopy={isLocalCopy}/> : null
		},
		{
			name: 'inspection',
			label: 'Inspection Information',
			href: project?.id ? reactRoute('projects.show', [project?.id], { tab: 'inspection' }) : undefined,
			Render: project ? <Index project={project} isLocalCopy={isLocalCopy}/> : null
		},
		{
			name: 'comments',
			label: 'Comments',
			href: project?.id ? reactRoute('projects.show', [project?.id], { tab: 'comments' }) : undefined,
			Render: project?.id ? <Comments model={Models.Project} id={project?.id}/> : undefined
		},
		{
			name: 'activity',
			label: 'Activity',
			href: project?.id ? reactRoute('projects.show', [project?.id], { tab: 'activity' }) : undefined,
			Render: project?.id ? <Activity model={Models.Project} id={project?.id}/> : undefined
		}
	];

	return (
		<div className="ProjectShowPage">
			{
				offline && (
					<div className="bg-red-500 text-white px-4 py-2 mb-3">
                        You are currently offline, some functionality may not be available. If you make a change to this
                        project, it will be stored locally and can be synced when you are back online.
					</div>
				)
			}
			{
				mobileMode && !isLocalCopy && (
					<div className="bg-red-500 text-white px-4 py-2 mb-3">
						Please download this job if you would like to make changes offline.
					</div>
				)
			}
			{
				!offline && isLocalCopy && (
					<div className="bg-yellow-500 text-blck flex justify-between  text-black px-4 py-2 mb-3">
						<p className="my-auto">
                            This project is currently being edited from the local copy, any changes you make will be stored
                            locally and can be synced when you are back online / better signal.
						</p>
					</div>
				)
			}
			{
				(project.locked_by_id && project.locked_by_id !== user?.id) && (
					<div className="bg-yellow-500 text-white  flex justify-between   text-black px-4 py-2 mb-3">
						<p className="my-auto">
							The project is currently being edited by {project?.locked_by?.name} and is locked. Any changes you make will not be saved.
						</p>
					</div>
				) 
			}
			<Section header={{
				title: project.name ?? '',
				actions:
                    <>
                    	{
                    		mobileMode &&
							(<DownloadSyncButton project={project}
                    		onChange={status => {
                    			setIsLocalCopy(status === 'downloaded');
                    			if (project.id) {
                    				getData(project.id, offline, true);
                    			}
                    		}}/>)
                    	}
                    	{
                    		(!mobileMode && !isLocalCopy) &&
							(!project.locked_by_id || project.locked_by_id === user?.id) &&
							(<CompleteButton project={project}/>)
                    	}
                    </>
			}}>
				<Tabs tabs={tabs} activeTabName={searchParams.get('tab') ?? 'project'}/>
			</Section>
		</div>
	);
};

export default Show;