import React, { FunctionComponent, LabelHTMLAttributes, ReactNode } from 'react';

interface IProps extends LabelHTMLAttributes<any> {
    value?: string | ReactNode;
}

const Label: FunctionComponent<IProps> = ({
	value,
	className = '',
	children,
	...props
}) => (
	<label
		{...props}
		className={'block font-medium text-sm text-gray-700 ' + className}
	>
		{value ? value : children}
	</label>
);

export default Label;
