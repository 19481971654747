import React, { FunctionComponent, useEffect, useState, useRef } from 'react'; // Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'; // Import FilePond styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import Vapor from 'laravel-vapor';
import { ActualFileObject, FilePondErrorDescription, FilePondFile } from 'filepond';
import { db } from '@/Services/IndexedDB';
import { useAuth } from '@/Contexts/AuthContext';
import route from 'ziggy-js';
import { isBase64Image } from '@/Utilities/helpers';

// Register the plugins
registerPlugin(
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginFilePoster,
	FilePondPluginFileValidateType,
	FilePondPluginGetFile,
	FilePondPluginFileValidateSize,
	// FilePondPluginImageTransform
);

interface IFileModel {
	uuid?: string;
	id: string;
	url: string;
	name: string;
	size: string;
	type: string;
	source?: File;
}

interface IFile {
	uuid: string;
	name: string;
	size: string;
	type: string;
	extension: string;
}

type Visibilities =
	| 'public-read'
	| 'private'
	| 'public-read-write'
	| 'aws-exec-read'
	| 'authenticated-read'
	| 'bucket-owner-read'
	| 'bucket-owner-full-control'
	| 'log-delivery-write';

interface IProps {
	maxFiles?: number;
	multiple?: boolean;
	onChange?: (name: string, data: any) => void;
	initialValue?: IFileModel[] | IFileModel | any | undefined;
	validateTypes?: string[];
	allowDownloadByUrl?: boolean;
	visibility?: Visibilities;
	label?: string;
	name?: string;
	maxFileSize?: string;
	minFileSize?: string;
	maxTotalFileSize?: string;
	compressImages?: boolean;
}

const FileUpload: FunctionComponent<IProps> = ({
												   maxFiles = 1,
												   multiple = false,
												   onChange,
												   initialValue = [],
												   validateTypes,
												   allowDownloadByUrl = true,
												   visibility = 'private',
												   label = 'Click here to <span class="filepond--label-action">Browse</span> your photos',
												   name = 'files',
												   maxFileSize = '100MB',
												   minFileSize = '0B',
												   maxTotalFileSize = '100MB',
												   compressImages = false
											   }) => {
	const { offline, mobileMode, setMobileMode } = useAuth();
	const filepondRef = useRef<FilePond>(null);

	//If this is a single attachment given, we should convert this into an array
	//ready for us to map this for the initial files variable
	if (initialValue && !Array.isArray(initialValue)) {
		initialValue = [initialValue];
	}

	const [files, setFiles] = useState<any[]>(
		initialValue
			? initialValue.map((value: IFileModel) => ({
				uuid: value.uuid ?? value.id,
				source: value?.source,
				options: {
					type: 'local',
					file: {
						name: value.name,
						size: value.size,
						type: value.type
					},
					metadata: {
						poster: value.url,
						url: value.url
					}
				}
			}))
			: []
	);

	const handleUpdatedFiles = () => {
		if (onChange) {
			if (filepondRef?.current !== undefined) {
				const result = filepondRef?.current?.getFiles().map((file: any) => {
					const uuid = ((file?.serverId !== undefined && typeof file?.serverId === 'string' ? file?.serverId : null) ?? file.uuid ?? file?.file?.uuid ?? file.serverId?.uuid);
					return {
						name: file.filename,
						size: file.fileSize,
						type: file.fileType,
						extension: file.fileExtension,
						uuid: uuid,
						url: (file?.getMetadata !== undefined && typeof file?.getMetadata === 'function' ? file?.getMetadata('url') : null) ?? (isBase64Image(uuid) ? uuid : route('api.file', { path: `tmp/${uuid}` })),
						source: file?.source,
						status: file?.status
					};
				}) ?? [];

				if (multiple) {
					return onChange(name, result);
				} else if (result.length > 0) {
					return onChange(name, result[0]);
				}

				return onChange(name, undefined);
			}
		}
	};

	const handleRemoveFile = (
		error: FilePondErrorDescription | null,
		file: FilePondFile
	) => {
		setFiles(
			files.filter((subFile) => {
				if (file.serverId) {
					return subFile.uuid !== file.serverId;
				}
			})
		);
	};

	const compressImage = async (file: ActualFileObject, { quality = 1, type = 'image/jpeg' }: {quality: number, type: string}) => {
		// Get as image data
		const imageBitmap = await createImageBitmap(file);

		// Draw to canvas
		const canvas = document.createElement('canvas');
		canvas.width = imageBitmap.width;
		canvas.height = imageBitmap.height;
		const ctx = canvas.getContext('2d');
		(ctx as CanvasRenderingContext2D).drawImage(imageBitmap, 0, 0);

		// Turn into Blob
		return await new Promise((resolve) =>
			canvas.toBlob(function (blob) {
				resolve(new File([blob as Blob], file?.name, { type, lastModified: file.lastModified }));
			}, type, quality)
		);
	};

	return (
		<FilePond
			ref={filepondRef}
			maxFileSize={maxFileSize}
			minFileSize={minFileSize}
			maxTotalFileSize={maxTotalFileSize}
			files={files}
			onremovefile={handleRemoveFile}
			onprocessfiles={() => setTimeout(handleUpdatedFiles, 1000)}
			allowMultiple={multiple}
			maxFiles={maxFiles}
			onupdatefiles={setFiles}
			acceptedFileTypes={validateTypes}
			allowDownloadByUrl={allowDownloadByUrl}
			server={{
				process: async (
					fieldName,
					file,
					metadata,
					load,
					error,
					progress,
					abort
				) => {

					if (compressImages) {
						const compressedFile = await compressImage(file, {
							quality: 0.5,
							type: 'image/jpeg',
						});

						file = (compressedFile as ActualFileObject);
					}

					if (mobileMode) {
						// Convert the file to base64 or blob here
						const reader = new FileReader();
						reader.onloadend = () => {
							const base64Data = reader.result;

							// Handle the base64 data as needed (e.g., send it to the server)
							// Note: You may need to adjust this part based on your server's requirements
							// For example, you might want to send the base64Data as a parameter in an API call.

							// Simulate a server response with a timeout
							setTimeout(() => {
								if (base64Data) {
									// Replace this with your actual server logic for handling offline uploads
									const response = {
										uuid: base64Data.toString(),
										name: file.name,
										size: file.size.toString(),
										type: file.type,
										extension: file.name.split('.').pop(),
									};

									load(response.uuid);
									setTimeout(handleUpdatedFiles, 500);
								}
							}, 1000);
						};

						reader.readAsDataURL(file);
					} else {
						db.tokens.orderBy(':id').last().then(token => {
							Vapor.store(file as File, {
								progress: (current: number) =>
									progress(true, Math.round(current * 100), 100),
								visibility: visibility,
								baseURL: process.env.REACT_APP_API_URL ?? 'http://cow-api.test/',
								headers: {
									Authorization: token ? `Bearer ${token.plainTextToken}` : '',
									Accept: 'application/json',
									'X-Requested-With': 'XMLHttpRequest'
								}
							})
								.then((response: IFile) => {
									load(response.uuid);
								})
								.catch(() =>
									error('Something went wrong. Please try again later...')
								);

							return {
								abort: () => abort()
							};
						});
					}
				}
			}}
			name={name}
			labelIdle={label}
		/>
	);
};

export { FileUpload as default, type IFileModel };
