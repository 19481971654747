import React, { FormEvent, FunctionComponent, PropsWithChildren, useEffect, useState } from 'react';
import useForm, { FormProps } from '@/Hooks/useForm';
import { Report } from '@/Types/Models/Projects/report';
import Button from '@/Components/Button';
import PencilIcon from '@/Components/PencilIcon';
import BinIcon from '@/Components/BinIcon';
import Field from '@/Components/Inputs/Field';
import Select from '@/Components/Inputs/Select';
import Label from '@/Components/Inputs/Label';
import WYSIWYG from '@/Components/Inputs/WYSIWYG';
import FileUpload from '@/Components/FileUpload';
import { Image } from '@/Types/Models/Projects/image';
import route from 'ziggy-js';
import { useAuth } from '@/Contexts/AuthContext';
import { classNames, isBase64 } from '@/Utilities/helpers';
import Modal from '@/Components/Modals/Modal';
import { Snag } from '@/Types/Models/Projects/snag';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datepicker from '@/Components/Inputs/Datepicker';

interface IProps extends FormProps<Report>, PropsWithChildren {
    canEdit?: boolean;
}

interface IForm extends FormProps<Snag> {
    index?: number;
	handleDelete?: () => void;
}

interface IMultipleForm extends FormProps<Snag[]> {
    snags: Snag[];
}

const MultipleForm: FunctionComponent<IMultipleForm> = ({ snags, data, setData, loadData }) => {
	const [area, setArea] = useState<string>(data?.[0]?.area ?? '');
	const [locations, setLocations] = useState<string[]>(
		data?.filter(snag => snag.area === area).map(snag => snag.location as string).filter((value, index, array) => array.indexOf(value) === index) ?? []
	);
	const areasOptions = snags.map(snag => snag.area as string).filter((value, index, array) => array.indexOf(value) === index);
	const locationOptions = snags.map(snag => snag.location as string).filter((value, index, array) => array.indexOf(value) === index);

	useEffect(() => {
		setArea(data?.[0]?.area ?? area ?? '');
		setLocations([
			...(locations ?? []),
			...(data.map(snag => snag.location as string).filter((value, index, array) => array.indexOf(value) === index))
		].filter((value, index, array) => array.indexOf(value) === index));
	}, [data]);

	const handleAreaChange = (name: string, value?: string | number) => {
		loadData && loadData([
			...(data.filter(snag => snag.area !== area)),
			...(data.filter(snag => snag.area === area).map((snag) => ({
				...snag,
				area: value as string
			})))
		]);

		setArea(value as string);
	};

	const handleAddLocation = () => {
		setLocations([
			...locations,
			''
		]);
	};

	const handleLocationChange = (index: number, value?: string | number) => {
		// Any existing snags with the same location will be updated to the new location
		loadData && loadData([
			...(data.filter(snag => snag.area !== area || snag.location !== locations[index])),
			...(data.filter(snag => snag.area === area && snag.location === locations[index]).map((snag) => ({
				...snag,
				location: value as string
			})))
		]);

		setLocations([
			...(locations.filter((_, i) => i !== index)),
            value as string
		]);
	};

	const handleSnagChange = (index: number, location: string, name: string, value?: string | number) => {
		loadData && loadData([
			...(data.filter(snag => snag.area !== area || snag.location !== location)),
			...(data.filter(snag => snag.area === area && snag.location === location).map((snag, i) => i === index ? {
				...snag,
				[name]: value
			} : snag))
		]);
	};

	const handleSnagDelete = (index: number, location: string) => {
		loadData && loadData([
			...(data.filter(snag => snag.area !== area || snag.location !== location)),
			...(data.filter(snag => snag.area === area && snag.location === location).filter((_, i) => i !== index))
		]);
	};

	const handleLocationDelete = (index: number) => {
		setLocations([
			...(locations.filter((_, i) => i !== index))
		]);

		loadData && loadData([
			...(data.filter(snag => snag.area !== area || snag.location !== locations[index])),
			...(data.filter(snag => snag.area === area && snag.location === locations[index]).filter((_, i) => i !== index))
		]);
	};

	return (
		<div className="SnagForm-MultipleForm bg-gray-100 mb-6 p-5">
			<div className="grid grid-cols-2 gap-6">
				<div className="col-span-2" key={area}>
					<Field name="area" label="Area *">
						<Select name="type"
							value={area}
							onChange={handleAreaChange}
							createable={true}
							options={[
								...(area ? [
									...(areasOptions.map(area => ({
										label: area,
										value: area
									}))),
									{
										label: area,
										value: area
									}
								] : (areasOptions.map(area => ({
									label: area,
									value: area
								}))))
							].filter(o => o !== undefined)}
						/>
					</Field>
				</div>
				{
					(area && locations.length > 0 && locations?.map((location, index) => (
						<div key={location} className="col-span-2">
							<div className="grid grid-cols-2">

								<div className="col-span-2">
									<Field name="location" label="Location *">
										<Select name="type"
											value={location}
											onChange={(_, value) => handleLocationChange(index, value)}
											createable={true}
											options={[
												...(location ? [
													...(locationOptions.map(location => ({
														label: location,
														value: location
													}))).filter(o => o.value !== location),
													{
														label: location,
														value: location
													}
												] : (locationOptions.map(location => ({
													label: location,
													value: location
												}))))
											].filter(o => o !== undefined)}
										/>
									</Field>
								</div>


								{
									data.filter(snag => snag.area === area && snag.location === location).map((snag, index) => (
										<div key={index} className="snag-locations grid grid-cols-2 col-span-2">
											<div className="w-full clear-both col-span-2">
												<Form index={index} data={snag}
													  handleDelete={() => handleSnagDelete(index, location)}
													  setData={(name, value) => handleSnagChange(index, location, name, value)}/>
											</div>
										</div>
									))
								}


								<div className="col-span-2 flex justify-end">
									<Button
										type="button"
										className="mt-6 btn-secondary"

										onClick={() => loadData && loadData([
											...data,
											{
												id: undefined,
												area: area,
												location: location,
												position: undefined,
												type: undefined,
												description: '',
												images: [],
											}
										])}
									>
                                        Add Snag
									</Button>

									<Button
										type="button"
										className="mt-6 btn-danger"
										onClick={() => handleLocationDelete(index)}
									>
										Remove Location
									</Button>
								</div>

							</div>
						</div>
					)))
				}
				{area && locations.length == 0 && (
					<div className="col-span-2">
						<Field name="location" label="Location *">
							<Select name="type"
								value={locations[0]}
								onChange={(_, value) => handleLocationChange(0, value)}
								createable={true}
								options={locationOptions.map(location => ({
									label: location,
									value: location
								}))}
							/>
						</Field>
					</div>
				)}

				<div className="col-span-2 flex justify-end">
					<Button
						type="button"
						className="ml-auto"
						onClick={handleAddLocation}
					>
                        Add Location
					</Button>
				</div>
			</div>
		</div>
	);
};

const Form: FunctionComponent<IForm> = ({ index, data, setData, handleDelete }) => {
	const { mobileMode, photoCount, photoLimit, incrementPhotoCount, deincrementPhotoCount } = useAuth();

	const handleImageChange = (imageIndex: number, attribute?: string, value?: any) => {
		if (data && attribute) {
			const image = data?.images?.[imageIndex];
			if (image) {
				image[attribute as keyof Image] = value;
				setData('images', [
					...(data?.images ?? []).slice(0, imageIndex),
					image,
					...(data?.images ?? []).slice(imageIndex + 1),
				]);
			}
		}
	};

	return (
		<div className="Form bg-gray-100 w-full snag-descriptions">
			<div className={classNames('grid gap-6', handleDelete ? 'grid-cols-3' : 'grid-cols-2')}>
				<div className="col-span-1">
					<Field name="position" label="Position *">
						<Select name="position"
							value={data?.position}
							onChange={setData}
							createable={true}
							required={true}
							options={[
								{
									label: 'Wall 1',
									value: 'Wall 1'
								},
								{
									label: 'Wall 2',
									value: 'Wall 2'
								},
								{
									label: 'Wall 3',
									value: 'Wall 3'
								},
								{
									label: 'Wall 4',
									value: 'Wall 4'
								},
								{
									label: 'Ceiling',
									value: 'Ceiling'
								},
								{
									label: 'Floor',
									value: 'Floor'
								}
							]}
						/>
					</Field>
				</div>
				<div className="col-span-1">
					<Field name="type" label="Type *">
						<Select name="type"
							value={data?.type}
							onChange={setData}
							required={true}
							options={[
								{
									label: 'Electrical',
									value: 'Electrical'
								},
								{
									label: 'Mechanical',
									value: 'Mechanical'
								},
								{
									label: 'Building',
									value: 'Building'
								},
								{
									label: 'Client',
									value: 'Client'
								},
							]}
						/>
					</Field>
				</div>
				{
					handleDelete && (
						<div className="col-span-1 flex">
							<Button
								type="button"
								className="m-auto"
								onClick={handleDelete}
							>
								Delete Snag
							</Button>
						</div>
					)
				}
				<div className={handleDelete ? 'col-span-3' : 'col-span-2'}>
					<Label>Snag Description</Label>
					<WYSIWYG
						name="content"
						placeholder="Add your description..."
						value={data?.description ?? ''}
						onChange={(_, value) => setData('description', value)}
					/>
				</div>

				<div className={handleDelete ? 'col-span-3' : 'col-span-2'}>
					{
						data?.images?.map((image, index) => (
							<div key={index} className="grid grid-cols-3 gap-6 mb-6">
								<div className="col-span-1">
									<Field
										label={index === 0 ? 'Image *' : undefined}
										name="attachment"
									>
										<FileUpload
											name="attachment"
											visibility="private"
											label="Upload an image"
											maxFileSize="10MB"
											initialValue={image?.attachment}
											onChange={(name, data) => handleImageChange(index, name, data)}
											validateTypes={['image/jpeg']}
											compressImages={true}
										/>
									</Field>
								</div>
								<div className="col-span-1">
									<Field
										label={index === 0 ? 'Caption *' : undefined}
										name="caption"
										value={image?.caption}
										onChange={(name, data) => handleImageChange(index, name, data)}
										spellCheck={true}
									/>
								</div>

								<div className="col-span-1 flex items-end">
									<Button
										type="button"
										className=""
										onClick={() => {
											mobileMode && deincrementPhotoCount && deincrementPhotoCount();
											setData('images', [
												...data?.images?.filter((_, i) => i !== index) ?? []
											]);
										}}
									>
                                        Remove Image
									</Button>
								</div>
							</div>
						))
					}
					{
						((mobileMode && (photoCount ?? 0) < photoLimit) || !mobileMode) && (
							<div className="col-span-2 flex justify-end">
								<Button
									type="button"
									className=""
									onClick={() => {
										mobileMode && incrementPhotoCount && incrementPhotoCount();
										setData('images', [
											...(data?.images ?? []),
											{
												id: undefined,
												attachment: undefined,
												caption: '',
											}
										]);
									}}
								>
                            	Add Image
								</Button>
							</div>
						)
					}
				</div>
			</div>
		</div>
	);
};

const SnagForm: FunctionComponent<IProps> = ({ canEdit = true, data, errors, setData, children }) => {
	const [openEditModal, setOpenEditModal] = useState<boolean>(false);
	const { offline, mobileMode } = useAuth();
	const { data: newSnags, setData: setNewSnags, loadData: loadNewSnags } = useForm<Snag[]>([]);
	const { data: snag, setData: setSnag, loadData: loadSnag } = useForm<Snag>({
		id: undefined
	});

	const handleCreate = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (newSnags !== undefined && newSnags?.filter(snag => snag.position === undefined || snag.type === undefined || snag.description === undefined || snag.description === '' || snag.position === '' || snag.type === '')?.length === 0) {

			const maxCount = Math.max(...(data?.snags ?? []).map(snag => snag.count ?? 0));

			setData('snags', [
				...(data?.snags ?? []),
				...newSnags.map((snag, index) => ({
					...snag,
					count: data?.snags && data?.snags?.length > 0 ? (maxCount + index + 1) : (index + 1)
				}))
			]);

			loadNewSnags([]);

			localStorage.setItem('flash-success', 'Snags have been created. This change will be reflected on submission.');

		} else {
			localStorage.setItem('flash-error', 'Please fill in all the required fields.');
		}
	};

	const handleDelete = (snag: Snag) => {
		setData('snags', [
			...(data?.snags ?? []).filter(s => s !== snag)
		]);
		localStorage.setItem('flash-success', 'Snag has been deleted. This change will be reflected on submission.');
	};

	const handleEdit = (snag: Snag) => {
		loadSnag(snag);
		setOpenEditModal(true);
	};

	const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (snag.id === undefined) {
			setData('snags', [
				...(data?.snags ?? []).map(o => o.count === snag?.count ? snag : o)
			]);
		} else {
			setData('snags', [
				...(data?.snags ?? []).map(o => o.id === snag?.id ? snag : o)
			]);
		}

		setOpenEditModal(false);
		localStorage.setItem('flash-success', 'Snag has been updated. This change will be reflected on submission.');
	};

	const handleCopy = (snag: Snag) => {
		const maxCount = Math.max(...(data?.snags ?? []).map(snag => snag.count ?? 0));
		loadNewSnags([{
			...snag,
			id: undefined,
			count: data?.snags && data?.snags?.length > 0 ? (maxCount + 1) : 0,
			images: snag?.images?.map(image => ({
				...image,
				id: undefined,
				relation_id: undefined,
				attachment: image?.attachment ? {
					...image?.attachment,
					to_replicate: true,
					relation_id: undefined
				} : undefined,
			}))
		}]);
		window.scrollTo(0, 0);
		localStorage.setItem('flash-success', 'Snag has been copied to the form for you to edit. You can create a newly copied snag by clicking the "Create" button.');
	};

	const handleComplete = (snag: Snag) => {
		setData('snags', [
			...(data?.snags ?? []).map(o => (o?.id !== undefined && snag?.id !== undefined && o.id === snag.id) ||
					(
						o?.id === undefined &&
						snag?.id === undefined &&
						snag.area === o.area &&
						snag.location === o.location &&
						snag.position === o.position &&
						snag.type === o.type &&
						snag.description === o.description
					) ? {
					...snag,
					complete: true
				} : o)
		]);
		localStorage.setItem('flash-success', 'Snag has been set to complete. This change will be reflected on submission.');
	};

	return (
		<div className="SnagForm relative float-left w-full">

			<div className="w-full mb-10 float-left w-full">
				<Field
					label="Date of Inspection *"
					name="date_of_inspection"
				>
					<Datepicker
						name="date_of_inspection"
						type="date"
						value={data?.date_of_inspection}
						disabled={!canEdit}
						onChange={setData}
					/>
				</Field>
			</div>

			{
				canEdit && (
					<form className="clear-both" onSubmit={handleCreate}>
						<MultipleForm snags={data?.snags ?? []} data={newSnags} setData={setNewSnags}
							loadData={loadNewSnags}/>

						<div className="w-full flex justify-end">
							<Button type="submit">Create</Button>
						</div>
					</form>
				)
			}

			{
				data?.snags && data?.snags?.length > 0 && (
					<div className="tables clear-both float-left w-full mt-6 overflow-hidden h-auto responsive-tables">
						<table className="w-full float-left h-auto">
							<thead>
								<tr>
									<th>Number</th>
									<th className="min-w-[100px]" align="left">Area</th>
									<th className="min-w-[100px]" align="left">Location</th>
									<th className="min-w-[100px]" align="left">Position</th>
									<th className="min-w-[100px]" align="left">Type</th>
									<th align="left">Description</th>
									<th>Photographs</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{data?.snags.filter(s => (s?.status?.name === undefined || s?.status?.name === 'open') && s?.complete !== true)?.map((snag, index) => (
									<tr key={index}>
										<td align="left">{snag?.count}</td>
										<td className="min-w-[100px]" align="left">{snag?.area}</td>
										<td className="min-w-[100px]" align="left">{snag?.location}</td>
										<td className="min-w-[100px]" align="left">{snag?.position}</td>
										<td className="min-w-[100px]" align="left">{snag?.type}</td>
										<td align="left">
											{snag?.description && (
												<div dangerouslySetInnerHTML={{ __html: snag?.description }}/>)}
										</td>
										<td>
											{snag?.images?.map((image, index) => (
												<div key={index} className="SnagImage">
													{(image?.attachment?.url ?? image?.attachment?.uuid) ?
														(
															<img key={index}
																src={image?.attachment?.url ?? (isBase64(image?.attachment?.uuid as string) ? image?.attachment?.uuid : route('api.file', {
																	path: 'tmp/' + image?.attachment?.uuid,
																}))}
																alt=""/>
														) : null}
												</div>
											))}
										</td>
										<td>
											{canEdit && (
												<div className="flex gap-2">
													<Button type="button" className="btn-secondary icon-only"
														onClick={() => handleCopy(snag)}>
														<FontAwesomeIcon icon={faCopy}
															className="h-4 w-4 shrink-0 text-white"
															aria-hidden="true"
														/>
													</Button>
													<Button type="button" className="btn-success icon-only"
														onClick={() => handleComplete(snag)}>
														<FontAwesomeIcon icon={faCheck}
															className="h-4 w-4 shrink-0"
															aria-hidden="true"
														/>
													</Button>
													<Button type="button" className="btn-secondary icon-only"
														onClick={() => handleEdit(snag)}><PencilIcon
															className="text-white"/></Button>
													<Button styleName="danger" type="button" onClick={() => handleDelete(snag)}><BinIcon/></Button>
												</div>
											)}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)
			}


			{openEditModal && (
				<Modal openModal={openEditModal} onClose={() => setOpenEditModal(!openEditModal)}>
					{
						canEdit && (
							<form key={snag?.id} onSubmit={handleUpdate}>
								<Form data={snag} setData={setSnag} loadData={loadSnag}/>

								<div className="w-full flex justify-end mt-6">
									<Button type="submit" disabled={!snag?.type}>Update</Button>
								</div>
							</form>
						)
					}
				</Modal>
			)}
			{children}
		</div>
	);
};

export default SnagForm;
