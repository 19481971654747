/**
 * Returns a string of class names from the given arguments, excluding any false values.
 *
 * @param classes
 * @returns {string}
 */
const classNames = (...classes: string[]) => {
	return classes.filter(Boolean).join(' ');
};

const capitaliseFirstLetter = (string: string) => {
	return string[0].toUpperCase() + string.slice(1);
};

/**
 * Make string look readable and pretty <3
 * @param str
 */
const toPrettyString = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).replace(/-/g, ' ').replace(/_/g, ' ');


const toSnakeCase = (str = '') => str.replace(/\W+/g, ' ')
	.split(/ |\B(?=[A-Z])/)
	.map(word => word.toLowerCase())
	.join('_');

/**
 * You can do for (x in range(0,100)) like python!
 * @param start
 * @param end
 */
const range = (start: number, end: number) => Array.from({ length: (end - start) }, (v, k) => k + start);

/**
 * Check if the string contains json
 * @param str
 */
const isJsonString = (str: string) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

const snakeCaseToSentenceCase = (str: string) => {
	// Replace underscores with spaces
	let sentenceCaseString = str.replace(/_/g, ' ');

	// Capitalize the first letter of each word
	sentenceCaseString = sentenceCaseString.replace(/\b\w/g, function (match) {
		return match.toUpperCase();
	});

	return sentenceCaseString;
};

const numberToCurrency = (number: number, locale = 'en-GB', currency = 'GBP') => {
	return (number ?? 0).toLocaleString(locale, {
		style: 'currency',
		currency,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
};

const isBase64 = (str: string) => {
	try {
		return btoa(atob(str)) === str;
	} catch (err) {
		return false;
	}
};

const isBase64Image = (str: string) => {
	if (typeof str !== 'string') {
		return false;
	}

	// Check if the string starts with a valid base64 image prefix
	const regex = /^data:image\/(jpeg|jpg|png|gif);base64,/;
	return regex.test(str);
};

const base64toFile = (base64: string, filename: string) => {
	const arr = base64.split(',');

	if (base64.split(',').length > 0) {
		const mime = arr[0]?.match(/:(.*?);/)?.[1],
			bstr = atob(arr[arr.length - 1]),
			u8arr = new Uint8Array(bstr.length);

		let n = bstr.length;

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}
};

/**
 * Clear all TinyMCE data from local storage
 * This is useful for clearing any autosave data that may be stored in local storage - preventing build up of data
 */
const clearAllTinyMceData = () => {
	for (const key in localStorage) {
		if (key.startsWith('tinymce-autosave')) {
			localStorage.removeItem(key);
		}
	}
};

export {
	classNames, capitaliseFirstLetter, range, isJsonString,
	toPrettyString, toSnakeCase, snakeCaseToSentenceCase,
	numberToCurrency, isBase64, isBase64Image, base64toFile,
	clearAllTinyMceData
};
