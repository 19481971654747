import { FunctionComponent } from 'react';
import ApplicationLogo from '@/Components/ApplicationLogo';

const NotFound: FunctionComponent = () => (
	<div className="NotFoundPage h-screen w-full flex flex-col justify-center items-center">
		<ApplicationLogo className="w-80"/>
		<div className="flex mt-10">
			<h1 className="text-9xl font-extrabold text-red-900 tracking-widest mr-10">404</h1>
			<div className="m-auto text-4xl underline">
                Page Not Found
			</div>
		</div>
	</div>
);

export default NotFound;