import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/Styling/tailwind.css';
import '@/Styling/styling.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import API from '@/Services/API';
import { db } from '@/Services/IndexedDB';
import axios from 'axios';
import LoadingPage from '@/Pages/LoadingPage';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import initWorkboxRefresh from '@loopmode/cra-workbox-refresh';

Sentry.init({
	dsn: 'https://5482c8c88475c15692e9f1b3716c2d58@o4506428370321408.ingest.sentry.io/4506512762404864',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [
				'localhost',
				/^https:\/\/cow\.dev-version\.version/,
				/^http:\/\/cow\.dev-version\.version/
			],
		}),
		new Sentry.Replay({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const main = () => {
	root.render(
		<React.StrictMode>
			<App/>
		</React.StrictMode>
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	// reportWebVitals(console.log);
};

window.apiAxios = axios;
serviceWorkerRegistration.register({
	onUpdate: registration => initWorkboxRefresh(registration, {
		className: 'btn btn-primary',
	})
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<LoadingPage/>
	</React.StrictMode>
);

if (!navigator.onLine) {
	window.Ziggy = await db.routes.orderBy(':id').last();
} else {
	try {
		const ziggy = await API.get('ziggy-routes');
		window.Ziggy = ziggy.data;
		db.routes.clear();
		db.routes.add(ziggy.data, 1);
	} catch (e) {
		window.Ziggy = await db.routes.orderBy(':id').last();
	}
}

main();