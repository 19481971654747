import React, { FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react';
import { MbscPopupButton, Popup } from '@mobiscroll/react';
import Button, { ButtonStyleName } from '../Button';

interface IProps {
    callback?: () => void;
    deleteBtnText?: string;
    modalHeaderText?: string;
    modalMessageText?: string;
    buttonText?: string | ReactNode;
    buttonStyle?: ButtonStyleName;
    buttonClasses?: string;
    isOpen?: boolean;
    disabled?: boolean;
    onChange?: (boolean: boolean) => void;
}

const DeleteConfirmation: FunctionComponent<IProps> = ({
	callback,
	deleteBtnText = 'Delete',
	modalHeaderText = 'Deleting',
	modalMessageText = '',
	buttonText = 'Delete',
	buttonStyle = 'danger',
	buttonClasses = undefined,
	isOpen = false,
	disabled = false,
	onChange
}) => {
	const [deleting, setDeleting] = useState(false);
	const [open, setOpen] = useState(isOpen);

	const modalHeader = useMemo(() => modalHeaderText, [modalHeaderText]);
	const modalMessage = useMemo(
		() => (
			<div className="delete-confirmation__message">{modalMessageText}</div>
		),
		[modalMessageText]
	);

	const handleDeleteEvent = useCallback(() => {
		if (onChange) onChange(false);
		if (setOpen) setOpen(false);

		callback && callback();
	}, [callback]);

	// popup options
	const popupButtons = useMemo(() => {
		return [
			'cancel',
            {
            	handler: () => handleDeleteEvent(),
            	keyCode: 'enter',
            	text: deleteBtnText,
            	cssClass: 'delete-confirmation__delete-btn'
            } as MbscPopupButton
		];
	}, [deleteBtnText, callback]);

	const onClose = useCallback(() => {
		if (setOpen) setOpen(false);
	}, []);

	return (
		<>
			<Button type="button" disabled={disabled} styleName={buttonStyle} className={buttonClasses}
				onClick={() => setOpen(!open)} processing={deleting}>
				{buttonText}
			</Button>
			<Popup
				display="center"
				fullScreen={false}
				contentPadding={false}
				headerText={modalHeader}
				buttons={popupButtons}
				isOpen={open}
				onClose={onClose}
				cssClass="modal delete-confirmation"
			>
				<div className="content modal-form">
					{modalMessage}
				</div>
			</Popup>
		</>
	);
};

export { DeleteConfirmation as default };
