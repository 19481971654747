import Tooltip from '@/Components/Tooltip';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Comments as CommentsType } from '@/Types/Models/Comments/comment';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserRobot } from '@fortawesome/pro-solid-svg-icons';
import Button from '@/Components/Button';
import route from 'ziggy-js';
import axios from 'axios';
import WYSIWYG from '@/Components/Inputs/WYSIWYG';
import API from '@/Services/API';
import Loader from '@/Components/Loader';
import { useAuth } from '@/Contexts/AuthContext';

interface IProps {
    model: string;
    id: number;
}

const Comments: FunctionComponent<IProps> = ({ model, id }) => {
	const [comments, setComments] = useState<CommentsType>();
	const [comment, setComment] = useState<string | undefined>('');
	const { offline } = useAuth();

	const getData = useCallback(() => {
		API.get(route('api.comments.index'), {
			params: {
				model,
				id
			}
		}).then(({ data }) => {
			setComments(data.data.reverse());
		});
	}, []);

	useEffect(() => {
		getData();
	}, []);

	const handleNewComment = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		axios.post(route('api.comments.store'), {
			comment,
			model,
			id
		}, {
			maxRedirects: 0
		}).then(() => {
			setComment('');
			getData();
		});
	};

	if (comments === undefined) {
		return <Loader/>;
	}

	return (
		<div className="Comments">
			<ul role="list" className="Comments-List mt-b space-y-6">
				{comments.map((comment, key) => (
					<li className="Comments-ListItem" key={key}>

						<div className="profile-inner float-left w-full h-auto block">
							<div className="meta flex justify-between mb-4 items-center content-center">
								<div className="profile-picture flex justify-start items-center content-center">
									{
										comment?.authenticatable?.gravitar_url ? (
											<img
												src={comment?.authenticatable?.gravitar_url}
												alt=""
												className="Comments-UserImage h-6 w-6 rounded-full bg-gray-50"
											/>
										)
											:
											(
												<FontAwesomeIcon icon={faUserRobot}
													className="Comments-BotImage h-6 w-6 rounded-full bg-gray-50"/>
											)
									}

									<span className="">{comment?.authenticatable?.name ?? ''}</span>{' '}
								</div>

								<div className="relative pl-4 whitespace-nowrap">
									<Tooltip tooltipContent={moment(comment.created_at).format('DD/MM/YYYY HH:mm')}>
										<time
											dateTime={comment?.created_at}
											className=" whitespace-nowrap py-0.5 text-xs leading-5 text-gray-500"
										>
											{moment(comment.created_at).fromNow()}
										</time>
									</Tooltip>
								</div>
							</div>

							<div className=" float-left w-full h-auto block comment-block px-4 py-3">
								<div dangerouslySetInnerHTML={{ __html: comment.comment }}/>
							</div>
						</div>


					</li>
				))}
			</ul>

			{
				!offline && (
					<div className="Comments-Comment flex w-full">
						<form className="Comments-CommentForm w-full grid grid-cols-1 gap-6 relative"
							onSubmit={handleNewComment}>
							<div className="col-span-1">
								<WYSIWYG
									label="Comment"
									name="comment"
									placeholder="Add your comments..."
									value={comment}
									onChange={(_, value) => setComment(value)}
								/>
							</div>
							<div className="col-span-1 flex justify-end">
								<Button type="submit" className="ml-3 btn-secondary">
                                    Add Comment
								</Button>
							</div>
						</form>
					</div>
				)
			}

		</div>
	);
};

export default Comments;
