import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Section } from '@/Components/Sections';
import { Table } from '@/Components/Tables';
import DeleteConfirmation from '@/Components/Modals/DeleteConfirmation';
import Button from '@/Components/Button';
import { Client } from '@/Types/Models/Clients/client';
import route from 'ziggy-js';
import { PaginatedData } from '@/Components/Tables/TableElement';
import PencilIcon from '@/Components/PencilIcon';
import BinIcon from '@/Components/BinIcon';
import { Link, useSearchParams } from 'react-router-dom';
import { useLayout } from '@/Contexts/LayoutContext';
import API from '@/Services/API';
import { debounce } from 'lodash';
import reactRoute from '@/Utilities/routes';
import Loader from '@/Components/Loader';

const Index: FunctionComponent = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [clients, setClients] = useState<PaginatedData<Client> | undefined>(undefined);
	const { setHeader, setAction } = useLayout();

	const getData = useCallback(
		debounce((searchParams: URLSearchParams) => {
			API.get(route('api.clients.index'), {
				params: {
					page: searchParams.get('page'),
					per_page: searchParams.get('per_page') ?? 20,
					search: searchParams.get('search')
				}
			}).then(({ data }) => {
				setClients(data?.data);
			});
		}, 500),
		[]
	);

	const setParam = (name: string, value: string | number) => {
		searchParams.delete(name);
		searchParams.append(name, value.toString());
		setSearchParams(searchParams);
	};

	useEffect(() => {
		setHeader && setHeader('Clients');
		setAction && setAction((
			<Link to={reactRoute('clients.create')}>
				<Button className="btn-success">Create Client</Button>
			</Link>
		));

		getData(searchParams);
	}, []);

	useEffect(() => {
		getData(searchParams);
	}, [searchParams]);

	if (clients === undefined) {
		return <Loader/>;
	}

	const tableConfig = {
		columns: [
			{
				title: 'Name',
				render: (client: Client) =>
					client.id &&
                    <>
                    	<Link
                    		to={client?.id.toString()}
                    		className="text-sm btn-link">
                    		{client.name}
                    	</Link>
                    </>

			},
			{
				title: 'Project Managers',
				render: (client: Client) => client?.project_managers.map(pm => pm.name).join(', ')
			},
			{
				render: (client: Client) =>
					client.id &&
                    <Link
                    	to={client?.id.toString()}
                    	className="btn btn-secondary icon-only">
                    	<PencilIcon/>
                    </Link>

			},
			{
				render: (client: Client) => client.id && <DeleteConfirmation
					isOpen={false}
					modalMessageText="Are you sure you want to delete?"
					modalHeaderText="Delete Client"
					buttonText={<BinIcon/>}
					callback={() => {
						API.delete(route('api.clients.destroy', [client.id])).then(() => {
							window.location.reload();
						});
					}}
				/>

			}
		]
	};

	return (
		<div className="ClientIndexPage">
			<Section header={{
				title: 'All Clients',
				onPerPageChange: (value) => setParam('per_page', value),
				perPageValue: typeof searchParams.get('per_page') === 'string' && (searchParams.get('per_page') as string).length > 0 ? parseInt(searchParams.get('per_page') as string) : 20,
				onSearchChange: (value) => setParam('search', value),
				searchValue: searchParams.get('search'),
				searchPlaceholder: 'Search Clients'
			}}>
				<Table<Client> data={clients?.data ?? []} meta={clients} config={tableConfig}/>
			</Section>
		</div>
	);
};

export default Index;
