import { FunctionComponent, HTMLProps, ReactNode } from 'react';
import { classNames } from '@/Utilities/helpers';

interface IProps extends HTMLProps<HTMLDivElement> {
    tooltipContent: string | ReactNode;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    toolTipClassNames?: string;
}

const Tooltip: FunctionComponent<IProps> = ({
	width,
	className = '',
	toolTipClassNames = 'bg-black',
	children,
	tooltipContent,
	placement = 'top',
	...props
}) => (
	<div className={classNames('group relative Tooltip-Wrapper', className)} {...props}>
		{children}
		<span
			style={{ width }}
			className={classNames(`Tooltip absolute ${placement} z-10 scale-0 transition-all rounded p-2 text-xs text-white group-hover:scale-100`, toolTipClassNames)}
		>
			{tooltipContent}
		</span>
	</div>
);

export default Tooltip;
