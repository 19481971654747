import moment from 'moment/moment';
import { Datepicker as DatepickerReact } from '@mobiscroll/react';
import React, { FunctionComponent } from 'react';
import Error from './Error';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { MbscDatepickerOptions } from '@mobiscroll/react/dist/src/core/components/datepicker/datepicker';

type TypeType =
    | 'range'
    | 'timegrid'
    | 'time'
    | 'date'
    | 'datetime'
    | 'datetimeRange'
    | 'month';

interface IProps extends Omit<MbscDatepickerOptions, 'error'> {
    type: TypeType;
    customOptions?: MbscDatepickerOptions | null;
    error?: string;
}

const Datepicker: FunctionComponent<IProps> = ({
	name,
	value,
	error,
	onChange,
	type,
	min,
	customOptions = {},
	disabled,
	inputTyping = false
}) => {
	const handleChange = (element: any, config: any) => {
		if (value != element.valueText && onChange) {
			onChange(config.props.name, element.valueText);
		}
	};

	let options: MbscDatepickerOptions = {
		theme: 'material',
		themeVariant: 'light'
	};

	switch (type) {
	case 'range':
		options = Object.assign(options, {
			controls: ['calendar'],
			select: 'range',
			dateFormat: 'DD/MM/YYYY'
		});
		break;
	case 'timegrid':
		options = Object.assign(options, {
			controls: ['timegrid'],
			timeFormat: 'HH:mm',
			minTime: '07:00',
			maxTime: '20:00'
		});
		break;
	case 'time':
		options = Object.assign(options, {
			controls: ['time'],
			timeFormat: 'HH:mm',
			stepMinute: 15
		});
		break;
	case 'date':
		options = Object.assign(options, {
			dateFormat: 'DD/MM/YYYY',
			controls: ['date']
		});
		break;
	case 'month':
		options = Object.assign(options, {
			controls: ['date'],
			dateFormat: 'MM/YYYY',
			dateWheels: '|MMMM YYYY|',
			touchUi: true
		});
		break;
	case 'datetime':
		options = Object.assign(options, {
			dateFormat: 'DD/MM/YYYY',
			controls: ['date', 'time'],
			timeFormat: 'HH:mm',
			min: min ?? null,
			minTime: '06:00',
			maxTime: '18:00'
		});
		break;
	case 'datetimeRange':
		options = Object.assign(options, {
			dateFormat: 'DD/MM/YYYY',
			controls: ['calendar', 'time'],
			select: 'range',
			timeFormat: 'HH:mm',
			min: min ?? null,
			minTime: '06:00',
			maxTime: '18:00'
		});
		break;
	}

	options = { ...options, ...customOptions };

	return (
		<>
			<div className="float-left w-full block h-auto datepicker relative">
				<DatepickerReact
					{...options}
					moment={moment}
					returnFormat="moment"
					value={value}
					onChange={handleChange}
					name={name}
					touchUi={false}
					disabled={disabled}
					inputTyping={inputTyping}
					buttons={['set', 'cancel']}
				/>

				{error && <Error message={error}/>}
			</div>
		</>
	);
};

export default Datepicker;
