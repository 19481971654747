import React, { FunctionComponent, useEffect, useState } from 'react';
import { useAuth } from '@/Contexts/AuthContext';

const OfflineBar: FunctionComponent = () => {
	const { user, offline, mobileMode, setMobileMode, photoCount, photoLimit } = useAuth();
	const [storagePercentageUsed, setStoragePercentageUsed] = useState<number>(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (navigator.storage && navigator.storage.estimate) {
				navigator.storage.estimate().then((quota) => {
					if (quota.usage && quota.quota) {
						const percentageUsed = (quota.usage / quota.quota) * 100;
						console.log(`Using ${percentageUsed}% of storage`);

						setStoragePercentageUsed(percentageUsed);
					}
				});
			}
		}, 10000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className="w-full fixed flex gap-6 justify-center offline-bar z-10 top-0 left-0">
			<div className="w-auto flex justify-center">
				{offline && (
					<div className="flex gap-1">
						<div className="w-3 h-3 bg-red-600 rounded-full my-auto"/>
						<span>You are offline please connect to the internet <small className="text-sm">({process.env.REACT_APP_VERSION})</small></span>
						<button className="btn-link underline" onClick={() => window.location.reload()}>Refresh</button>
					</div>
				)}
				{!offline && (
					<div className="flex gap-1">
						<div className="w-3 h-3 bg-green-500 rounded-full my-auto"/>
						<span>You are online <small className="text-sm">({process.env.REACT_APP_VERSION})</small></span>
					</div>
				)}
			</div>
			{user?.role?.name === 'administrator' && user?.email?.includes('identify') && (
				<div className="w-auto flex justify-center">
					{mobileMode && (
						<div className="flex gap-1">
							<span>Mobile mode is enabled</span>
							<button className="btn-link underline"
								onClick={() => setMobileMode && setMobileMode(false)}>Disable
							</button>
						</div>
					)}
					{!mobileMode && (
						<div className="flex gap-1">
							<span>Mobile mode is disabled</span>
							<button className="btn-link underline"
								onClick={() => setMobileMode && setMobileMode(true)}>Enable
							</button>
						</div>
					)}
				</div>
			)}
			{mobileMode && (
				<div className="w-auto flex justify-center">
					<div className="flex gap-1">
						<span> | {photoCount}/{photoLimit} photo limit remaining</span>
					</div>
				</div>
			)}
			{mobileMode && storagePercentageUsed > 80 && (
				<div className="w-auto flex justify-center">
					<div className="flex gap-1">
						<span> | Storage usage is above 80% Please Sync Your Projects</span>
					</div>
				</div>
			)}
		</div>
	);
};

export default OfflineBar;