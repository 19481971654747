import { FormEvent, Fragment, FunctionComponent, ReactNode, useEffect } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { classNames } from '@/Utilities/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faChevronDown, faChevronUp, faCog, faHome, faUnlock } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import API from '@/Services/API';
import route from 'ziggy-js';
import { useAuth } from '@/Contexts/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import reactRoute, { isCurrentReactRoute } from '@/Utilities/routes';

interface INavItem {
    name: string;
    href?: string;
    icon?: IconProp;
    current?: boolean;
    subItems?: this[];
}

interface IProps {
    showSideBar: boolean;
    setShowSideBar: (showSideBar: boolean) => void;
}

const SimpleNavItem: FunctionComponent<{ item: INavItem; children?: ReactNode, menuButton?: boolean }> = ({
	item,
	children,
	menuButton = false
}) => {
	const Children = () => (
		<>
			{item.icon && <FontAwesomeIcon icon={item.icon} className={classNames(
				item.current ? 'text-black active' : 'text-gray-300 group-hover:text-black',
				'h-6 w-6 shrink-0'
			)}
			aria-hidden="true"/>}
			{item.name}

			{children}
		</>
	);

	return (
		<li key={item.name}>
			{
				menuButton ? (
					<a className={classNames(
						item.current
							? 'text-black active'
							: 'text-gray-300 hover:text-black',
						'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
					)}>
						<Children/>
					</a>
				) : (
					<Link
						to={menuButton ? `#${item.name}` : (item.href ?? '#no-link')}
						className={classNames(
							item.current
								? 'text-black active'
								: 'text-gray-300 hover:text-black',
							'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
						)}
					>
						<Children/>
					</Link>
				)
			}
		</li>
	);
};

const MenuNavItem: FunctionComponent<{ item: INavItem }> = ({ item }) => {
	return (
		<Menu as="li" className="relative">
			{({ open }) => (
				<>
					<Menu.Button className="w-full">
						<SimpleNavItem item={item} menuButton={true}>
							<FontAwesomeIcon icon={open ? faChevronUp : faChevronDown}
								className="h-3 w-3 shrink-0 ml-auto my-auto"
								aria-hidden="true"/>
						</SimpleNavItem>
					</Menu.Button>
					<Transition
						as="div"
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
						className="rounded-md bg-gray-200 absolute top-full left-0 w-full z-10"
					>
						<Menu.Items>
							{item?.subItems?.map((item, key) => (
								item.subItems ? <MenuNavItem item={item} key={key}/> :
									<SimpleNavItem item={item} key={key}/>
							))}
						</Menu.Items>
					</Transition>
				</>
			)}
		</Menu>
	);
};

const Navigation: FunctionComponent = () => {
	const { user, removeSession } = useAuth();
	const navigate = useNavigate();

	const navigation: INavItem[] = [
		{
			name: 'Dashboard',
			href: reactRoute('home'),
			icon: faHome,
			current: isCurrentReactRoute('home')
		},
		{
			name: 'Projects',
			href: reactRoute('projects.index'),
			icon: faBriefcase,
			current: isCurrentReactRoute('projects.index')
		},
		{
			name: 'Settings',
			icon: faCog,
			current: false,
			subItems: [
				{
					name: 'Users',
					href: reactRoute('users.index'),
					current: isCurrentReactRoute('users.index')
				},
				{
					name: 'Clients',
					href: reactRoute('clients.index'),
					current: isCurrentReactRoute('clients.index')
				}
			]
		}
	];

	// const user = usePage<inertia.AuthenticatedProps>().props.auth;

	const logout = (e: FormEvent) => {
		e.preventDefault();
		API.post(route('api.logout')).then(function () {
			removeSession && removeSession();
			navigate('login');
		});
	};

	return (
		<div className="sidebar fixed p-6">
			<nav className="float-left w-full block">
				<ul role="list" className="float-left w-full block">
					<li>
						<ul role="list" className="subnav float-left w-full block">
							{navigation.map((item, key) => (
								<Fragment key={key}>
									{item.subItems ? <MenuNavItem item={item}/> : <SimpleNavItem item={item}/>}
								</Fragment>
							))}
						</ul>
					</li>

					<li className="mt-auto">
						<ul className="meta-nav float-left w-full block">
							<li>
								<Link
									to='/profile'
									className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:text-black"
								>
									<img
										className="h-6 w-6 rounded-full bg-gray-50"
										src={user?.gravitar_url ?? 'http://www.gravatar.com/avatar/?d=identicon'}
									/>
									{user?.name}
								</Link>
							</li>
							<li>
								<form className="w-full" method="POST" onSubmit={logout}>
									<button
										className="w-full logout"
										type="submit"
									>
										<FontAwesomeIcon icon={faUnlock}
											className=""
											aria-hidden="true"
										/>
                                        Log out
									</button>
								</form>
							</li>
						</ul>
					</li>
				</ul>
			</nav>
		</div>
	);
};

const Sidebar: FunctionComponent<IProps> = ({ showSideBar, setShowSideBar }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		setShowSideBar && setShowSideBar(false);
	}, [pathname]);

	return (
		<>
			<div>
				<Transition.Root show={showSideBar} as={Fragment}>
					<Dialog as="div" className="relative z-50 navbar" onClose={setShowSideBar}>
						<Transition.Child
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 backdrop-blur"/>
						</Transition.Child>

						<div className="fixed inset-0 flex">
							<Transition.Child
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
									<Transition.Child
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute close-menu">
											<button type="button" className="flex justify-center items-center"
												onClick={() => setShowSideBar(false)}>
												<span className="sr-only">Close sidebar</span>
												<svg xmlns="http://www.w3.org/2000/svg" height="1em"
													viewBox="0 0 384 512">
													<path
														d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"/>
												</svg>
											</button>
										</div>
									</Transition.Child>
									<Navigation/>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>

				<div className="hidden">
					<Navigation/>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
