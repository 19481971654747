import { FunctionComponent, useEffect } from 'react';
import Index from '@/Pages/Projects/Index';
import route from 'ziggy-js';
import API from '@/Services/API';

const Home: FunctionComponent = () => {

	useEffect(() => {
		// Cache all the statuses into the SW in case the app goes offline
		API.get(route('api.statuses.index', { type: 'observation' }));
		API.get(route('api.statuses.index', { type: 'snag' }));
		API.get(route('api.statuses.index', { type: 'project' }));
	}, []);

	return (
		<div className="HomePage">
			<Index homePage/>
		</div>
	);
};

export default Home;
