import React, { FunctionComponent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReactAsyncSelect from 'react-select/async';
import ReactAsyncSelectCreateable from 'react-select/async-creatable';
import { classNames } from '@/Utilities/helpers';
import axios from 'axios';
import route from 'ziggy-js';
import { GroupBase } from 'react-select/dist/declarations/src/types';
import Select from 'react-select/base';
import debounce from 'lodash/debounce';
import API from '@/Services/API';

interface IOption {
    label?: string | number;
    valueKey?: string | number;
    value?: string | number;
    name?: string | number;
}

type ValueKey = 'value' | 'id';
type LabelKey = 'label' | 'name';

interface IProps {
    routeName: string;
    routeParams?: Record<string, string | number>;
    valueKey?: ValueKey | string;
    labelKey?: LabelKey | string;
    labelGenerate?: (data: IOption) => string;
    name?: string;
    value?: IOption | string | number | any;
    multiple?: boolean;
    error?: string;
    onChange?: (name: string, value: string, option: IOption | IOption[]) => void;
    queryName?: string;
    onQueryFail?: (data: any) => void;
    onQuerySuccess?: (data: any) => void;
    placeholder?: string;
    formatData?: (data: any) => IOption;
    allowNullSearch?: boolean;
    createable?: boolean;
    innerRef?: MutableRefObject<Select<unknown, false, GroupBase<unknown>>>;
    disabled?: boolean;
    selectParams?: object;
    selectRouteName?: string;
    className?: string;
}

const AsyncSelect: FunctionComponent<IProps> = ({
	routeName,
	routeParams,
	valueKey = 'id',
	labelGenerate,
	multiple = false,
	error,
	onChange,
	name,
	value,
	queryName = 'query',
	onQueryFail,
	onQuerySuccess,
	placeholder,
	formatData,
	allowNullSearch = false,
	createable = false,
	innerRef,
	disabled,
	selectParams,
	selectRouteName,
	className = ''
}) => {
	const [inputValue, setInputValue] = useState('');
	const [selectValue, setSelectValue] = useState<IOption | IOption[] | undefined>();

	const [options, setOptions] = useState<IOption[] | any[]>([]);

	const handleInputChange = (newValue: string) => {
		const inputValue = newValue.replace(/\W/g, '');
		setInputValue(inputValue);
	};

	const generateLabel =
        labelGenerate ?? useCallback((data: IOption) => data?.label ?? data.name, []);

	const inputRef =
        innerRef ?? useRef<Select<unknown, false, GroupBase<unknown>>>(null);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		inputRef?.current?.setValue(selectValue);
	}, [selectValue]);

	const handleDefaultValue = () => {
		if (Array.isArray(value)) {
			return value.map((item) => {
				const matched = options.filter(
					(optionItem) => optionItem[valueKey] === String(item)
				);

				if (matched.length) {
					return {
						...item,
						value: matched[0][valueKey],
						label: generateLabel(matched[0])
					};
				}

				return {
					...item,
					value: item[valueKey],
					label: generateLabel(item)
				};
			});
		}

		if (
			value !== null &&
            typeof value === 'object' &&
            Object.prototype.hasOwnProperty.call(value, valueKey)
		) {
			return {
				...value,
				value: value[valueKey],
				label: generateLabel(value)
			};
		}

		const matched = options.filter(
			(item) => String(item[valueKey]) === String(value)
		);

		if (matched.length) {
			return {
				...matched[0],
				value: matched[0][valueKey],
				label: generateLabel(matched[0])
			};
		}

		if (selectValue === undefined && (value !== '' && value !== undefined) && (typeof value === 'string' || typeof value === 'number') && selectRouteName) {
			API
				.get(
					route(selectRouteName, {
						...routeParams,
						...{ [queryName]: inputValue },
						...selectParams
					})
				)
				.then((data) => data?.data?.data ?? data?.data)
				.then((data) => {
					data?.data &&
                    setSelectValue(
                    	{
                    		...data.data,
                    		value: data.data[valueKey],
                    		label: generateLabel(data.data)
                    	}
                    );

					if (data && data[valueKey] && generateLabel(data)) {
						setSelectValue(
							{
								...data,
								value: data[valueKey],
								label: generateLabel(data)
							}
						);
					}
				});
		}

		return value;
	};

	const handleOnChange = (options: any, action: any) => {
		if (onChange) {
			if (multiple) {
				onChange(
					action.name,
					options.map((option: IOption) => option.value),
					options
				);
			} else {
				onChange(action.name, options ? options.value : '', options);
			}
		}
	};

	const getAsyncOptions = (inputValue: string) => {
		return new Promise((resolve) => {
			(inputValue || allowNullSearch) &&
            axios
            	.get(
            		route(routeName, { ...routeParams, ...{ [queryName]: inputValue } })
            	)
            	.then((data) => data?.data?.data ?? data?.data)
            	.then((data: any) => {

            		if (Object.prototype.hasOwnProperty.call(data, 'data')) data = data.data;

            		data = formatData ? formatData(data) : data;

            		if (!Array.isArray(data)) {
            			onQueryFail && onQueryFail(data);
            			return [];
            		}

            		onQuerySuccess && onQuerySuccess(data);

            		const options = data.map((element) => ({
            			...element,
            			...{
            				value: element[valueKey],
            				label: generateLabel(element)
            			}
            		}));

            		resolve(options);
            	});

			if (!inputValue && !allowNullSearch) {
				resolve([]);
			}
		});
	};

	const loadOptions = useCallback(
		debounce((inputText, callback) => {
			getAsyncOptions(inputText).then(options => {
				setOptions(options as IOption[]);
				callback(options);
			}).catch((e) => {
				callback([]);
				console.error(e);
			});
		}, 1500),
		[]
	);

	return (
		<>
			{createable ? (
				<>
					<ReactAsyncSelectCreateable
						cacheOptions
						loadOptions={loadOptions}
						classNamePrefix="react-select"
						defaultOptions
						name={name}
						placeholder={placeholder}
						isMulti={multiple}
						//menuIsOpen={true}
						defaultValue={handleDefaultValue}
						onInputChange={handleInputChange}
						onChange={handleOnChange}
						ref={inputRef}
						isClearable={true}
						className={classNames(className, error ? 'selectError' : '')}
						isDisabled={disabled}
					/>
					{error && (
						<p className="mt-2 text-sm text-red-600 clear-both">{error}</p>
					)}
				</>
			) : (
				<>
					<ReactAsyncSelect
						cacheOptions
						loadOptions={loadOptions}
						defaultOptions
						placeholder={placeholder}
						name={name}
						classNamePrefix="react-select"
						isMulti={multiple}
						//menuIsOpen={true}
						isClearable={true}
						defaultValue={handleDefaultValue}
						onInputChange={handleInputChange}
						onChange={handleOnChange}
						ref={inputRef}
						className={classNames(className, error ? 'selectError' : '')}
						isDisabled={disabled}
					/>
					{error && (
						<p className="mt-2 text-sm text-red-600 clear-both">{error}</p>
					)}
				</>
			)}
		</>
	);
};

export default AsyncSelect;
